import styled from "styled-components";
import { Button } from "components/Button";
import { ProgressBar } from "components/ProgressBar";
import { sizing, spacing } from "styles/sizing";
import { regular, semiBold, bold } from "styles/typography";
import colors from "styles/colors";
import { deskStart } from "styles/consts";
import { desk } from "styles/media";
import { rgba } from "polished";
import { Loader } from "components/Loader";
import { NavLink } from "react-router-dom";

export const StyledLoader = styled(Loader)`
  background-color: ${colors.blackRock};
`;

export const StyledProgressBar = styled(ProgressBar)`
  width: 111px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${spacing(1)};
  margin-top: ${spacing(2)};
`;

export const Name = styled.h1`
  margin: 0;
  font-size: ${sizing(32)};
  line-height: ${sizing(36)};
  letter-spacing: -0.76px;
  text-align: center;
  ${regular};

  ${desk(`
    font-size: ${sizing(48)};
    line-height: ${sizing(54)};
  `)};
`;

export const Info = styled.div`
  width: 100%;
  padding: ${spacing(2)} 0;
  position: sticky;
  top: 0;
  background: ${colors.blackRock};
  z-index: 10;
  text-align: center;
`;

export const InnerInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  * + * {
    margin-left: ${spacing(1)};
  }
`;

export const Title = styled.span`
  margin-bottom: ${spacing(1)};
  font-size: ${sizing(14)};
  line-height: ${sizing(22)};
  ${semiBold};
  text-align: center;
  flex: 1;

  ${desk(`
    font-size: ${sizing(18)};
    line-height: ${sizing(28)};
  `)};
`;

export const Container = styled.div`
  background-color: ${colors.white};
  width: 100%;
  flex: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: ${spacing(2)} 0;
  max-width: ${deskStart}px;

  ${desk(`
    flex-direction: row-reverse;
    height: 100%;
  `)};
`;

export const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${desk(`
    flex: 0 0 300px;
    margin-right: ${spacing(3)};
  `)};
`;

export const Content = styled.section`
  flex-grow: 0;

  ${desk(`
    flex-grow: 1;
    overflow: auto;
  `)};
`;

export const Section = styled.div`
  position: relative;
  margin-bottom: ${spacing(3)};
  padding-bottom: ${spacing(3)};
  border-bottom: 1px solid ${colors.antiFlashWhite};

  ${({ padded }) =>
    desk(`
    padding: ${padded ? spacing(2) : 0};
    border: ${padded ? `1px solid ${colors.antiFlashWhite}` : "none"};
  `)};
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const SectionContent = styled.div`
  color: ${colors.liquorice};
  line-height: ${sizing(24)};
`;

export const Heading = styled.h2`
  margin-top: 0;
  color: ${colors.liquorice};
  font-size: ${sizing(18)};
  line-height: ${sizing(26)};
  ${semiBold};

  ${desk(`
    font-size: ${sizing(20)};
  `)};
`;

export const Copy = styled.span`
  font-size: ${sizing(14)};
  line-height: ${sizing(24)};
  color: ${rgba(colors.liquorice, 0.8)};
  ${regular};
`;

export const EditButton = styled(Button)`
  width: auto;
  padding-top: 0;
  margin: 0;
`;

export const Rate = styled.span`
  ${desk(`
    margin: 0 ${spacing()};
    padding: ${spacing(1)};
    border-radius: 2px;
    background-color: ${colors.palatinateBlue};
    color: ${colors.white};
    font-size: ${sizing(12)};
    ${bold};
  `)};
`;

export const RateCopy = styled(Copy)`
  display: none;

  ${desk(`
    display: inline-block;
  `)};
`;

export const Experience = styled.div`
  padding: ${spacing(2)} 0;
`;
export const ExperienceTitle = styled.h4`
  color: ${colors.blackRock};
  ${semiBold};
  font-size: ${sizing(16)};
  margin: 0;
`;
export const ExperienceCompany = styled.span`
  display: block;
  ${regular};
  font-size: ${sizing(16)};
  color: ${colors.blackRock};
`;
export const ExperienceDates = styled.time`
  color: ${colors.frenchGray};
  ${semiBold};
  font-size: ${sizing(14)};
  display: block;
  line-height: ${sizing(24)};
`;
export const ExperienceDescription = styled.div`
  ${regular};
  font-size: ${sizing(16)};
  line-height: ${sizing(24)};
`;

export const Tabs = styled.div`
  width: 100%;
  display: flex;
  padding-top: ${spacing(2)};
  border-bottom: 1px solid ${colors.snuff};
`;

export const Tab = styled(NavLink)`
  text-decoration: none;
  color: ${colors.frenchGray};
  border-bottom: 1px solid ${colors.snuff};
  margin-right: ${spacing(2)};
  margin-bottom: -1px;
  ${semiBold};
  font-size: ${sizing(14)};
  text-transform: uppercase;
  cursor: pointer;

  transition: color 0.4s ease, border-color 0.4s ease;

  &:hover,
  &:focus {
    color: ${colors.carribeanGreen};
    border-color: ${colors.carribeanGreen};
  }

  &.active {
    color: ${colors.carribeanGreen};
    border-color: ${colors.carribeanGreen};
  }
`;

export const RolesContainer = styled.div``;

export const Results = styled.div`
  width: 100%;
  flex: 1;
  background: ${colors.white};
  padding: ${spacing(2)} 0;
  display: flex;
  flex-direction: column;
`;

export const IllustrationContainer = styled.div`
  padding: ${spacing(2)} ${spacing(5)};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${sizing(18)};
  color: ${colors.blackRock};
  ${semiBold};
`;

export const Role = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing(2)} 0;
  border-bottom: 2px solid ${colors.snuff};
`;

export const RoleTitle = styled.h3`
  margin: 0;
  font-size: ${sizing(18)};
  color: ${colors.blackRock};
  ${semiBold};
  padding-right: ${spacing(4)};
`;

export const RoleTime = styled.div`
  padding: ${spacing(1)} 0;
  font-size: ${sizing(14)};
  color: ${colors.blackRock};
  ${regular};
`;

export const RoleDetails = styled.div`
  display: flex;
  padding: ${spacing(2)} 0;
`;

export const RolePill = styled.span`
  padding: ${spacing(0.5)};
  font-size: ${sizing(14)};
  color: ${colors.white};
  ${bold};
  background: ${props =>
    props.closed ? colors.frenchGray : colors.carribeanGreen};
`;

export const RoleApplicants = styled.span`
  padding-left: ${spacing(1)};
  padding-top: ${spacing(0.5)};
  font-size: ${sizing(14)};
  color: ${colors.blackRock};
  ${regular};
`;

export const ButtonContainer = styled.div`
  display: flex;
`;
