import React, { Component, Fragment } from "react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import { Loader } from "components/Loader";
import { spacing, sizing } from "styles/sizing";
import { semiBold } from "styles/typography";
import colors from "styles/colors";
import { Button } from "components/Button";
import { CancellableTag } from "components/Tag";
import { AppliedIcon } from "components/icons";
import { Link } from "react-router-dom";
import { tablet } from "styles/media";
import { Flag } from "flag";
import { TextEditor } from "components/TextEditor";
import { locations } from "constants/locations";
import { experienceLevels } from "constants/experienceLevels";
import { PageLayout, PageHeader } from "components/PageLayout";
import { PageSubTitle } from "components/PageHeader";
import { PageTitle } from "components/PageTitle";
import { StyledProgressBar } from "containers/Consultant";
import {
  NaturalBlock,
  NaturalData,
  NaturalIntro
} from "components/NaturalBlocks";

const Footer = styled.footer`
  padding: ${spacing(2)} 0;
`;

const TagsSection = styled.div`
  width: 100%;
  padding: ${spacing(2)} 0;
`;

const RoleDetail = styled.div`
  flex: 1;
`;

export const AbsoluteLeft = styled(Link)`
  position: absolute;
  top: 28px;
  transform: translateY(-50%);
  cursor: pointer;

  ${tablet(`
    display: none;
  `)};
`;

const ApplicationMessage = styled.span`
  color: ${colors.frenchGray};
  ${semiBold};
  font-size: ${sizing(12)};
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: ${spacing()};
  padding-left: ${spacing(3)};

  ${tablet(`
    padding-left: 0;
  `)};

  span {
    padding-left: ${spacing()};
  }
`;

export class Role extends Component {
  render() {
    const roleId = this.props.match.params.id;

    if (roleId === "create") {
      return null;
    }
    return (
      <Query
        variables={{ id: roleId, userId: this.props.User.id }}
        query={query}
        fetchPolicy={"cache-and-network"}
      >
        {({ loading, error, data }) => (
          <Fragment>
            {loading && !data.role && <Loader />}
            {data.role && (
              <PageLayout
                isDual
                leftAligned
                backLink="/roles"
                header={
                  <PageHeader>
                    {data.role.organisation && data.role.organisation.logo && (
                      <StyledProgressBar
                        onClick={() =>
                          this.props.history.push(
                            `/organisations/${data.role.organisation.id}`
                          )
                        }
                        profile_completeness={100}
                        image={data.role.organisation.logo}
                      />
                    )}
                    <PageTitle sticky>{data.role.title}</PageTitle>
                    {data.role.conversations.length > 0 && (
                      <ApplicationMessage>
                        <AppliedIcon />{" "}
                        <span>You’ve applied for this role</span>
                      </ApplicationMessage>
                    )}
                  </PageHeader>
                }
              >
                <PageSubTitle>Details</PageSubTitle>
                <RoleDetail>
                  {data.role.location && (
                    <NaturalBlock>
                      <NaturalIntro>This role is in: </NaturalIntro>
                      <NaturalData>{locations[data.role.location]}</NaturalData>
                    </NaturalBlock>
                  )}
                  {data.role.day_rate && (
                    <NaturalBlock>
                      <NaturalIntro> at a rate of: </NaturalIntro>
                      <NaturalData>{`£${data.role.day_rate} p/d`}</NaturalData>
                    </NaturalBlock>
                  )}
                  {data.role.experience && (
                    <NaturalBlock>
                      <NaturalIntro>
                        {" "}
                        the required experience level is:{" "}
                      </NaturalIntro>
                      <NaturalData>
                        {experienceLevels[data.role.experience]}
                      </NaturalData>
                    </NaturalBlock>
                  )}
                  <NaturalBlock>
                    <NaturalIntro> and the position is: </NaturalIntro>
                    <NaturalData>
                      {data.role.full_time ? "Full Time" : "Part Time"}
                    </NaturalData>
                  </NaturalBlock>
                </RoleDetail>

                {data.role.tags.length ? (
                  <TagsSection>
                    <PageSubTitle>Tags</PageSubTitle>
                    {data.role.tags.map(tag => (
                      <CancellableTag key={tag.id}>{tag.value}</CancellableTag>
                    ))}
                  </TagsSection>
                ) : null}
                <PageSubTitle>Description</PageSubTitle>
                <TextEditor
                  readOnly
                  defaultValue={data.role.description}
                  key={data.role.id}
                />
                <Footer>
                  <Flag name="consultant">
                    {data.role.conversations.length ? (
                      <Button
                        primary
                        onClick={() =>
                          this.props.history.push(
                            `/messages/${data.role.applications[0].id}`
                          )
                        }
                      >
                        Continue Application
                      </Button>
                    ) : (
                      <Button
                        primary
                        onClick={() =>
                          this.props.history.push(`/roles/${roleId}/apply`)
                        }
                      >
                        Apply for this role
                      </Button>
                    )}
                  </Flag>
                  <Flag name="client">
                    {!data.role.closed ? (
                      <Button
                        onClick={() =>
                          this.props.history.push(`/dashboard/${roleId}/edit`)
                        }
                        primary
                      >
                        Edit Role
                      </Button>
                    ) : (
                      <Button
                        onClick={() =>
                          this.props.history.push(
                            `/dashboard/${roleId}/duplicate`
                          )
                        }
                        primary
                      >
                        Duplicate Role
                      </Button>
                    )}
                    {!data.role.closed && (
                      <Mutation
                        mutation={closeRole}
                        variables={{
                          id: data.role.id
                        }}
                      >
                        {mutation => (
                          <Button primary onClick={() => mutation()}>
                            Close Role
                          </Button>
                        )}
                      </Mutation>
                    )}
                  </Flag>
                </Footer>
              </PageLayout>
            )}
          </Fragment>
        )}
      </Query>
    );
  }
}

const query = gql`
  query($id: ID!, $userId: ID!) {
    role(where: { id: $id }) {
      id
      title
      description
      day_rate
      location
      experience
      full_time
      closed
      tags {
        id
        value
      }
      conversations(where: { sender: { id: $userId } }) {
        id
      }
      organisation {
        id
        name
        logo
      }
    }
  }
`;

const closeRole = gql`
  mutation closeRole($id: ID!) {
    updateRole(where: { id: $id }, data: { closed: true }) {
      id
      closed
    }
  }
`;
