import React, { Fragment, useState } from "react";
import { Mutation } from "react-apollo";
import { PoseGroup } from "react-pose";
import gql from "graphql-tag";
import { ArrowMessage } from "components/ArrowMessage";
import { Button } from "components/Button";
import { PageContainer } from "components/PageContainer";
import { ProgressBar } from "components/ProgressBar";
import {
  Wrapper,
  Header,
  AbsoluteLeft,
  Profile,
  Slider,
  Title,
  Card,
  Slide
} from "./styles";
import { Loader } from "components/Loader";
import auth0Client from "auth/authClient";
import { Form } from "./Form";
import { PageSubTitle } from "components/PageHeader";
import { ApplicationSent } from "components/illustrations";
import { Text, Link } from "components/Text";

const formConfig = {
  fields: [
    {
      label: "Add your picture:",
      name: "avatar",
      type: "image"
    },
    {
      label: "Your first name is:",
      name: "first_names",
      type: "string",
      required: true,
      errorMessage: "First name is required",
      options: {
        autoComplete: "given-name"
      }
    },
    {
      label: "Your last name is:",
      name: "last_name",
      type: "string",
      required: true,
      errorMessage: "Last name is required",
      options: {
        autoComplete: "family-name"
      }
    },
    {
      label: "I can be emailed at:",
      name: "email",
      type: "email",
      required: true
    },
    {
      label: "I could be reached at this number:",
      name: "phone",
      type: "phone",
      required: true
    },
    {
      label: "Your are a:",
      name: "job_title",
      type: "string",
      required: true,
      errorMessage: "Job title is required"
    },
    {
      label: "My daily rate is:",
      name: "day_rate",
      type: "number"
    },
    {
      label: "Your are based in:",
      name: "location",
      type: "location",
      required: true
    },
    {
      label: "How experienced are you:",
      name: "experience_level",
      type: "experience-level"
    },
    {
      label: "Are you willing to travel:",
      name: "willing_to_travel",
      type: "toggle",
      options: {
        activeLabel: "Yes",
        inactiveLabel: "No"
      }
    },
    {
      label: "How do you like to work:",
      name: "part_time",
      type: "toggle",
      options: {
        activeLabel: "Part Time",
        inactiveLabel: "Full Time"
      }
    },
    {
      label: (
        <Text>
          I accept the{" "}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            title="Terms and conditions"
            href="http://coreconsultants.io/terms-and-conditions/"
          >
            {" "}
            Terms and conditions{" "}
          </Link>{" "}
          and the{" "}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            title="Privacy Policy"
            href="http://coreconsultants.io/privacy/"
          >
            {" "}
            Privacy Policy{" "}
          </Link>
        </Text>
      ),
      name: "terms",
      type: "checkbox",
      required: true,
      errorMessage: "Please accept the terms"
    }
  ],
  submitText: "Join",
  initialValues: {
    avatar: "https://s3-eu-west-1.amazonaws.com/cc-app-uploads/user.jpg"
  }
};

export const GetStarted = ({ history: { push } }) => {
  const [error, setError] = useState(null);
  const [values, setValues] = useState({});
  const [stage, setStage] = useState({
    form: true
  });

  return (
    <PageContainer>
      <Wrapper>
        <Mutation
          mutation={createConsultant}
          onCompleted={data => {
            setStage({ success: true });
            auth0Client.changePassword(
              {
                email: data.createConsultant.email,
                connection: "Username-Password-Authentication"
              },
              err => {
                if (err) {
                  console.log(err.error_description);
                }
              }
            );
          }}
          onError={err => {
            setStage({ error: true });
            setError(err);
          }}
        >
          {(createConsultantMutation, { loading }) => {
            if (loading && !stage.loading) {
              setStage({ loading: true });
            }

            return (
              <Fragment>
                <Header>
                  <AbsoluteLeft>
                    {stage.error && (
                      <ArrowMessage
                        onClick={() => {
                          setStage({ form: true });
                          setError(null);
                        }}
                        left
                      >
                        Back
                      </ArrowMessage>
                    )}
                  </AbsoluteLeft>
                  <Profile>
                    <ProgressBar
                      percentage={stage.success ? 75 : 0}
                      image={
                        values.avatar ||
                        "https://s3-eu-west-1.amazonaws.com/cc-app-uploads/user.jpg"
                      }
                    />
                  </Profile>
                </Header>
                <Slider>
                  <PoseGroup>
                    {stage.form && (
                      <Slide key={0}>
                        <Title>Hi, Lets get started.</Title>
                        <Card>
                          <Form
                            config={{
                              ...formConfig,
                              initialValues: { ...values }
                            }}
                            onSubmit={values => {
                              setValues(values);
                              console.log(values);
                              createConsultantMutation({
                                variables: {
                                  ...values,
                                  location: values.location.id,
                                  experience_level: values.experience_level.id,
                                  terms_agreed: values.terms
                                }
                              });
                            }}
                          />
                        </Card>
                      </Slide>
                    )}
                    {stage.loading && (
                      <Slide key={loading}>
                        <Card>
                          <Loader />
                        </Card>
                      </Slide>
                    )}
                    {stage.error && (
                      <Slide key={loading}>
                        <Card>
                          <PageSubTitle>Error!</PageSubTitle>
                          {navigator.onLine ? (
                            <Fragment>
                              <p>
                                There has been an error! Please go back and fix
                                the issues below. If you already have an account
                                please use the forgot password form or login. If
                                this continues to persist then please get in
                                contact at:{" "}
                                <a href="mailto:support@coreconsultants.io">
                                  support@coreconsultants.io
                                </a>
                              </p>
                              <ul>
                                {error.graphQLErrors.map((err, index) => (
                                  <li key={`error--${index}`}>{err.message}</li>
                                ))}
                              </ul>
                              <Button onClick={() => push("/login")} secondary>
                                Login
                              </Button>
                              <Button
                                onClick={() => push("/forgot-password")}
                                secondary
                              >
                                Forgot Password
                              </Button>
                            </Fragment>
                          ) : (
                            <p>
                              You are currently offline. Please connect to the
                              internet and go back and re-submit.
                            </p>
                          )}
                        </Card>
                      </Slide>
                    )}
                    {stage.success && (
                      <Slide key={loading}>
                        <Card>
                          <ApplicationSent
                            style={{
                              width: "300px",
                              margin: "0 auto"
                            }}
                            dark
                          />
                          <p style={{ textAlign: "center" }}>
                            Hi <strong>{values.first_names}</strong> you have
                            signed up. You will recieve an email to{" "}
                            <strong>{values.email}</strong> with a link to set
                            up your password
                          </p>
                          <p style={{ textAlign: "center" }}>
                            Please check your spam if you do not recieve an
                            email.
                          </p>
                          <Button primary onClick={() => push("/login")}>
                            Go to login
                          </Button>
                        </Card>
                      </Slide>
                    )}
                  </PoseGroup>
                </Slider>
              </Fragment>
            );
          }}
        </Mutation>
      </Wrapper>
    </PageContainer>
  );
};

const createConsultant = gql`
  mutation createConsultant(
    $avatar: Upload
    $first_names: String!
    $last_name: String!
    $email: String!
    $phone: String!
    $job_title: String!
    $location: Location
    $day_rate: Int
    $summary: String
    $tags: TagUpdateManyInput
    $experience_level: Experience_level
    $experience: ExperienceUpdateManyWithoutUserInput
    $part_time: Boolean
    $willing_to_travel: Boolean
    $terms_agreed: Boolean
  ) {
    createConsultant(
      avatar: $avatar
      first_names: $first_names
      last_name: $last_name
      email: $email
      phone: $phone
      job_title: $job_title
      day_rate: $day_rate
      summary: $summary
      tags: $tags
      experience_level: $experience_level
      experience: $experience
      part_time: $part_time
      willing_to_travel: $willing_to_travel
      location: $location
      terms_agreed: $terms_agreed
    ) {
      id
      first_names
      last_name
      email
    }
  }
`;
