import { injectGlobal } from "styled-components";
import { normalize, rgba } from "polished";
import colors from "./colors";

import SemiBoldEOT from "assets/fonts/37D38B_0_0.eot";
import SemiBoldWOFF from "assets/fonts/37D38B_0_0.woff";
import SemiBoldWOFF2 from "assets/fonts/37D38B_0_0.woff2";
import SemiBoldTTF from "assets/fonts/37D38B_0_0.ttf";

import RegularEOT from "assets/fonts/37D38B_1_0.eot";
import RegularWOFF from "assets/fonts/37D38B_1_0.woff";
import RegularWOFF2 from "assets/fonts/37D38B_1_0.woff2";
import RegularTTF from "assets/fonts/37D38B_1_0.ttf";

import BoldEOT from "assets/fonts/37D38B_2_0.eot";
import BoldWOFF from "assets/fonts/37D38B_2_0.woff";
import BoldWOFF2 from "assets/fonts/37D38B_2_0.woff2";
import BoldTTF from "assets/fonts/37D38B_2_0.ttf";
import { datePickerStyles } from "./datePicker";
import { spacing, sizing } from "./sizing";
import { bold, semiBold, regular } from "./typography";

injectGlobal`
    ${normalize()};
   

    @font-face {
        font-family: 'Gilroy-SemiBold';
        src: url(${SemiBoldEOT});
        src: url('${SemiBoldEOT}?#iefix') format('embedded-opentype'),
        url(${SemiBoldWOFF2}) format('woff2'),
        url(${SemiBoldWOFF}) format('woff'),
        url(${SemiBoldTTF}) format('truetype');
    }
    
    
    @font-face {
        font-family: 'Gilroy-Regular';
        src: url(${RegularEOT});
        src: url('${RegularEOT}?#iefix') format('embedded-opentype'),
        url(${RegularWOFF2}) format('woff2'),
        url(${RegularWOFF}) format('woff'),
        url(${RegularTTF}) format('truetype');
    }
    
    @font-face {
        font-family: 'Gilroy-Bold';
        src: url(${BoldEOT});
        src: url('${BoldEOT}?#iefix') format('embedded-opentype'),
        url(${BoldWOFF2}) format('woff2'),
        url(${BoldWOFF}) format('woff'),
        url(${BoldTTF}) format('truetype');
    }

    * {
        box-sizing: border-box;
    }

    html, body, #root {
        height: 100%;
        margin: 0;
        padding: 0;
        color: ${colors.blackRock};
        overflow: hidden;
        font-family: 'Gilroy-Regular';
    }

    .ReactModal__Overlay {
        display: flex;
        background-color: ${rgba(colors.liquorice, 0.8)} !important;
        z-index: 20;

        .ReactModal__Content > div {
            height: 100%;
        }
        .ReactModal__Content {
            overflow: scroll;
        }
    }

    input[type=text], textarea {   
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; 
    }

    ${datePickerStyles};

    h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: ${sizing(18)};
    ${semiBold};
    color: ${colors.brightGray};
  }

  strong {
    ${bold};
  }

  p {
    line-height: 1.4;
  }

  a {
    color: ${colors.palatinateBlue};
  }

  strike {
    text-decoration: line-through;
  }

  blockquote {
    margin: ${spacing(2)};
    border-left: 2px solid ${colors.snuff};
    padding: ${spacing(1)};
  }

  ul,
  ol {
    padding-left: ${spacing(2)};
    list-style-type: decimal;
    ${bold};

    li {
      ${regular};
    }
  }

  ol { counter-reset: item }
    ol > li { display: block }
    ol > li:before { content: counters(item, ".") ". "; counter-increment: item }
`;
