import React, { Fragment } from "react";
import { Autocomplete } from "components/Autocomplete";
import { Query } from "react-apollo";
import { debounce } from "loadsh";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OrganisationInput = ({
  values,
  query,
  style,
  name,
  id,
  onChange,
  isSingle
}) => {
  return (
    <Wrapper style={style}>
      <Fragment>
        <Query
          query={query}
          variables={{
            search: "",
            id
          }}
        >
          {({ data, fetchMore }) => (
            <Autocomplete
              isSingle={isSingle}
              name={name}
              items={
                data && data.organisations
                  ? data.organisations
                      .filter(i =>
                        values && values.length
                          ? values.every(v => v.id !== i.id)
                          : i
                      )
                      .map(i => ({
                        id: i.id,
                        value: i.name,
                        ...i
                      }))
                  : []
              }
              onChange={value => {
                onChange(value);
              }}
              onInputValueChange={value => {
                debounce(
                  () =>
                    fetchMore({
                      search: value.toLowerCase()
                    }),
                  1000
                );
              }}
            />
          )}
        </Query>
      </Fragment>
    </Wrapper>
  );
};
