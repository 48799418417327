import React from "react";
import styled from "styled-components";
import { spacing, sizing } from "styles/sizing";
import colors from "styles/colors";
import { bold } from "styles/typography";
import { NavLink } from "react-router-dom";

const TeamCardWrapper = styled(NavLink)`
  padding: ${spacing(1)};
  width: 50%;
`;

const TeamCardInner = styled.div`
  padding: ${spacing(1)};
  border: 1px solid ${colors.snuff};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background 0.4s ease;

  &:hover {
    background: ${colors.carribeanGreen};
  }
`;

export const TeamName = styled.span`
  font-size: ${sizing(16)};
  text-align: center;
  padding: ${spacing(1)} 0;
  text-transform: capitalize;
  color: ${colors.blackRock};
  text-decoration: none;
`;

export const TeamRole = styled.span`
  padding: ${spacing(0.5)} ${spacing(1)};
  color: ${colors.white};
  background: ${colors.liquorice};
  margin-bottom: ${spacing()};
  border-radius: 4px;
  width: fit-content;
  font-size: ${sizing(14)};
  ${bold};
`;

export const TeamImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

export const TeamCard = ({ children, to }) => (
  <TeamCardWrapper to={to}>
    <TeamCardInner>{children}</TeamCardInner>
  </TeamCardWrapper>
);
