import React, { Component, Fragment } from "react";
import { Modal } from "components/Modal";
import { Title, Header, Body } from "./style";
import { Form } from "components/Form";
import * as Yup from "yup";
import { Field, FieldWrapper } from "components/FormField";
import { Label } from "components/FormField/styles";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { Autocomplete } from "components/Autocomplete";
import { locations } from "constants/locations";
import { isValid } from "date-fns";
import { experienceLevels } from "constants/experienceLevels";
import { ExperienceInput } from "components/ExperienceInput";
import { ToggleSwitch } from "components/ToggleSwitch";
import { TagInput } from "components/TagInput";
import { TextEditor } from "components/TextEditor";
import { ImageUploader } from "components/ImageUploader";
import { AnimatedWrapper } from "containers/DeleteAccount/styles";
import { SubmittedTitle } from "containers/OrginisationJoin/styles";
import { ImageContainer } from "containers/OrganisationInvite/styles";
import { ApplicationSent, NotFound } from "components/illustrations";
import { Button } from "components/Button";
import { PoseGroup } from "react-pose";
import { Loader } from "components/Loader";
import { ConfirmationDialog } from "components/ConfirmationDialog";

export class CreateConsultant extends Component {
  state = {
    isFormSubmitted: false,
    error: false,
    loading: false
  };
  getTagVariables = (currentValues, previousValues) => {
    return {
      createTags: currentValues
        .filter(i => i.create)
        .map(i => ({ value: i.value })),
      updateTags: currentValues
        .filter(i => !i.create)
        .filter(
          i =>
            previousValues.length === 0 ||
            previousValues.some(p => p.id !== i.id)
        )
        .map(i => ({ id: i.id })),
      deleteTags: previousValues
        .filter(i => currentValues.every(p => p.id !== i.id))
        .map(i => ({ id: i.id }))
    };
  };

  getExperienceVariables = currentValues => {
    return {
      createExperience: currentValues
        .filter(i => i.create)
        .map(exp => ({
          title: exp.title,
          company: exp.company,
          description: exp.description,
          start_date: exp.start_date,
          end_date: exp.end_date === null ? null : exp.end_date
        })),
      updateExperience: currentValues
        .filter(i => !i.create)
        .map(exp => ({
          where: {
            id: exp.id
          },
          data: {
            title: exp.title,
            company: exp.company,
            description: exp.description,
            start_date: exp.start_date,
            end_date: exp.end_date === null ? null : exp.end_date
          }
        }))
    };
  };
  render() {
    const { isFormSubmitted, error } = this.state;
    return (
      <Modal
        isOpen
        onRequestClose={() =>
          ConfirmationDialog({
            message: "Are you sure you wish to exit, your data will be lost?",
            primaryText: "Close",
            primaryAction: () => this.props.history.push("/consultants"),
            secondaryText: "Cancel",
            secondaryAction: () => null
          })
        }
      >
        <PoseGroup>
          {!isFormSubmitted ? (
            <AnimatedWrapper key="form">
              <Header>
                <Title>Create Consultant</Title>
              </Header>
              <Body>
                <Mutation mutation={createConsultant}>
                  {(mutation, { error, loading }) => {
                    if (error) {
                      this.setState({ error });
                    }

                    if (loading) {
                      return <Loader />;
                    }

                    return (
                      <Form
                        initialValues={{
                          location: {},
                          tags: [],
                          experience_level: {},
                          part_time: false,
                          experience: []
                        }}
                        validationSchema={Yup.object().shape({
                          job_title: Yup.string().required(
                            "Job Title is a required field"
                          ),
                          experience_level: Yup.object()
                            .shape({
                              id: Yup.string(),
                              value: Yup.string()
                            })
                            .required("Please include an experience level"),
                          location: Yup.object()
                            .shape({
                              id: Yup.string(),
                              value: Yup.string()
                            })
                            .required("Please include a location"),

                          day_rate: Yup.number().required(
                            "Day Rate is a required field"
                          ),
                          email: Yup.string()
                            .email("You need to enter a valid email")
                            .required("Email is a required field"),
                          phone: Yup.string()
                            .matches(
                              /^(0\d{10,12}|44\d{10,12})$/s,
                              "Please enter a valid phone number"
                            )
                            .required("Phone Number is a required field"),

                          tags: Yup.array()
                            .of(
                              Yup.object().shape({
                                id: Yup.string(),
                                value: Yup.string()
                              })
                            )
                            .min(5, "You have to have a minimum of 5 tags"),
                          experience: Yup.array()
                            .of(
                              Yup.object().shape({
                                title: Yup.string().required(
                                  "Please include your job title"
                                ),
                                company: Yup.string().required(
                                  "Please include the company you worked for."
                                ),
                                description: Yup.string().required(
                                  "Please include a short description"
                                ),
                                start_date: Yup.date().required(
                                  "Please include a start date."
                                ),
                                end_date: Yup.date()
                                  .nullable(true)
                                  .when("start_date", (start_date, schema) => {
                                    return isValid(new Date(start_date))
                                      ? schema.min(
                                          start_date,
                                          "End date needs to be larger than start date."
                                        )
                                      : schema;
                                  })
                              })
                            )
                            .min(
                              1,
                              "You need to include one previous experience"
                            ),
                          summary: Yup.string().required(
                            "Summary is a required field"
                          )
                        })}
                        onReset={() => this.props.history.push("/consultants")}
                        onSubmit={values => {
                          const tags = this.getTagVariables(values.tags, []);
                          const experience = this.getExperienceVariables(
                            values.experience
                          );
                          mutation({
                            variables: {
                              avatar: values.avatar,
                              first_names: values.first_names.toLowerCase(),
                              last_name: values.last_name.toLowerCase(),
                              email: values.email,
                              phone: values.phone,
                              job_title: values.job_title.toLowerCase(),
                              willing_to_travel: values.willing_to_travel,
                              day_rate: values.day_rate,
                              part_time: values.part_time,
                              summary: values.summary,
                              tags: {
                                connect: tags.updateTags || [],
                                create: tags.createTags || []
                              },
                              experience: {
                                create: experience.createExperience
                              },
                              location: values.location.id,
                              experience_level: values.experience_level.id
                            }
                          });
                          this.setState({ isFormSubmitted: true });
                        }}
                      >
                        {(errors, values, setFieldValue, touched) => (
                          <Fragment>
                            <ImageUploader
                              onChange={file => setFieldValue("avatar", file)}
                              image={values.avatar}
                            />
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label name="title">First names</Label>
                              <Field name="first_names" />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label name="last_name">Last name</Label>
                              <Field name="last_name" />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label name="email">Email</Label>
                              <Field name="email" />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label>Summary</Label>
                              <TextEditor
                                defaultValue={
                                  values["summary"] ? values.summary : ""
                                }
                                onChange={value =>
                                  setFieldValue("summary", value)
                                }
                              />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label name="location">Location</Label>
                              <Autocomplete
                                isSingle
                                style={{ width: "100%" }}
                                items={Object.keys(locations).map(key => ({
                                  id: key,
                                  isActive: key === values.location.id,
                                  value: locations[key]
                                }))}
                                onChange={value =>
                                  setFieldValue("location", value)
                                }
                              />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label name="phone">Phone</Label>
                              <Field name="phone" />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label name="job_title">Job Title</Label>
                              <Field name="job_title" />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label name="experience">Experience</Label>
                              <ExperienceInput
                                values={values.experience}
                                setFieldValue={setFieldValue}
                                errors={errors.experience}
                              />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label>Day Rate</Label>
                              <Field
                                name="day_rate"
                                type="number"
                                step={1}
                                max={10000}
                                min={1}
                              />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label>Experience Level</Label>
                              <Autocomplete
                                isSingle
                                style={{ width: "100%" }}
                                items={Object.keys(experienceLevels).map(
                                  key => ({
                                    id: key,
                                    isActive:
                                      key === values.experience_level.id,
                                    value: experienceLevels[key]
                                  })
                                )}
                                onChange={value =>
                                  setFieldValue("experience_level", value)
                                }
                              />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label>Tags</Label>
                              <TagInput
                                query={queryTags}
                                values={values.tags}
                              />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label>Willing to travel</Label>
                              <ToggleSwitch
                                value={values.willing_to_travel}
                                activeLabel="Yes"
                                inactiveLabel="No"
                                onChange={value =>
                                  setFieldValue("willing_to_travel", value)
                                }
                              />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label>Likes to work:</Label>
                              <ToggleSwitch
                                value={values.part_time}
                                activeLabel="Part Time"
                                inactiveLabel="Full Time"
                                onChange={value =>
                                  setFieldValue("part_time", value)
                                }
                              />
                            </FieldWrapper>
                          </Fragment>
                        )}
                      </Form>
                    );
                  }}
                </Mutation>
              </Body>
            </AnimatedWrapper>
          ) : (
            <AnimatedWrapper key="result">
              <SubmittedTitle>
                {error
                  ? "There was an error"
                  : "your consultant has been created"}
              </SubmittedTitle>
              <ImageContainer>
                {error ? <NotFound dark /> : <ApplicationSent dark />}
              </ImageContainer>
              <Button
                primary
                type="button"
                onClick={() => this.props.history.push(`/consultants`)}
              >
                Close
              </Button>
            </AnimatedWrapper>
          )}
        </PoseGroup>
      </Modal>
    );
  }
}

const createConsultant = gql`
  mutation createConsultant(
    $avatar: Upload
    $first_names: String!
    $last_name: String!
    $email: String!
    $phone: String!
    $job_title: String!
    $day_rate: Int
    $summary: String
    $tags: TagUpdateManyInput
    $experience_level: Experience_level
    $experience: ExperienceUpdateManyWithoutUserInput
    $part_time: Boolean
    $willing_to_travel: Boolean
    $location: Location
  ) {
    createConsultant(
      avatar: $avatar
      first_names: $first_names
      last_name: $last_name
      email: $email
      phone: $phone
      job_title: $job_title
      day_rate: $day_rate
      summary: $summary
      tags: $tags
      experience_level: $experience_level
      experience: $experience
      part_time: $part_time
      willing_to_travel: $willing_to_travel
      location: $location
    ) {
      id
    }
  }
`;

const queryTags = gql`
  query tags($search: String!) {
    tags(where: { value_contains: $search }) {
      id
      value
    }
  }
`;
