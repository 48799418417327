import React, { Fragment } from "react";
import styled from "styled-components";
import { spacing, sizing } from "styles/sizing";
import colors from "styles/colors";
import { ArrowIcon } from "components/icons";
import { Link } from "react-router-dom";
import { Button } from "components/Button";

const Wrapper = styled(Link)`
  font-family: "Gilroy-SemiBold";
  font-size: ${sizing(14)};
  color: ${colors.white};
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const InlineButton = styled(Button)`
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Span = styled.div`
  padding: 0 ${spacing(1.5)};
`;

export const ArrowMessage = ({ children, left, right, to, onClick }) => (
  <Fragment>
    {onClick && (
      <InlineButton onClick={onClick} transparent>
        {left && <ArrowIcon rotate={180} />}
        <Span>{children}</Span>
        {right && <ArrowIcon rotate={0} />}
      </InlineButton>
    )}
    {to && (
      <Wrapper to={to}>
        {left && <ArrowIcon rotate={180} />}
        <Span>{children}</Span>
        {right && <ArrowIcon rotate={0} />}
      </Wrapper>
    )}
  </Fragment>
);
