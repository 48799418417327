import React, { Fragment } from "react";
import { FieldArray } from "formik";
import { Autocomplete } from "components/Autocomplete";
import { CancellableTag } from "components/Tag";
import { Query } from "react-apollo";
import { debounce } from "loadsh";
import styled from "styled-components";
import { spacing } from "styles/sizing";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TagContainer = styled.div`
  padding: ${spacing(2)} 0;
  flex: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

export const TagInput = ({ values, query, style }) => {
  return (
    <Wrapper style={style}>
      <FieldArray
        name="tags"
        render={arrayHelpers => (
          <Fragment>
            <Query
              query={query}
              variables={{
                search: ""
              }}
            >
              {({ data, fetchMore }) => (
                <Autocomplete
                  create
                  name="tags"
                  items={
                    data && data.tags
                      ? data.tags.filter(i =>
                          values ? values.every(v => v.value !== i.value) : i
                        )
                      : []
                  }
                  onChange={value => {
                    arrayHelpers.push(value);
                  }}
                  onInputValueChange={value => {
                    debounce(
                      () =>
                        fetchMore({
                          search: value.toLowerCase()
                        }),
                      1000
                    );
                  }}
                />
              )}
            </Query>
            <TagContainer>
              {values &&
                values.map((tag, index) => (
                  <CancellableTag
                    key={tag.id || tag.value}
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    {tag.value}
                  </CancellableTag>
                ))}
            </TagContainer>
          </Fragment>
        )}
      />
    </Wrapper>
  );
};
