import { tablet } from "styles/media";
import styled from "styled-components";
import colors from "styles/colors";
import { spacing, sizing } from "styles/sizing";
import { regular, semiBold } from "styles/typography";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  width: 100%;

  ${tablet(`
    border-right: 1px solid ${colors.snuff};
    max-width: 500px;
  `)}
`;

export const Results = styled.div`
  width: 100%;
  flex: 1;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  ${regular};
  font-size: ${sizing(30)};
  text-align: center;
  margin: 0;
  padding: ${spacing(3)} 0 ${spacing()} 0;
`;

export const Info = styled.span`
  display: block;
  ${semiBold};
  color: ${colors.liquorice};
  font-size: ${sizing(14)};
  text-align: center;
  margin: 0;
  padding-bottom: ${spacing()};
`;

export const IllustrationContainer = styled.div`
  padding: ${spacing(2)} ${spacing(5)};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
