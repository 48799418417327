import styled, { css } from "styled-components";
import { FormField } from "components/FormField";
import colors from "styles/colors";
import { semiBold } from "styles/typography";
import { sizing, spacing } from "styles/sizing";

export const Wrapper = styled.div`
  position: relative;
  background: ${colors.white};
  width: 100%;
  max-width: 480px;
`;

export const Input = styled(FormField)`
  padding: 0;

  label {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    color: ${colors.ghost};
    transform: translateY(-50%);
  }

  input {
    padding: ${spacing()} ${spacing()} ${spacing()}
      ${props => (!props.disabled ? spacing(4) : spacing())};
    ${props =>
      !props.disabled
        ? `border-bottom: 1px solid ${colors.ghost}`
        : "border: none"};
    ${semiBold};
    font-size: ${sizing(16)};
    text-align: left;
    color: ${colors.blackRock};

    &:focus,
    &:not(:empty) {
      border-color: ${colors.neonBlue};
    }
  }
`;

export const ResultList = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: ${colors.white};
  border: 1px solid ${colors.ghost};
  box-shadow: 0 3px 8px 0px rgba(0, 0, 0, 0.1);
  list-style: none;
  z-index: 10;
  max-height: 160px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  &:empty {
    display: none;
  }
`;

export const ResultItem = styled.li`
  padding: ${spacing(2)} ${spacing(3)};
  background: ${colors.white};
  font-size: ${sizing(14)};
  color: ${colors.blackRock};
  text-transform: capitalize;

  display: flex;
  align-items: center;

  ${props =>
    props.isActive &&
    css`
      background-color: ${colors.neonBlue};
      color: ${colors.white};
    `}

  &:hover,
  &:active,
  &:focus {
    background-color: ${colors.neonBlue};
    color: ${colors.white};
  }

  + li {
    border-top: 1px solid ${colors.ghost};
  }
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: ${spacing(0.5)};
`;
