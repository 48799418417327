import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";
import { spacing, sizing } from "styles/sizing";
import { regular } from "styles/typography";
import colors from "styles/colors";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";

const MessageBox = styled.div`
  border: 1px solid ${colors.snuff};
  padding: ${spacing(2)};
  margin: ${spacing(2)} 0;
  width: 100%;
  ${regular};
  font-size: ${sizing(14)};
  color: ${colors.blackRock};
  position: relative;
  display: flex;
`;

export const MessageContainer = ({ children, id, read_reciept, disabled }) => (
  <VisibilitySensor delayedCall={true}>
    {({ isVisible }) => (
      <Mutation mutation={messageRead}>
        {mutation => {
          if (!read_reciept && isVisible && !disabled) {
            mutation({
              variables: {
                id: id
              }
            });
          }
          return <MessageBox>{children}</MessageBox>;
        }}
      </Mutation>
    )}
  </VisibilitySensor>
);

const messageRead = gql`
  mutation updateMessage($id: ID!) {
    updateMessage(id: $id, read_reciept: true) {
      id
      read_reciept
    }
  }
`;
