import styled from "styled-components";
import { spacing, sizing } from "styles/sizing";
import colors from "styles/colors";
import { semiBold } from "styles/typography";

export const Header = styled.header`
  width: 100%;
  padding: 0 ${spacing(2)};
  border-bottom: 1px solid ${colors.snuff};
`;

export const Title = styled.h1`
  font-size: ${sizing(18)};
  color: ${colors.blackRock};
  ${semiBold};
  text-align: center;
`;

export const Body = styled.section`
  padding: ${spacing(2)} 0;
  width: 100%;
  overflow: scroll;
  border-bottom: 1px solid ${colors.snuff};
`;

export const Footer = styled.section`
  width: 100%;
  padding: 0 ${spacing(2)};
`;
