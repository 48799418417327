import React from "react";
import { Icon } from "./Icon";

export const SearchIcon = props => (
  <Icon {...props} viewBox="0 0 20 14">
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M13.042 12.934l2.886 2.887-.707.707-2.91-2.91a7.2 7.2 0 1 1 .731-.683zm.932-4.908a6.2 6.2 0 1 0-12.4 0 6.2 6.2 0 0 0 12.4 0z"
    />
  </Icon>
);
