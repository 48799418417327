import React, { Component, Fragment } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import { Loader } from "components/Loader";
import { semiBold } from "styles/typography";
import colors from "styles/colors";
import { sizing, spacing } from "styles/sizing";
import { NotFound } from "components/illustrations";
import { Tag } from "components/Tag";
import { isTabletAndUp } from "styles/media";
import { parse, stringify } from "query-string";
import { Filters } from "./filters";
import { debounce } from "loadsh";
import { experienceLevels } from "constants/experienceLevels";
import { PageLayout, PageHeader } from "components/PageLayout";
import { FilterArea, FilterStatus } from "components/Filters";
import { Flag } from "flag";
import { PageTitle } from "components/PageTitle";
import {
  SummaryTile,
  SummaryDetail,
  SummaryImage,
  SummaryTitle,
  SummarySubtitle
} from "components/Summary";
import { FloatingButton } from "components/PageLayout/style";
import Helmet from "react-helmet";

const Results = styled.div`
  width: 100%;
  flex: 1;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
`;

const Info = styled.span`
  display: block;
  ${semiBold};
  color: ${colors.liquorice};
  font-size: ${sizing(14)};
  text-align: center;
  margin: 0;
  padding-bottom: ${spacing()};
`;

const IllustrationContainer = styled.div`
  padding: ${spacing(2)} ${spacing(5)};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export class Consultants extends Component {
  state = {
    allEntitiesRecieved: false,
    filtersActive: false
  };

  shouldComponentUpdate(nextProps) {
    if (
      this.props.location.search !== nextProps.location.search &&
      this.props.location.pathname !== nextProps.location.pathname
    ) {
      this.setState({
        allEntitiesRecieved: false
      });
    }
    return true;
  }

  generateFilters = () => {
    const {
      tab,
      search,
      tags,
      day_rate_low,
      day_rate_high,
      experience
    } = parse(this.props.location.search);
    const { User } = this.props;

    const filters = { data: {} };

    if (day_rate_low) {
      filters.data.day_rate_gte = parseInt(day_rate_low, 10);
    }

    if (day_rate_high) {
      filters.data.day_rate_lte = parseInt(day_rate_high, 10);
    }

    if (experience) {
      filters.data.experience_level_in = experience;
    }

    if (tags) {
      filters.data.tags_some = {
        id_in: tags
      };
    }

    if (search) {
      filters.OR = [
        {
          first_names_contains: search.toLowerCase()
        },
        {
          last_name_contains: search.toLowerCase()
        },
        {
          data: {
            job_title_contains: search.toLowerCase()
          }
        }
      ];
    }

    if (tab === "applied") {
      filters.applications_some = {
        consultant: { id: User.id }
      };
    }

    return filters;
  };

  handleFilterSubmit = values => {
    const current = parse(this.props.location.search);
    this.setState({ filtersActive: false });

    const rate = {};

    if (values.rate[0] > 0) {
      rate.day_rate_low = values.rate[0];
    }
    if (values.rate[1] > 0) {
      rate.day_rate_high = values.rate[1];
    }

    this.props.history.push({
      search: stringify({
        ...current,
        ...{
          tags: values.tags.map(i => i.id),
          experience: values.experience.map(i => i.id)
        },
        ...rate
      })
    });
  };

  handleSearch = value => {
    const current = parse(this.props.location.search);
    debounce(
      () =>
        this.props.history.push({
          search: stringify({
            ...current,
            ...{
              search: value.toLowerCase()
            }
          })
        }),
      1000
    )();
  };

  render() {
    const { allEntitiesRecieved, filtersActive } = this.state;
    const { tab } = parse(this.props.location.search);
    const filters = this.generateFilters();
    const currentSearch = parse(this.props.location.search);
    const filterCount = Object.keys(currentSearch).filter(
      key => key !== "tab" || (key === "search" && currentSearch[key] !== "")
    ).length;

    return (
      <Query
        query={query}
        variables={{
          skip: 0,
          filters
        }}
        fetchPolicy={"cache-and-network"}
        onCompleted={data => {
          if (!allEntitiesRecieved && data.consultants.length < 20) {
            this.setState({
              allEntitiesRecieved: true
            });
          }
        }}
      >
        {({ loading, error, data, fetchMore }) => {
          if (
            data &&
            data.consultants &&
            data.consultants[0] &&
            isTabletAndUp() &&
            this.props.match.isExact
          ) {
            this.props.history.push({
              pathname: `/consultants/${data.consultants[0].id}`,
              search: this.props.location.search
            });
          }
          return (
            <PageLayout
              isDual
              rightAligned
              onScrollBottom={() => {
                if (!allEntitiesRecieved) {
                  fetchMore({
                    variables: {
                      skip: data.consultants.length,
                      filters: filters
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult || loading) return prev;

                      if (fetchMoreResult.consultants.length < 20) {
                        this.setState({
                          allEntitiesRecieved: true
                        });
                      }

                      return Object.assign({}, prev, {
                        consultants: [
                          ...prev.consultants,
                          ...fetchMoreResult.consultants
                        ]
                      });
                    }
                  });
                }
              }}
              header={
                <PageHeader>
                  <Helmet>
                    <title>Consultants - Core Consultants</title>
                  </Helmet>
                  <Filters
                    isActive={filtersActive}
                    onRequestClose={() =>
                      this.setState({ filtersActive: false })
                    }
                    onSubmit={this.handleFilterSubmit}
                    currentFilters={currentSearch}
                  />

                  <PageTitle leftAligned>
                    Consultants
                    <Flag name="admin">
                      <FloatingButton
                        primary
                        onClick={() =>
                          this.props.history.push(
                            "/consultants/create-consultant"
                          )
                        }
                      >
                        Add Consultant
                      </FloatingButton>
                    </Flag>
                  </PageTitle>

                  <FilterArea
                    defaultValue={currentSearch.search || ""}
                    onChange={e => this.handleSearch(e.target.value)}
                    onClick={() => this.setState({ filtersActive: true })}
                  />

                  {filterCount ? (
                    <FilterStatus
                      sticky
                      count={filterCount}
                      onClick={() => {
                        if (this.searchElement) {
                          this.searchElement.value = "";
                        }

                        this.props.history.push({
                          search: stringify({ tab })
                        });
                      }}
                    />
                  ) : null}
                </PageHeader>
              }
            >
              <Results>
                {loading && !data && !data.consultants && <Loader />}
                {data && data.consultants && !data.consultants.length && (
                  <Fragment>
                    <PageHeader>No results here</PageHeader>
                    <Info>Try a different set of key words</Info>
                    <IllustrationContainer>
                      <NotFound />
                    </IllustrationContainer>
                  </Fragment>
                )}
                {data && data.consultants && data.consultants.length && (
                  <Fragment>
                    {data.consultants.map((consultant, index) => (
                      <SummaryTile
                        key={`consultant-${index}`}
                        to={{
                          pathname: `/consultants/${consultant.id}`,
                          search: this.props.location.search
                        }}
                      >
                        <SummaryImage url={consultant.avatar} />
                        <SummaryDetail>
                          <SummaryTitle>{`${consultant.first_names} ${
                            consultant.last_name
                          }`}</SummaryTitle>
                          <SummarySubtitle>
                            {consultant.data.job_title}
                          </SummarySubtitle>
                          {consultant.data.day_rate && (
                            <Tag dark>{`£${consultant.data.day_rate} p/d`}</Tag>
                          )}
                          {consultant.data.experience_level && (
                            <Tag dark>
                              {
                                experienceLevels[
                                  consultant.data.experience_level
                                ]
                              }
                            </Tag>
                          )}
                        </SummaryDetail>
                      </SummaryTile>
                    ))}
                    {!allEntitiesRecieved && (
                      <div style={{ height: "50px" }}>
                        <Loader width="40px" />
                      </div>
                    )}
                  </Fragment>
                )}
              </Results>
            </PageLayout>
          );
        }}
      </Query>
    );
  }
}

const query = gql`
  query($skip: Int, $filters: UserWhereInput) {
    consultants(skip: $skip, first: 20, where: $filters) {
      id
      first_names
      last_name
      avatar
      data {
        id
        job_title
        experience_level
        day_rate
      }
    }
  }
`;
