import React from "react";
import { Icon } from "./Icon";

export const ArrowIcon = props => (
  <Icon
    {...props}
    viewBox="0 0 20 14"
    style={{ transform: `rotate(${props.rotate}deg)` }}
  >
    <g
      fill="none"
      fillRule="nonzero"
      stroke={props.fill}
      strokeLinecap="round"
      strokeWidth="2"
    >
      <path d="M12.36396116 1.41421364l5.65685422 5.65685423-5.65685424 5.65685424M17.4142136 7H1.5" />
    </g>
  </Icon>
);

ArrowIcon.defaultProps = {
  fill: "currentColor",
  rotate: 0
};
