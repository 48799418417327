import React from "react";
import { string } from "prop-types";
import styled, { keyframes } from "styled-components";
import colors from "styles/colors";

const animation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  flex: 1;
  max-width: 600px;
`;

const Path = styled.path`
  animation: ${animation} 2s linear infinite;
  animation-direction: ${props => (props.reverse ? "reverse" : "normal")};
  transform-origin: ${props => props.transformOrigin};
`;

export function Loader({ width, ...props }) {
  return (
    <Wrapper>
      <svg width={width} viewBox="0 0 143 143">
        <g
          fill="none"
          fillRule="evenodd"
          strokeWidth="6"
          transform="translate(-8 -8)"
        >
          <circle
            cx="79.5061728"
            cy="79.5061728"
            r="16.2962963"
            stroke={colors.carribeanGreen}
          />
          <Path
            stroke={colors.liquorice}
            strokeLinecap="round"
            d="M79.2274502,122.863814 C103.327148,122.863814 122.863814,103.327148 122.863814,79.2274502 C122.863814,55.127752 103.327148,35.5910865 79.2274502,35.5910865 C55.127752,35.5910865 35.5910865,55.127752 35.5910865,79.2274502 C35.5910865,93.4840236 42.42796,106.14376 53.0018773,114.106829"
            opacity=".90000004"
            transform="rotate(-108 79.22745 79.22745)"
            reverse
            transformOrigin="79.22745px 79.22745px"
          />
          <Path
            stroke={colors.liquorice}
            strokeLinecap="round"
            d="M80.885241,147.462335 C117.772933,146.768493 147.462335,116.646556 147.462335,79.589663 C147.462335,42.0978657 117.071966,11.7047588 79.5835467,11.7047588 C42.0951273,11.7047588 11.7047588,42.0978657 11.7047588,79.589663 C11.7047588,107.34409 28.3591196,131.208332 52.2185179,141.732214"
            opacity=".5"
            transform="rotate(-101 79.583547 79.583547)"
            transformOrigin="79.583547px 79.583547px"
          />
        </g>
      </svg>
    </Wrapper>
  );
}

Loader.propTypes = {
  width: string
};

Loader.defaultProps = {
  width: "200px"
};
