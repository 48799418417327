import React from "react";
import { node, bool, string } from "prop-types";
import { StyledModal, Title } from "./styles";

export const Modal = ({ isOpen, children, title, ...props }) => {
  return (
    <StyledModal
      isOpen={isOpen}
      contentLabel={title ? `${title} Modal` : "App Modal"}
      {...props}
    >
      {title && <Title>{title}</Title>}
      {children}
    </StyledModal>
  );
};

Modal.propTypes = {
  children: node.isRequired,
  title: string,
  isOpen: bool
};
