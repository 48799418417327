import React from "react";
import styled from "styled-components";
import { spacing, sizing } from "styles/sizing";
import colors from "styles/colors";
import { bold } from "styles/typography";
import { Button } from "components/Button";

const UserCardWrapper = styled.div`
  padding: ${spacing(1)};
  width: 50%;
`;

const UserCardInner = styled.div`
  padding: ${spacing(1)};
  border: 1px solid ${colors.snuff};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const UserName = styled.span`
  font-size: ${sizing(16)};
  text-align: center;
  padding: ${spacing(1)} 0;
  text-transform: capitalize;
`;

export const UserRole = styled.span`
  padding: ${spacing(0.5)} ${spacing(1)};
  color: ${colors.white};
  background: ${colors.liquorice};
  margin-bottom: ${spacing()};
  border-radius: 4px;
  width: fit-content;
  font-size: ${sizing(14)};
  ${bold};
`;

export const UserImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

export const UserAction = styled(Button)`
  cursor: pointer;
  display: block;
  margin: ${spacing(0.5)} 0;
  padding: ${spacing()};
  font-size: ${sizing(14)};
`;

export const UserCard = ({ children }) => (
  <UserCardWrapper>
    <UserCardInner>{children}</UserCardInner>
  </UserCardWrapper>
);
