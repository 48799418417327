import React, { Component, Fragment } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import { Loader } from "components/Loader";
import { spacing, sizing } from "styles/sizing";
import { semiBold, regular } from "styles/typography";
import colors from "styles/colors";
import { Button } from "components/Button";
import { CancellableTag } from "components/Tag";
import { tablet, desk } from "styles/media";
import { format } from "date-fns";
import { ProgressBar } from "components/ProgressBar";
import { TextEditor } from "components/TextEditor";
import { PageLayout, PageHeader } from "components/PageLayout";
import { Flag } from "flag";
import { authClient } from "auth/authClient";
import { PageSubTitle } from "components/PageHeader";
import { PageTitle } from "components/PageTitle";
import { locations } from "constants/locations";
import { experienceLevels } from "constants/experienceLevels";
import { ArrowMessage } from "components/ArrowMessage";
import {
  NaturalBlock,
  NaturalIntro,
  NaturalData
} from "components/NaturalBlocks";
import { ConfirmationDialog } from "components/ConfirmationDialog";

const Body = styled.section`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  flex: 1;
`;

const Footer = styled.footer`
  padding: ${spacing(2)};
  border-top: 1px solid ${colors.snuff};
`;

const AbsoluteLeft = styled.div`
  position: absolute;
  top: 28px;
  transform: translateY(-50%);
  cursor: pointer;
  left: ${spacing(2)};

  ${tablet(`
    display: none;
  `)};
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const SectionContent = styled.div`
  color: ${colors.liquorice};
  line-height: ${sizing(24)};
`;

const Content = styled.section`
  flex-grow: 0;

  ${desk(`
    flex-grow: 1;
    overflow: auto;
  `)};
`;

const Section = styled.div`
  position: relative;
  margin-bottom: ${spacing(3)};
  padding-bottom: ${spacing(3)};
  border-bottom: 1px solid ${colors.antiFlashWhite};

  ${({ padded }) =>
    desk(`
    padding: ${padded ? spacing(2) : 0};
    border: ${padded ? `1px solid ${colors.antiFlashWhite}` : "none"};
  `)};
`;

const Experience = styled.div`
  padding: ${spacing(2)} 0;
`;
const ExperienceTitle = styled.h4`
  color: ${colors.blackRock};
  ${semiBold};
  font-size: ${sizing(16)};
  margin: 0;
`;
const ExperienceCompany = styled.span`
  display: block;
  ${regular};
  font-size: ${sizing(16)};
  color: ${colors.blackRock};
`;
const ExperienceDates = styled.time`
  color: ${colors.frenchGray};
  ${semiBold};
  font-size: ${sizing(14)};
  display: block;
  line-height: ${sizing(24)};
`;

export const StyledProgressBar = styled(ProgressBar)`
  width: 111px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${spacing(1)};
  margin-top: ${spacing(2)};
`;

export const Name = styled.h1`
  margin: 0;
  font-size: ${sizing(32)};
  line-height: ${sizing(36)};
  letter-spacing: -0.76px;
  text-align: center;
  ${regular};
  padding: ${spacing(2)} 0;

  ${desk(`
    font-size: ${sizing(48)};
    line-height: ${sizing(54)};
  `)};
`;

export const Info = styled.div`
  width: 100%;
  padding: ${spacing(2)} 0;
  position: sticky;
  top: 0;
  z-index: 10;
  text-align: center;
`;

export class Consultant extends Component {
  render() {
    const consultantId = this.props.match.params.id;

    if (consultantId === "create-consultant") {
      return null;
    }

    return (
      <Query
        variables={{ id: consultantId }}
        query={query}
        fetchPolicy={"cache-and-network"}
      >
        {({ loading, error, data }) => (
          <Fragment>
            {loading && !data.consultant && <Loader />}
            {data.consultant && (
              <PageLayout
                isDual
                leftAligned
                backLink="/consultants"
                header={
                  <PageHeader>
                    <AbsoluteLeft>
                      <ArrowMessage to="/consultants" left>
                        Back
                      </ArrowMessage>
                    </AbsoluteLeft>
                    <StyledProgressBar
                      percentage={100}
                      image={data.consultant.avatar}
                    />
                    <PageTitle sticky>
                      {data.consultant.first_names} {data.consultant.last_name}
                    </PageTitle>
                    <PageSubTitle
                      style={{
                        textAlign: "center",
                        padding: ` ${spacing(1)} 0`
                      }}
                    >
                      {data.consultant.data.job_title}
                    </PageSubTitle>
                  </PageHeader>
                }
              >
                <Body>
                  <Content>
                    <Section>
                      <SectionHeader>
                        <PageSubTitle>Details</PageSubTitle>
                      </SectionHeader>

                      <NaturalBlock>
                        <NaturalIntro>My daily rate is:</NaturalIntro>
                        <NaturalData>
                          £{data.consultant.data.day_rate}
                        </NaturalData>
                      </NaturalBlock>

                      <NaturalBlock>
                        <NaturalIntro>My Experience level is:</NaturalIntro>
                        <NaturalData>
                          {
                            experienceLevels[
                              data.consultant.data.experience_level
                            ]
                          }
                        </NaturalData>
                      </NaturalBlock>

                      <NaturalBlock>
                        <NaturalIntro>My location is:</NaturalIntro>
                        <NaturalData>
                          {locations[data.consultant.data.location]}
                        </NaturalData>
                      </NaturalBlock>

                      <NaturalBlock>
                        <NaturalIntro>I am:</NaturalIntro>
                        <NaturalData>
                          {data.consultant.data.willing_to_travel
                            ? "Willing to travel"
                            : "not willing to travel"}
                        </NaturalData>
                      </NaturalBlock>
                      <NaturalBlock>
                        <NaturalIntro>I prefer to be:</NaturalIntro>
                        <NaturalData>
                          {data.consultant.data.part_time
                            ? "part time"
                            : "full time"}
                        </NaturalData>
                      </NaturalBlock>
                    </Section>

                    {data.consultant.data.tags.length ? (
                      <Section>
                        <SectionHeader>
                          <PageSubTitle>Tags</PageSubTitle>
                        </SectionHeader>
                        {data.consultant.data.tags.map(tag => (
                          <CancellableTag key={tag.id}>
                            {tag.value}
                          </CancellableTag>
                        ))}
                      </Section>
                    ) : null}
                    {data.consultant.data.summary && (
                      <Section>
                        <SectionHeader>
                          <PageSubTitle>About Me</PageSubTitle>
                        </SectionHeader>
                        <SectionContent>
                          <TextEditor
                            defaultValue={data.consultant.data.summary}
                            readOnly
                            key={data.consultant.id}
                          />
                        </SectionContent>
                      </Section>
                    )}
                    {data.consultant.data.experience.length ? (
                      <Section>
                        <SectionHeader>
                          <PageSubTitle>My Experience</PageSubTitle>
                        </SectionHeader>
                        <SectionContent>
                          {data.consultant.data.experience.map(exp => (
                            <Experience key={exp.id}>
                              <ExperienceTitle>{exp.title}</ExperienceTitle>
                              <ExperienceCompany>
                                {exp.company}
                              </ExperienceCompany>
                              <ExperienceDates>{`${format(
                                exp.start_date,
                                "MMMM YYYY"
                              )} - ${
                                exp.end_date !== null
                                  ? format(exp.end_date, "MMMM YYYY")
                                  : "Current"
                              }`}</ExperienceDates>

                              <TextEditor
                                defaultValue={exp.description}
                                readOnly
                                key={exp.id}
                              />
                            </Experience>
                          ))}
                        </SectionContent>
                      </Section>
                    ) : null}
                  </Content>
                </Body>
                <Flag name="admin">
                  <Button
                    primary
                    onClick={() =>
                      ConfirmationDialog({
                        message: "Are you sure you wish to reset this password",
                        primaryText: "Reset",
                        primaryAction: () =>
                          authClient.changePassword(
                            {
                              connection: "Username-Password-Authentication",
                              email: data.consultant.email
                            },
                            err => {
                              if (err) {
                                alert("there was an error please try again");
                              } else {
                                alert("password reset sent");
                              }
                            }
                          ),
                        secondaryText: "Cancel",
                        secondaryAction: () => null
                      })
                    }
                  >
                    Reset Password
                  </Button>
                  <Button
                    primary
                    onClick={() =>
                      this.props.history.push(
                        `/consultants/${consultantId}/contact`
                      )
                    }
                  >
                    Contact
                  </Button>
                </Flag>
                <Flag name="client">
                  <Footer>
                    <Button
                      onClick={() =>
                        this.props.history.push(
                          `/consultants/${consultantId}/propose`
                        )
                      }
                      primary
                    >
                      Propose Role
                    </Button>
                  </Footer>
                </Flag>
              </PageLayout>
            )}
          </Fragment>
        )}
      </Query>
    );
  }
}

const query = gql`
  query consultant($id: ID!) {
    consultant(where: { id: $id }) {
      id
      avatar
      first_names
      last_name
      email
      data {
        id
        job_title
        willing_to_travel
        location
        part_time
        experience_level
        summary
        day_rate
        location
        tags {
          id
          value
        }
        experience(orderBy: start_date_DESC) {
          id
          title
          company
          description
          start_date
          end_date
        }
      }
    }
  }
`;
