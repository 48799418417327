import styled, { css } from "styled-components";
import { tablet } from "styles/media";
import colors from "styles/colors";
import { spacing } from "styles/sizing";
import { Button } from "components/Button";
import { Link } from "react-router-dom";
import { semiBold } from "styles/typography";

export const Wrapper = styled.div`
  padding: 0;
  height: 100%;
  width: 100%;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;

  ${props =>
    props.isDual &&
    tablet(css`
      max-width: 600px;
      flex: 1;
      margin: ${props.leftAligned ? "0 auto 0 0" : "0 0 0 auto"};
    `)}
`;

export const Body = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  flex: 1;
  overflow: scroll;
  padding: ${spacing(2)};
  background: ${colors.white};

  ${props =>
    props.isDual &&
    tablet(css`
      max-width: 600px;
      margin: ${props.leftAligned ? "0 auto 0 0" : "0 0 0 auto"};
    `)}
`;

export const FloatingButton = styled(Button)`
  position: absolute;
  top: ${spacing(1)};
  right: ${spacing(1)};
  width: auto;
  margin: 0;

  ${tablet(css`
    top: ${spacing(2)};
    right: ${spacing(2)};
  `)}
`;

export const Shadow = styled.span`
  display: block;
  width: 100%;
  margin: 0 ${spacing(1)} 0;
  border-bottom: 2px solid ${colors.snuff};
  position: sticky;
  top: 58px;
  z-index: 20;

  ${tablet(css`
    top: 74px;
    width: calc(100% - ${spacing(2)});
  `)}
`;

export const BackButton = styled(Link)`
  position: absolute;
  top: ${spacing(6)};
  left: ${spacing(2)};
  transform: translateY(-50%);
  cursor: pointer;
  color: ${colors.blackRock};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-decoration: none;
  ${semiBold};

  svg {
    margin-right: ${spacing()};
  }

  ${tablet(css`
    display: none;
  `)};
`;

export const Footer = styled.footer`
  position: sticky;
  bottom: 0;
  padding: 0 ${spacing(2)};
  background: ${colors.white};
`;
