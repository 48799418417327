import React, { Component, Fragment } from "react";
import { Modal } from "components/Modal";
import { PoseGroup } from "react-pose";
import { Button } from "components/Button";
import { ApplicationSent } from "components/illustrations";
import {
  SubmittedTitle,
  ImageContainer,
  AnimatedWrapper,
  Header,
  Wrapper,
  Title,
  Body
} from "./styles";
import { Form, Formik } from "formik";
import { TextEditor } from "components/TextEditor";
import { FieldWrapper, FieldLabel, Field } from "components/FormField";
import gql from "graphql-tag";
import { Mutation, Query } from "react-apollo";
import { ImageUploader } from "components/ImageUploader";
import * as Yup from "yup";
import { Loader } from "components/Loader";

export class OrganisationEdit extends Component {
  state = {
    isFormSubmitted: false
  };
  render() {
    const {
      match: {
        params: { id }
      },
      history: { push }
    } = this.props;

    const { isFormSubmitted } = this.state;
    return (
      <Modal isOpen onRequestClose={() => push(`/teams/${id}`)}>
        <PoseGroup>
          {!isFormSubmitted ? (
            <AnimatedWrapper key="form">
              <Wrapper>
                <Header>
                  <Title>Edit Team</Title>
                </Header>
                <Body>
                  <Query variables={{ id: id }} query={query}>
                    {({ data, loading }) => (
                      <Fragment>
                        {loading && !data && <Loader />}
                        {data && data.organisation && (
                          <Mutation mutation={mutation}>
                            {mutation => (
                              <Formik
                                initialValues={{
                                  name: data.organisation.name,
                                  logo: data.organisation.logo,
                                  description: data.organisation.description,
                                  website: data.organisation.website
                                }}
                                validationSchema={Yup.object().shape({
                                  name: Yup.string().required(
                                    "Please enter a name"
                                  ),
                                  description: Yup.string().required(
                                    "Please enter a description"
                                  ),
                                  website: Yup.string().url(
                                    "Please enter a vlid URL"
                                  )
                                })}
                                onSubmit={values => {
                                  mutation({
                                    variables: {
                                      id: id,
                                      logo: values.logo,
                                      name: values.name.toLowerCase(),
                                      description: values.description,
                                      website: values.website
                                    }
                                  });
                                  this.setState({ isFormSubmitted: true });
                                }}
                              >
                                {({ setFieldValue, values }) => (
                                  <Form>
                                    <ImageUploader
                                      image={values.logo}
                                      onChange={file =>
                                        setFieldValue("logo", file)
                                      }
                                    />
                                    <FieldWrapper
                                      style={{ flexDirection: "column" }}
                                    >
                                      <FieldLabel name="name">Name</FieldLabel>
                                      <Field name="name" />
                                    </FieldWrapper>
                                    <FieldWrapper
                                      style={{ flexDirection: "column" }}
                                    >
                                      <FieldLabel name="website">
                                        Website
                                      </FieldLabel>
                                      <Field name="website" />
                                    </FieldWrapper>
                                    <FieldWrapper
                                      style={{ flexDirection: "column" }}
                                    >
                                      <FieldLabel name="description">
                                        Description
                                      </FieldLabel>
                                      <TextEditor
                                        defaultValue={values["description"]}
                                        onChange={value =>
                                          setFieldValue("description", value)
                                        }
                                      />
                                    </FieldWrapper>
                                    <Button primary type="submit">
                                      Save
                                    </Button>
                                    <Button
                                      secondary
                                      type="button"
                                      onClick={() => push(`/teams/${id}`)}
                                    >
                                      Cancel
                                    </Button>
                                  </Form>
                                )}
                              </Formik>
                            )}
                          </Mutation>
                        )}
                      </Fragment>
                    )}
                  </Query>
                </Body>
              </Wrapper>
            </AnimatedWrapper>
          ) : (
            <AnimatedWrapper key="result">
              <SubmittedTitle>The Team was succesfully updated</SubmittedTitle>
              <ImageContainer>
                <ApplicationSent dark />
              </ImageContainer>
              <Button
                primary
                type="button"
                onClick={() => push(`/teams/${id}`)}
              >
                Close
              </Button>
            </AnimatedWrapper>
          )}
        </PoseGroup>
      </Modal>
    );
  }
}

const mutation = gql`
  mutation updateOrganisation(
    $name: String!
    $description: String!
    $logo: Upload!
    $id: ID!
  ) {
    updateOrganisation(
      where: { id: $id }
      name: $name
      logo: $logo
      description: $description
    ) {
      id
      name
      description
      logo
    }
  }
`;

const query = gql`
  query($id: ID!) {
    organisation(where: { id: $id }) {
      id
      name
      description
      website
      logo
    }
  }
`;
