import posed from "react-pose";

export const AnimatedWrapper = posed.div({
  enter: {
    opacity: 1,
    scale: 1,
    duration: 400
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    duration: 400
  }
});
