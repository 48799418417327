import React, { Component } from "react";
import { string, number, object, oneOfType } from "prop-types";
import styled from "styled-components";
import colors from "styles/colors";
import posed from "react-pose";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Image = styled.div`
  position: absolute;
  top: 48%;
  left: 50.5%;
  transform: translateX(-50%) translateY(-50%);
  width: 81%;
  height: 77%;
  border-radius: 50%;
  background-image: url(${props => props.url});
  background-position: center;
  background-size: cover;
`;

const Path = posed.path({
  fill: {
    pathLength: 100,
    pathOffset: ({ percentage }) => 100 - percentage,
    duration: 400
  }
});

export class ProgressBar extends Component {
  state = {
    image: null
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.image || typeof nextProps.image === "string") {
      return;
    }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, image: reader.result });
      };

      reader.readAsDataURL(nextProps.image);
    });
  }

  render() {
    const { percentage, image, ...props } = this.props;
    return (
      <Wrapper {...props}>
        <svg width="100%" viewBox="0 0 170 172">
          <g fill="none" fillRule="nonzero">
            <path
              stroke={colors.brightGray}
              strokeWidth="8"
              strokeLinecap="round"
              d="M165.182745 68.98702318c-9.21182316-43.05149245-51.25610394-70.970485-94.66377853-62.5328878-43.9171125 8.53662191-72.59929065 51.0555673-64.06343793 94.96872266 8.53585275 43.91315556 51.05738258 72.59150248 94.97449508 64.05488058 32.5109591-6.31949028 56.67295387-31.26178463 63.56779132-61.60640294"
            />
            <Path
              percentage={percentage}
              poseKey={percentage}
              pose="fill"
              stroke={colors.carribeanGreen}
              strokeWidth="8"
              strokeLinecap="round"
              d="M165.182745 68.98702318c-9.21182316-43.05149245-51.25610394-70.970485-94.66377853-62.5328878-43.9171125 8.53662191-72.59929065 51.0555673-64.06343793 94.96872266 8.53585275 43.91315556 51.05738258 72.59150248 94.97449508 64.05488058 32.5109591-6.31949028 56.67295387-31.26178463 63.56779132-61.60640294"
            />
          </g>
        </svg>
        <Image url={this.state.image || image} />
      </Wrapper>
    );
  }
}

ProgressBar.propTypes = {
  percentage: number,
  image: oneOfType([string, object])
};
