import React from "react";
import { Icon } from "./Icon";
import colors from "styles/colors";

export const PersonIcon = props => (
  <Icon {...props} viewBox="0 0 20 23">
    <path
      d="M16 22h2.802l-1.338-7.358A2 2 0 0 0 15.496 13H4.504a2 2 0 0 0-1.968 1.642L1.198 22H4v-4h1v4h10v-4h1v4zM4.504 12h10.992a3 3 0 0 1 2.952 2.463l1.338 7.358A1 1 0 0 1 18.802 23H1.198a1 1 0 0 1-.984-1.179l1.338-7.358A3 3 0 0 1 4.504 12zM10 9a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 1a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"
      fill={colors.liquorice}
      fillRule="nonzero"
    />
  </Icon>
);
