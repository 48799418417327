import React, { Component } from "react";
import { Modal } from "components/Modal";
import { PoseGroup } from "react-pose";
import { Button } from "components/Button";
import { ApplicationSent } from "components/illustrations";
import {
  SubmittedTitle,
  ImageContainer,
  AnimatedWrapper,
  Header,
  Wrapper,
  Title,
  Body
} from "./styles";
import { Form, Formik } from "formik";
import { TextEditor } from "components/TextEditor";
import { FieldLabel, FieldWrapper } from "components/FormField";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";

export class OrganisationJoin extends Component {
  state = {
    isFormSubmitted: false
  };
  render() {
    const {
      User: { id: UserID },
      match: {
        params: { id }
      },
      history: { push }
    } = this.props;

    const { isFormSubmitted } = this.state;
    return (
      <Modal isOpen onRequestClose={() => push(`/teams/${id}`)}>
        <PoseGroup>
          {!isFormSubmitted ? (
            <AnimatedWrapper key="form">
              <Wrapper>
                <Header>
                  <Title>Join Team</Title>
                </Header>
                <Body>
                  <Mutation mutation={mutation}>
                    {mutation => (
                      <Formik
                        initialValues={{
                          message: ""
                        }}
                        onSubmit={values => {
                          mutation({
                            variables: {
                              user: UserID,
                              organisation: id,
                              message: values.message,
                              time: new Date().toISOString()
                            }
                          });
                          this.setState({ isFormSubmitted: true });
                        }}
                      >
                        {({ setFieldValue, values }) => (
                          <Form>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <FieldLabel>
                                Enter a message explaining why you want to join.
                              </FieldLabel>
                              <TextEditor
                                defaultValue={values["message"]}
                                onChange={value =>
                                  setFieldValue("message", value)
                                }
                              />
                            </FieldWrapper>
                            <Button primary type="submit">
                              Apply to join
                            </Button>
                            <Button
                              secondary
                              type="button"
                              onClick={() => push(`/teams/${id}`)}
                            >
                              Cancel
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </Mutation>
                </Body>
              </Wrapper>
            </AnimatedWrapper>
          ) : (
            <AnimatedWrapper key="result">
              <SubmittedTitle>Your application was submitted</SubmittedTitle>
              <ImageContainer>
                <ApplicationSent dark />
              </ImageContainer>
              <Button
                primary
                type="button"
                onClick={() => push(`/teams/${id}`)}
              >
                Close
              </Button>
            </AnimatedWrapper>
          )}
        </PoseGroup>
      </Modal>
    );
  }
}

const mutation = gql`
  mutation(
    $user: ID!
    $organisation: ID!
    $message: String!
    $time: DateTime!
  ) {
    createConversation(
      data: {
        sender: { connect: { id: $user } }
        lastMessageAt: $time
        organisation: { connect: { id: $organisation } }
        type: APPLICATION
        messages: {
          create: { user: { connect: { id: $user } }, value: $message }
        }
      }
    ) {
      id
    }
  }
`;
