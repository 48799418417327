import React, { Fragment, Component } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import {
  StyledProgressBar,
  StyledLoader,
  Title,
  Tab,
  Tabs,
  Results,
  IllustrationContainer
} from "./styles";
import { distanceInWordsToNow } from "date-fns";
import { Button } from "components/Button";
import { stringify, parse } from "querystring";
import { Loader } from "components/Loader";
import { NotFound } from "components/illustrations";
import { PageLayout, PageHeader } from "components/PageLayout";
import { PageSubTitle, PageTitle } from "components/PageHeader";
import { isTabletAndUp } from "styles/media";
import Helmet from "react-helmet";
import {
  SummaryTile,
  SummaryTitle,
  SummaryImage,
  SummarySubtitle,
  SummaryDetail,
  SummaryDate
} from "components/Summary";

export class ClientDashboard extends Component {
  state = {
    allEntitiesRecieved: false
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      this.setState({
        allEntitiesRecieved: false
      });
    }
    return true;
  }

  render() {
    const { history, location, User } = this.props;
    const { allEntitiesRecieved } = this.state;
    const { tab } = parse(location.search.replace("?", ""));

    return (
      <Query
        query={clientRolesQuery}
        variables={{
          skip: 0,
          filter: {
            client: {
              id: User.id
            },
            closed: tab === "closed"
          }
        }}
        fetchPolicy={"cache-and-network"}
        onCompleted={data => {
          if (!allEntitiesRecieved && data.roles.length < 20) {
            this.setState({
              allEntitiesRecieved: true
            });
          }
        }}
      >
        {({ loading, error, data: rolesData, fetchMore }) => {
          if (
            rolesData &&
            rolesData.roles &&
            rolesData.roles[0] &&
            isTabletAndUp() &&
            this.props.match.isExact
          ) {
            this.props.history.push({
              pathname: `/dashboard/${rolesData.roles[0].id}`,
              search: this.props.location.search
            });
          }

          return (
            <Query query={query} fetchPolicy={"cache-and-network"}>
              {({ loading, data }) => {
                if (!data.User) {
                  return null;
                }
                const {
                  avatar,
                  first_names,
                  last_name,
                  data: { job_title }
                } = data.User;

                return !loading ? (
                  <PageLayout
                    isDual
                    rightAligned
                    header={
                      <PageHeader>
                        <Helmet>
                          <title>Dashboard - Core Consultants</title>
                        </Helmet>
                        <StyledProgressBar percentage={100} image={avatar} />
                        <PageTitle sticky>
                          {first_names} {last_name}
                        </PageTitle>
                        <PageSubTitle center sticky hasShadow>
                          {job_title}
                        </PageSubTitle>
                      </PageHeader>
                    }
                    onScrollBottom={() => {
                      if (!allEntitiesRecieved) {
                        fetchMore({
                          variables: {
                            skip: rolesData.roles.length,
                            filter: {
                              client: {
                                id: User.id
                              },
                              closed: tab === "closed"
                            }
                          },
                          updateQuery: (prev, { fetchMoreResult }) => {
                            if (!fetchMoreResult || loading) return prev;

                            if (fetchMoreResult.roles.length < 20) {
                              this.setState({
                                allEntitiesRecieved: true
                              });
                            }

                            return Object.assign({}, prev, {
                              roles: [...prev.roles, ...fetchMoreResult.roles]
                            });
                          }
                        });
                      }
                    }}
                  >
                    <Button
                      onClick={() => history.push("/roles/create")}
                      primary
                    >
                      Create new Role
                    </Button>
                    <Query
                      query={activeRolesCount}
                      variables={{ User: User.id }}
                    >
                      {({ data: activeRoles, loading }) => (
                        <Query
                          query={closedRolesCount}
                          variables={{ User: User.id }}
                          fetchPolicy={"cache-and-network"}
                        >
                          {({ data: closedRoles, loading }) => {
                            return (
                              <Tabs>
                                <Tab
                                  activeClassName="active"
                                  isActive={() => !tab}
                                  to={{
                                    search: stringify({
                                      tab: undefined
                                    })
                                  }}
                                >
                                  {`Open (${activeRoles.rolesConnection &&
                                    activeRoles.rolesConnection.aggregate &&
                                    activeRoles.rolesConnection.aggregate
                                      .count})`}
                                </Tab>
                                <Tab
                                  activeClassName="active"
                                  isActive={() => tab === "closed"}
                                  to={{
                                    search: stringify({
                                      tab: "closed"
                                    })
                                  }}
                                >
                                  {`Closed (${closedRoles.rolesConnection &&
                                    closedRoles.rolesConnection.aggregate &&
                                    closedRoles.rolesConnection.aggregate
                                      .count})`}
                                </Tab>
                              </Tabs>
                            );
                          }}
                        </Query>
                      )}
                    </Query>
                    <Results>
                      {loading && !rolesData.roles && <Loader />}
                      {rolesData.roles && !rolesData.roles.length && (
                        <Fragment>
                          <Title>No results here</Title>
                          <IllustrationContainer>
                            <NotFound />
                          </IllustrationContainer>
                        </Fragment>
                      )}

                      {rolesData.roles && rolesData.roles.length && (
                        <Fragment>
                          {rolesData.roles.map((role, index) => (
                            <SummaryTile
                              key={role.id}
                              to={{
                                pathname: `/dashboard/${role.id}`,
                                search: this.props.location.search
                              }}
                            >
                              <SummaryImage
                                url={
                                  role.organisation
                                    ? role.organisation.logo
                                    : avatar
                                }
                              />
                              <SummaryDetail>
                                <SummaryTitle>{role.title}</SummaryTitle>
                                <Query
                                  query={applicationsCount}
                                  variables={{ id: role.id }}
                                >
                                  {({ data }) => {
                                    if (
                                      data.applicationsConnection &&
                                      data.applicationsConnection.aggregate
                                    ) {
                                      return (
                                        <SummarySubtitle>
                                          {
                                            data.applicationsConnection
                                              .aggregate.count
                                          }{" "}
                                          Applicants
                                        </SummarySubtitle>
                                      );
                                    } else {
                                      return (
                                        <SummarySubtitle>
                                          No applicants yet
                                        </SummarySubtitle>
                                      );
                                    }
                                  }}
                                </Query>
                                <SummaryDate>{`Posted: ${distanceInWordsToNow(
                                  role.createdAt
                                )} ago`}</SummaryDate>
                              </SummaryDetail>
                            </SummaryTile>
                          ))}
                          {!allEntitiesRecieved && (
                            <div style={{ height: "50px" }}>
                              <Loader width="40px" />
                            </div>
                          )}
                        </Fragment>
                      )}
                    </Results>
                  </PageLayout>
                ) : (
                  <StyledLoader />
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

const query = gql`
  query {
    User {
      id
      first_names
      last_name
      avatar
      data {
        id
        job_title
      }
    }
  }
`;

const clientRolesQuery = gql`
  query clientRolesQuery($filter: RoleWhereInput, $skip: Int) {
    roles(skip: $skip, first: 20, where: $filter) {
      id
      createdAt
      title
      organisation {
        id
        logo
      }
    }
  }
`;

const closedRolesCount = gql`
  query activeRolesCount($User: ID!) {
    rolesConnection(where: { client: { id: $User }, closed: true }) {
      aggregate {
        count
      }
    }
  }
`;

const activeRolesCount = gql`
  query activeRolesCount($User: ID!) {
    rolesConnection(where: { client: { id: $User }, closed: false }) {
      aggregate {
        count
      }
    }
  }
`;

const applicationsCount = gql`
  query applicationsCount($id: ID!) {
    conversationsConnection(where: { role: { id: $id } }) {
      aggregate {
        count
      }
    }
  }
`;
