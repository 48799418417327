import React from "react";
import { Search } from "components/Search";
import { FilterButton, Status, Filter } from "./style";
import { FilterIcon } from "components/icons";
import { Button } from "components/Button";

export const FilterArea = ({ defaultValue, onChange, onClick }) => (
  <Filter>
    <Search name="search" defaultValue={defaultValue} onChange={onChange} />
    <FilterButton onClick={onClick}>
      <FilterIcon />
      <span>Filter</span>
    </FilterButton>
  </Filter>
);

export const FilterStatus = ({ onClick, count }) => (
  <Status>
    <span>{`${count} Active Filters`}</span>
    <Button onClick={onClick} secondary>
      Clear Filters
    </Button>
  </Status>
);
