import React, { Component, Fragment } from "react";
import { PageLayout, PageHeader } from "components/PageLayout";
import { Query } from "react-apollo";
import { Loader } from "components/Loader";
import gql from "graphql-tag";
import { locations } from "constants/locations";
import { Section, Label, Value, Spacer } from "./style";
import { StyledProgressBar } from "containers/Profile/styles";
import { Button } from "components/Button";
import { authClient } from "auth/authClient";
import { PageTitle, PageSubTitle } from "components/PageHeader";
import { ConfirmationDialog } from "components/ConfirmationDialog";

export class Client extends Component {
  render() {
    const Id = this.props.match.params.id;
    return (
      <Query variables={{ id: Id }} query={getClient}>
        {({ data, loading }) => (
          <Fragment>
            {loading && <Loader />}
            {data && data.client && (
              <PageLayout
                isDual
                leftAligned
                header={
                  <PageHeader>
                    <StyledProgressBar
                      image={data.client.avatar}
                      profile_completeness={100}
                    />
                    <PageTitle sticky>{`${data.client.first_names} ${
                      data.client.last_name
                    }`}</PageTitle>
                    <PageSubTitle center sticky>
                      {data.client.data.job_title}
                    </PageSubTitle>
                  </PageHeader>
                }
              >
                <Button primary>View Roles</Button>
                <Button
                  primary
                  onClick={() =>
                    ConfirmationDialog({
                      message: "Are you sure you wish to reset this password",
                      primaryText: "Reset",
                      primaryAction: () =>
                        authClient.changePassword(
                          {
                            connection: "Username-Password-Authentication",
                            email: data.client.email
                          },
                          err => {
                            if (err) {
                              alert("there was an error please try again");
                            } else {
                              alert("password reset sent");
                            }
                          }
                        ),
                      secondaryText: "Cancel",
                      secondaryAction: () => null
                    })
                  }
                >
                  Reset Password
                </Button>
                <PageSubTitle>Details</PageSubTitle>
                <Section>
                  <Spacer>
                    <Label>Location:</Label>
                    <Value>{locations[data.client.data.location]}</Value>
                  </Spacer>
                  <Spacer>
                    <Label>email:</Label>
                    <Value>
                      <a href={`mailto:${data.client.email}`}>
                        {data.client.email}
                      </a>
                    </Value>
                  </Spacer>
                  <Spacer>
                    <Label>Phone:</Label>
                    <Value>
                      <a href={`tel:${data.client.data.phone}`}>
                        {data.client.data.phone}
                      </a>
                    </Value>
                  </Spacer>
                </Section>
                <PageSubTitle>Orginisations</PageSubTitle>
                <Section />
              </PageLayout>
            )}
          </Fragment>
        )}
      </Query>
    );
  }
}

const getClient = gql`
  query($id: ID!) {
    client(where: { id: $id }) {
      first_names
      last_name
      email
      avatar
      data {
        location
        phone
        job_title
      }
      organisationOwnership {
        id
        logo
        name
      }
      organisationManager {
        id
        logo
        name
      }
      organisationMember {
        id
        logo
        name
      }
    }
  }
`;
