import React from "react";
import { Icon } from "./Icon";

export const ChatIcon = props => (
  <Icon {...props} viewBox="0 0 20 19">
    <path
      fill="currentcolor"
      fillRule="nonzero"
      d="M5.475 17.01L7.485 15H19V1H1v14h2.464l2.01 2.01zM7.899 16l-2.424 2.425L3.05 16H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H7.9zM5 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm5 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm5 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
    />
  </Icon>
);
