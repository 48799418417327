import React from "react";
import styled from "styled-components";
import { spacing, sizing } from "styles/sizing";
import { semiBold } from "styles/typography";
import colors from "styles/colors";
import { tablet } from "styles/media";

export const Tag = styled.span`
  display: inline-block;
  margin-right: ${spacing()};
  margin-top: ${spacing()};
  padding: ${spacing(0.75)};
  font-size: ${sizing(12)};
  ${semiBold};
  text-transform: uppercase;
  border-radius: 2px;
  letter-spacing: 0.4px;
  padding-bottom: 2px;

  ${tablet(`
    font-size: ${sizing(14)};
  `)}

  color: ${props => (props.dark ? colors.white : colors.blackRock)};
  background-color: ${props =>
    props.dark ? colors.liquorice : colors.whiteLilac};
`;

export const CancelTag = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: ${spacing()};
  margin-top: ${spacing()};
  padding: ${spacing(1)};
  font-size: ${sizing(12)};
  ${semiBold};
  text-transform: uppercase;
  border-radius: 2px;
  letter-spacing: 0.4px;
  background: ${colors.white};
  border: 1px solid ${colors.palatinateBlue};
  color: ${colors.blackRock};

  button {
    font-size: ${sizing(18)};
    background: transparent;
    border: none;
    outline: none;
  }
`;

export const CancellableTag = ({ children, onClick }) => (
  <CancelTag>
    {children}
    {onClick && <button onClick={onClick}>&times;</button>}
  </CancelTag>
);
