import React, { Component, Fragment } from "react";
import { Modal } from "components/Modal";
import { Title, Header, Body } from "./style";
import { Form } from "components/Form";
import * as Yup from "yup";
import { Field, FieldWrapper, FieldError } from "components/FormField";
import { Label } from "components/FormField/styles";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { TextEditor } from "components/TextEditor";
import { UserInput } from "components/UserInput";
import { ImageUploader } from "components/ImageUploader";
import { Flag } from "flag";
import {
  SubmittedTitle,
  AnimatedWrapper
} from "containers/OrganisationEdit/styles";
import { ImageContainer } from "containers/OrganisationDelete/styles";
import { NotFound, ApplicationSent } from "components/illustrations";
import { Button } from "components/Button";
import { PoseGroup } from "react-pose";
import { Loader } from "components/Loader";
import { ConfirmationDialog } from "components/ConfirmationDialog";

export class CreateOrganisation extends Component {
  state = {
    isFormSubmitted: false,
    error: false,
    loading: false
  };
  render() {
    const { isFormSubmitted, error } = this.state;
    return (
      <Modal
        isOpen
        onRequestClose={() =>
          ConfirmationDialog({
            message:
              "Are you sure you wish to close all your data will be lost?",
            primaryText: "Close",
            primaryAction: () => this.props.history.push("/teams"),
            secondaryText: "Cancel",
            secondaryAction: () => null
          })
        }
      >
        <PoseGroup>
          {!isFormSubmitted ? (
            <AnimatedWrapper key="form">
              <Header>
                <Title>Create Team</Title>
              </Header>
              <Body>
                <Mutation mutation={createOrganisation}>
                  {(mutation, { error, loading }) => {
                    if (error) {
                      this.setState({ error });
                    }

                    if (loading) {
                      return <Loader />;
                    }

                    return (
                      <Form
                        initialValues={{
                          name: "",
                          logo:
                            "https://s3-eu-west-1.amazonaws.com/cc-app-uploads/organisation.jpg",
                          description: "",
                          website: "http://",
                          owner: [],
                          managers: [],
                          members: []
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required("Please enter a name"),
                          description: Yup.string().required(
                            "Please enter a description"
                          ),
                          website: Yup.string().url(
                            "Please enter a valid URL including http or https"
                          ),
                          owner: Yup.array().of(
                            Yup.object().shape({
                              id: Yup.string()
                            })
                          )
                        })}
                        onReset={() => this.props.history.push("/teams")}
                        onSubmit={values => {
                          mutation({
                            variables: {
                              name: values.name.toLowerCase(),
                              logo: values.logo,
                              description: values.description,
                              website: values.website,
                              owner: {
                                id: values.owner[0] ? values.owner[0].id : null
                              },
                              managers: values.managers.map(i => ({
                                id: i.id
                              })),
                              members: values.members.map(i => ({ id: i.id }))
                            }
                          });
                          this.setState({ isFormSubmitted: true });
                        }}
                      >
                        {(errors, values, setFieldValue, touched) => (
                          <Fragment>
                            <ImageUploader
                              image={values.logo}
                              onChange={file => setFieldValue("logo", file)}
                            />
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label name="name">Name</Label>
                              <Field name="name" />
                              <FieldError name="name" />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label name="website">Website</Label>
                              <Field name="website" />
                              <FieldError name="website" />
                            </FieldWrapper>
                            <Flag name="admin">
                              <FieldWrapper style={{ flexDirection: "column" }}>
                                <Label name="owner">Owner</Label>
                                <UserInput
                                  name="owner"
                                  isSingular
                                  query={queryUsers}
                                  values={values.owner}
                                />
                              </FieldWrapper>
                              <FieldError name="owner" />
                            </Flag>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label name="managers">Managers</Label>
                              <UserInput
                                name="managers"
                                query={queryUsers}
                                values={values.managers}
                              />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label name="members">Members</Label>
                              <UserInput
                                name="members"
                                query={queryUsers}
                                values={values.members}
                              />
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <Label name="description">Description</Label>
                              <TextEditor
                                defaultValue={values["description"]}
                                onChange={value =>
                                  setFieldValue("description", value)
                                }
                              />
                            </FieldWrapper>
                          </Fragment>
                        )}
                      </Form>
                    );
                  }}
                </Mutation>
              </Body>
            </AnimatedWrapper>
          ) : (
            <AnimatedWrapper key="result">
              <SubmittedTitle>
                {this.error
                  ? "There was an error"
                  : "your organisation has been created"}
              </SubmittedTitle>
              <ImageContainer>
                {error ? <NotFound dark /> : <ApplicationSent dark />}
              </ImageContainer>
              <Button
                primary
                type="button"
                onClick={() => this.props.history.push(`/teams`)}
              >
                Close
              </Button>
            </AnimatedWrapper>
          )}
        </PoseGroup>
      </Modal>
    );
  }
}

const queryUsers = gql`
  query users($search: String!) {
    users(
      where: {
        OR: [{ first_names_contains: $search }, { last_name_contains: $search }]
      }
    ) {
      id
      first_names
      last_name
      avatar
    }
  }
`;

const createOrganisation = gql`
  mutation createOrganisation(
    $name: String!
    $description: String!
    $website: String
    $logo: Upload!
    $owner: UserWhereUniqueInput!
    $managers: [UserWhereUniqueInput!]
    $members: [UserWhereUniqueInput!]
  ) {
    createOrganisation(
      name: $name
      logo: $logo
      description: $description
      website: $website
      owner: $owner
      managers: $managers
      members: $members
    ) {
      id
      name
    }
  }
`;
