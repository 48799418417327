import React, { useState } from "react";
import { Logo } from "components/Logo";
import { PageContainer } from "components/PageContainer";
import { Button } from "components/Button";
import styled from "styled-components";
import { ArrowMessage } from "components/ArrowMessage";
import auth0Client from "auth/authClient";
import { PoseGroup } from "react-pose";
import { ApplicationSent } from "components/illustrations";
import { Form } from "containers/getStarted/Form";
import { Text, Link } from "components/Text";
import { CardHeading, Card, CardWrapper } from "components/Card";
import { AnimatedWrapper } from "components/Animations";

const AbsoluteLeft = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  transform: translateY(-50%);
`;

const formConfig = {
  fields: [
    {
      name: "email",
      type: "email",
      label: "Email",
      options: {
        placeholder: "Email",
        autoComplete: "email",
        className: "block"
      },
      required: true
    }
  ],
  submitText: "Login"
};

export const ForgotPassword = ({ history }) => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmission = values => {
    auth0Client.changePassword(
      {
        ...values,
        connection: "Username-Password-Authentication"
      },
      err => {
        setSubmitted(true);
        if (err) {
          console.log(err.error_description);
        }
      }
    );
  };

  return (
    <PageContainer>
      <CardWrapper>
        <Logo width="60px" isVertical />
        <AbsoluteLeft>
          <ArrowMessage to="/login" left>
            Back
          </ArrowMessage>
        </AbsoluteLeft>
        <PoseGroup>
          {!submitted ? (
            <AnimatedWrapper key="form">
              <CardHeading>Enter your email to reset password</CardHeading>
              <Card>
                <Form config={formConfig} onSubmit={handleSubmission} />
                <Text center>
                  Need help? contact us{" "}
                  <Link href="mailto:support@coreconsultants.io">here</Link>
                </Text>
              </Card>
            </AnimatedWrapper>
          ) : (
            <AnimatedWrapper key="result">
              <CardHeading>
                An email has been to sent to reset your password
              </CardHeading>
              <Card>
                <ApplicationSent style={{ width: "250px" }} />
                <Button primary onClick={() => history.push("/login")}>
                  Return to Login
                </Button>
              </Card>
            </AnimatedWrapper>
          )}
        </PoseGroup>
      </CardWrapper>
    </PageContainer>
  );
};
