import styled from "styled-components";
import posed from "react-pose";
import { sizing, spacing } from "styles/sizing";
import { regular } from "styles/typography";
import { tablet } from "styles/media";
import { tabletStart } from "styles/consts";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${tabletStart}px;
  height: 100%;
`;

export const Title = styled.h1`
  padding-bottom: ${spacing(2)};
  text-align: center;
  font-size: ${sizing(20)};
  color: ${colors.white};
  ${regular};

  ${tablet(`
    font-size: ${sizing(24)};
  `)};
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: ${spacing(2)};
`;

export const Card = styled.section`
  padding: ${spacing(2)};
  width: 100%;
  background: ${colors.white};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  ${tablet(`
    padding: ${spacing(6)} ${spacing(12)};
  `)};
`;

export const Slider = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const AbsoluteLeft = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

export const Profile = styled.div`
  width: 60px;

  ${tablet(`
    width: 150px;
  `)};
`;

const AnimatedSlide = posed.div({
  enter: {
    opacity: 1,
    scale: 1,
    duration: 400
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    duration: 400
  }
});

export const Slide = styled(AnimatedSlide)`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
