import React, { Component, Fragment } from "react";
import { Modal } from "components/Modal";
import gql from "graphql-tag";
import { PageLayout, PageHeader } from "components/PageLayout";
import { Loader } from "components/Loader";
import { Query } from "react-apollo";
import { StyledProgressBar } from "containers/Consultant";
import { AbsoluteLeft } from "containers/getStarted/styles";
import { ArrowMessage } from "components/ArrowMessage";
import { PageTitle } from "components/PageTitle";
import { PageSubTitle } from "components/PageHeader";
import { spacing, sizing } from "styles/sizing";
import {
  Experience,
  ExperienceTitle,
  ExperienceCompany,
  ExperienceDates
} from "containers/Profile/styles";
import {
  NaturalBlock,
  NaturalIntro,
  NaturalData
} from "components/NaturalBlocks";
import { experienceLevels } from "constants/experienceLevels";
import { locations } from "constants/locations";
import { CancellableTag } from "components/Tag";
import { TextEditor } from "components/TextEditor";
import { format } from "date-fns";
import styled from "styled-components";
import colors from "styles/colors";
import { desk } from "styles/media";
import { Button } from "components/Button";

const Body = styled.section`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  flex: 1;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const SectionContent = styled.div`
  color: ${colors.liquorice};
  line-height: ${sizing(24)};
`;

const Content = styled.section`
  flex-grow: 0;

  ${desk(`
    flex-grow: 1;
    overflow: auto;
  `)};
`;

const Section = styled.div`
  position: relative;
  margin-bottom: ${spacing(3)};
  padding-bottom: ${spacing(3)};
  border-bottom: 1px solid ${colors.antiFlashWhite};

  ${({ padded }) =>
    desk(`
    padding: ${padded ? spacing(2) : 0};
    border: ${padded ? `1px solid ${colors.antiFlashWhite}` : "none"};
  `)};
`;

export class TeamMember extends Component {
  render() {
    const {
      history: { push }
    } = this.props;

    const consultantId = this.props.match.params.member;
    const teamId = this.props.match.params.id;

    return (
      <Modal isOpen onRequestClose={() => push(`/teams`)}>
        <Query
          variables={{ id: consultantId }}
          query={query}
          fetchPolicy={"cache-and-network"}
        >
          {({ loading, error, data }) => (
            <Fragment>
              {loading && !data.consultant && <Loader />}
              {data.consultant && (
                <PageLayout
                  backLink={`/teams/${teamId}`}
                  header={
                    <PageHeader>
                      <AbsoluteLeft>
                        <ArrowMessage to={`/teams/${teamId}`} left>
                          Back
                        </ArrowMessage>
                      </AbsoluteLeft>
                      <StyledProgressBar
                        percentage={100}
                        image={data.consultant.avatar}
                      />
                      <PageTitle sticky>
                        {data.consultant.first_names}{" "}
                        {data.consultant.last_name}
                      </PageTitle>
                      <PageSubTitle
                        style={{
                          textAlign: "center",
                          padding: ` ${spacing(1)} 0`
                        }}
                      >
                        {data.consultant.data.job_title}
                      </PageSubTitle>
                    </PageHeader>
                  }
                >
                  <Body>
                    <Content>
                      <Section>
                        <SectionHeader>
                          <PageSubTitle>Details</PageSubTitle>
                        </SectionHeader>

                        <NaturalBlock>
                          <NaturalIntro>My daily rate is:</NaturalIntro>
                          <NaturalData>
                            £{data.consultant.data.day_rate}
                          </NaturalData>
                        </NaturalBlock>

                        <NaturalBlock>
                          <NaturalIntro>My Experience level is:</NaturalIntro>
                          <NaturalData>
                            {
                              experienceLevels[
                                data.consultant.data.experience_level
                              ]
                            }
                          </NaturalData>
                        </NaturalBlock>

                        <NaturalBlock>
                          <NaturalIntro>My location is:</NaturalIntro>
                          <NaturalData>
                            {locations[data.consultant.data.location]}
                          </NaturalData>
                        </NaturalBlock>

                        <NaturalBlock>
                          <NaturalIntro>I am:</NaturalIntro>
                          <NaturalData>
                            {data.consultant.data.willing_to_travel
                              ? "Willing to travel"
                              : "not willing to travel"}
                          </NaturalData>
                        </NaturalBlock>
                        <NaturalBlock>
                          <NaturalIntro>I prefer to be:</NaturalIntro>
                          <NaturalData>
                            {data.consultant.data.part_time
                              ? "part time"
                              : "full time"}
                          </NaturalData>
                        </NaturalBlock>
                      </Section>

                      {data.consultant.data.tags.length ? (
                        <Section>
                          <SectionHeader>
                            <PageSubTitle>Tags</PageSubTitle>
                          </SectionHeader>
                          {data.consultant.data.tags.map(tag => (
                            <CancellableTag key={tag.id}>
                              {tag.value}
                            </CancellableTag>
                          ))}
                        </Section>
                      ) : null}
                      {data.consultant.data.summary && (
                        <Section>
                          <SectionHeader>
                            <PageSubTitle>About Me</PageSubTitle>
                          </SectionHeader>
                          <SectionContent>
                            <TextEditor
                              defaultValue={data.consultant.data.summary}
                              readOnly
                              key={data.consultant.id}
                            />
                          </SectionContent>
                        </Section>
                      )}
                      {data.consultant.data.experience.length ? (
                        <Section>
                          <SectionHeader>
                            <PageSubTitle>My Experience</PageSubTitle>
                          </SectionHeader>
                          <SectionContent>
                            {data.consultant.data.experience.map(exp => (
                              <Experience key={exp.id}>
                                <ExperienceTitle>{exp.title}</ExperienceTitle>
                                <ExperienceCompany>
                                  {exp.company}
                                </ExperienceCompany>
                                <ExperienceDates>{`${format(
                                  exp.start_date,
                                  "MMMM YYYY"
                                )} - ${
                                  exp.end_date !== null
                                    ? format(exp.end_date, "MMMM YYYY")
                                    : "Current"
                                }`}</ExperienceDates>

                                <TextEditor
                                  defaultValue={exp.description}
                                  readOnly
                                  key={exp.id}
                                />
                              </Experience>
                            ))}
                          </SectionContent>
                        </Section>
                      ) : null}
                      <Button primary onClick={() => push(`/teams/${teamId}`)}>
                        Close
                      </Button>
                    </Content>
                  </Body>
                </PageLayout>
              )}
            </Fragment>
          )}
        </Query>
      </Modal>
    );
  }
}

const query = gql`
  query consultant($id: ID!) {
    consultant(where: { id: $id }) {
      id
      avatar
      first_names
      last_name
      email
      data {
        id
        job_title
        willing_to_travel
        location
        part_time
        experience_level
        summary
        day_rate
        location
        tags {
          id
          value
        }
        experience(orderBy: start_date_DESC) {
          id
          title
          company
          description
          start_date
          end_date
        }
      }
    }
  }
`;
