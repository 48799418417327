import styled from "styled-components";
import { sizing, spacing } from "styles/sizing";
import { semiBold, regular } from "styles/typography";
import colors from "styles/colors";

export const Section = styled.section`
  padding: ${spacing(2)} 0;
  border-bottom: 1px solid ${colors.snuff};
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing(2)};
`;

export const Title = styled.h2`
  font-size: ${sizing(18)};
  ${semiBold};
  padding: ${spacing(1)} 0;
  margin: 0;
`;

export const Label = styled.p`
  font-size: ${sizing(16)};
  ${semiBold};
  padding-right: ${spacing(1)};
  margin: 0;
`;

export const Value = styled.p`
  font-size: ${sizing(16)};
  ${regular};
  margin: 0;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacing(2)};
`;

export const SubTitle = styled.h2`
  font-size: ${sizing(16)};
  ${regular};
  padding: ${spacing(1)} 0;
  margin: 0;
  color: ${colors.snuff};
`;

export const Spacer = styled.div`
  display: flex;
  padding: ${spacing(1)} 0;
`;
