import React from "react";
import { Icon } from "./Icon";
import colors from "styles/colors";

export const AppliedIcon = props => (
  <Icon {...props} viewBox="0 0 14 14">
    <path
      fill={colors.carribeanGreen}
      fillRule="nonzero"
      d="M7 13.5C3.41014913 13.5.5 10.5898509.5 7 .5 3.41014913 3.41014913.5 7 .5c3.5898509 0 6.5 2.91014913 6.5 6.5 0 3.5898509-2.9101491 6.5-6.5 6.5zm0-1c3.0375661 0 5.5-2.4624339 5.5-5.5 0-3.03756612-2.4624339-5.5-5.5-5.5-3.03756612 0-5.5 2.46243388-5.5 5.5 0 3.0375661 2.46243388 5.5 5.5 5.5zm1.57238151-6.80930211L9.6514454 4.64151756c.1979843-.19250141.5145356-.18805659.707037.00992779.1925015.19798438.1880566.51453567-.0099278.70703709l-1.0847702 1.0546417L5.7885513 9.6869192 3.65715178 7.67906389c-.20100062-.18934985-.21044544-.50579142-.02109559-.70679204.18934984-.20100061.50579141-.21044543.70679203-.02109559l1.44570807 1.36190922 2.78382522-2.62238759z"
    />
  </Icon>
);
