export const locations = {
  BARNSLEY: "Barnsley",
  BASILDON: "Basildon",
  BASINGSTOKE: "Basingstoke",
  BATH: "Bath",
  BEDFORD: "Bedford",
  BIRKENHEAD: "Birkenhead",
  BIRMINGHAM: "Birmingham",
  BLACKBURN: "Blackburn",
  BLACKPOOL: "Blackpool",
  BOLTON: "Bolton",
  BOURNEMOUTH: "Bournemouth",
  BRACKNELL: "Bracknell",
  BRADFORD: "Bradford",
  BRIGHTONANDHOVE: "Brighton and Hove",
  BRISTOL: "Bristol",
  BURNLEY: "Burnley",
  BURTONUPONTRENT: "Burton upon Trent",
  BURY: "Bury",
  CAMBRIDGE: "Cambridge",
  CARDIFF: "Cardiff",
  CARLISLE: "Carlisle",
  CHATHAM: "Chatham",
  CHELMSFORD: "Chelmsford",
  CHELTENHAM: "Cheltenham",
  CHESTER: "Chester",
  CHESTERFIELD: "Chesterfield",
  COLCHESTER: "Colchester",
  COVENTRY: "Coventry",
  CRAWLEY: "Crawley",
  DARLINGTON: "Darlington",
  DERBY: "Derby",
  DONCASTER: "Doncaster",
  DUDLEY: "Dudley",
  EASTBOURNE: "Eastbourne",
  EXETER: "Exeter",
  GATESHEAD: "Gateshead",
  GILLINGHAM: "Gillingham",
  GLOUCESTER: "Gloucester",
  GRIMSBY: "Grimsby",
  GUILDFORD: "Guildford",
  HALIFAX: "Halifax",
  HARLOW: "Harlow",
  HARROGATE: "Harrogate",
  HARTLEPOOL: "Hartlepool",
  HASTINGS: "Hastings",
  HEMELHEMPSTEAD: "Hemel Hempstead",
  HIGHWYCOMBE: "High Wycombe",
  HUDDERSFIELD: "Huddersfield",
  IPSWICH: "Ipswich",
  KINGSTONUPONHULL: "Kingston upon Hull",
  LEEDS: "Leeds",
  LEICESTER: "Leicester",
  LINCOLN: "Lincoln",
  LIVERPOOL: "Liverpool",
  LONDON: "London",
  LUTON: "Luton",
  MAIDSTONE: "Maidstone",
  MANCHESTER: "Manchester",
  MANSFIELD: "Mansfield",
  MIDDLESBROUGH: "Middlesbrough",
  MILTONKEYNES: "Milton Keynes",
  NEWCASTLEUPONTYNE: "Newcastle upon Tyne",
  NEWCASTLE_UNDER_LYME: "Newcastle-under-Lyme",
  NEWPORT: "Newport",
  NORTHAMPTON: "Northampton",
  NORWICH: "Norwich",
  NOTTINGHAM: "Nottingham",
  NUNEATON: "Nuneaton",
  OLDHAM: "Oldham",
  OXFORD: "Oxford",
  PETERBOROUGH: "Peterborough",
  PLYMOUTH: "Plymouth",
  POOLE: "Poole",
  PORTSMOUTH: "Portsmouth",
  PRESTON: "Preston",
  READING: "Reading",
  REDDITCH: "Redditch",
  REMOTE_INSIDEUK: "Remote - Inside UK",
  REMOTE_OUTSIDEUK: "Remote - Outside UK",
  ROCHDALE: "Rochdale",
  ROTHERHAM: "Rotherham",
  SALFORD: "Salford",
  SCUNTHORPE: "Scunthorpe",
  SHEFFIELD: "Sheffield",
  SHREWSBURY: "Shrewsbury",
  SLOUGH: "Slough",
  SOLIHULL: "Solihull",
  SOUTHAMPTON: "Southampton",
  SOUTHEND_ON_SEA: "Southend-on-Sea",
  SOUTHPORT: "Southport",
  SOUTHSHIELDS: "South Shields",
  STALBANS: "St Albans",
  STEVENAGE: "Stevenage",
  STHELENS: "St Helens",
  STOCKPORT: "Stockport",
  STOCKTON_ON_TEES: "Stockton-on-Tees",
  STOKE_ON_TRENT: "Stoke-on-Trent",
  SUNDERLAND: "Sunderland",
  SUTTONCOLDFIELD: "Sutton Coldfield",
  SWANSEA: "Swansea",
  SWINDON: "Swindon",
  TELFORD: "Telford",
  WAKEFIELD: "Wakefield",
  WALSALL: "Walsall",
  WARRINGTON: "Warrington",
  WATFORD: "Watford",
  WESTBROMWICH: "West Bromwich",
  WESTON_SUPER_MARE: "Weston-Super-Mare",
  WIGAN: "Wigan",
  WOKING: "Woking",
  WOLVERHAMPTON: "Wolverhampton",
  WORCESTER: "Worcester",
  WORTHING: "Worthing",
  YORK: "York"
};
