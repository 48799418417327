import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

import colors from "styles/colors";
import { tablet, desk } from "styles/media";
import { spacing, sizing } from "styles/sizing";
import { semiBold } from "styles/typography";

export const Wrapper = styled.header`
  display: flex;
  width: 100%;
  height: ${spacing(7)};
  background: ${colors.blackRock};

  ${tablet(css`
    height: ${spacing(10)};
  `)};
`;

export const LogoContainer = styled.div`
  display: none;
  flex: 1;

  ${desk(css`
    display: block;
    padding: ${spacing(3)};
  `)};
`;

export const Navigation = styled.nav`
  flex: 1;
  height: 100%;

  ${desk(css`
    flex: none;
    padding: ${spacing(3)};
  `)};
`;

export const NavigationContainer = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  list-style: none;
`;

export const MenuItemContainer = styled.li`
  display: flex;
  flex: 1;
  align-items: center;

  ${tablet(css`
    + li {
      margin-left: ${spacing(4)};
    }
  `)};
`;

export const MenuItem = styled(NavLink)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${spacing(0.5)};
  border-bottom: 1px solid ${colors.scarpaFlow};
  color: ${colors.white};
  text-decoration: none;
  transition: border-color, color 0.4s ease;

  height: ${spacing(7)};

  &:hover,
  &.is-active {
    border-color: ${colors.carribeanGreen};
    color: ${colors.carribeanGreen};
  }

  ${tablet(css`
    border: 0;
  `)};
`;

export const MenuText = styled.span`
  /* display: none; */
  padding-top: ${spacing()};
  ${semiBold};
  font-size: ${sizing(12)};
  display: inline;

  ${tablet(css`
    font-size: ${sizing(14)};
  `)}
`;

export const Badge = styled.span`
  background: ${colors.red};
  position: absolute;
  left: calc(50% - 20px);
  top: 6px;
  padding: ${spacing(0.5)};
  ${semiBold};
  border-radius: 50%;
  color: ${colors.white};
  font-size: ${sizing(11)};
  line-height: ${sizing(11.5)};
  text-align: center;

  min-width: 18px;
  min-height: 18px;

  ${tablet(css`
    left: 6px;
  `)}
`;
