import React from "react";
import { isTabletAndUp } from "styles/media";
import styled from "styled-components";
import { regular, semiBold } from "styles/typography";
import colors from "styles/colors";
import { sizing, spacing } from "styles/sizing";
import { NotFound } from "components/illustrations";

const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.h3`
  ${regular};
  color: ${colors.liquorice};
  font-size: ${sizing(30)};
  text-align: center;
  margin: 0;
  padding: ${spacing(3)} 0 ${spacing()} 0;
`;

const Info = styled.span`
  display: block;
  ${semiBold};
  color: ${colors.liquorice};
  font-size: ${sizing(14)};
  text-align: center;
  margin: 0;
  padding-bottom: ${spacing()};
`;

const IllustrationContainer = styled.div`
  padding: ${spacing(2)} ${spacing(5)};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyArea = () =>
  isTabletAndUp() ? (
    <Wrapper>
      <Title>No Results</Title>
      <Info>Select or create an item</Info>
      <IllustrationContainer>
        <NotFound />
      </IllustrationContainer>
    </Wrapper>
  ) : null;
