import { spacing, sizing } from "styles/sizing";
import styled, { css } from "styled-components";
import colors from "styles/colors";
import { tablet } from "styles/media";

export const Filter = styled.div`
  width: 100%;
  display: flex;
  padding: ${spacing(2)} ${spacing(2)};

  > div {
    border-bottom-color: ${colors.blackRock};
  }

  input {
    color: ${colors.blackRock};
    background: ${colors.white};
    width: 100%;
  }
`;

export const FilterButton = styled.button`
  border: none;
  padding: ${spacing()};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background: transparent;
  transition: color 0.4s ease;
  color: ${colors.blackRock};

  span {
    padding-top: ${spacing(0.5)};
    padding-left: ${spacing()};
  }

  &:focus,
  &:hover {
    outline: none;
    color: ${colors.carribeanGreen} !important;
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 20;
  background: ${colors.white};
  height: 58px;

  padding: ${spacing(1)} ${spacing(2)} ${spacing(0.5)};

  ${tablet(css`
    padding: ${spacing(2)} ${spacing(2)} ${spacing(1)};
    height: 74px;
  `)}

  span {
    font-size: ${sizing(14)};
    padding-right: ${spacing(1)};
    display: block;
    flex: 1;
  }

  button {
    width: auto;
  }
`;
