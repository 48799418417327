import React, { Component, Fragment } from "react";
import { Modal } from "components/Modal";
import { Title, Header, Body } from "./style";
import { Form } from "components/Form";
import * as Yup from "yup";
import { Field, FieldWrapper } from "components/FormField";
import { Label } from "components/FormField/styles";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";

export class AddAdmin extends Component {
  render() {
    return (
      <Modal
        isOpen
        onRequestClose={() => this.props.history.push("/dashboard")}
      >
        <Header>
          <Title>Add Admin</Title>
        </Header>
        <Body>
          <Mutation mutation={addAdmin}>
            {mutation => (
              <Form
                initialValues={{
                  first_names: "",
                  last_name: "",
                  email: "",
                  phone: ""
                }}
                validationSchema={Yup.object().shape({
                  first_names: Yup.string().required(
                    "Please enter a first name"
                  ),
                  last_name: Yup.string().required("Please enter a last name"),
                  email: Yup.string()
                    .email("Please enter a valid email")
                    .required("Please enter an email"),
                  phone: Yup.string()
                    .matches(/^[+#*()[\]]*([0-9][ ext+-pw#*()[\]]*){6,45}$/, {
                      excludeEmptyString: true,
                      message: "Please enter a valid phone number"
                    })
                    .required("Please enter a phone number")
                })}
                onReset={() => this.props.history.push("/dashboard")}
                onSubmit={values => {
                  mutation({
                    variables: {
                      first_names: values.first_names.toLowerCase(),
                      last_name: values.last_name.toLowerCase(),
                      phone: values.phone,
                      email: values.email
                    }
                  });
                }}
              >
                {(errors, values, setFieldValue, touched) => (
                  <Fragment>
                    <FieldWrapper style={{ flexDirection: "column" }}>
                      <Label name="title">First names</Label>
                      <Field name="first_names" />
                    </FieldWrapper>
                    <FieldWrapper style={{ flexDirection: "column" }}>
                      <Label name="last_name">Last name</Label>
                      <Field name="last_name" />
                    </FieldWrapper>
                    <FieldWrapper style={{ flexDirection: "column" }}>
                      <Label name="email">Email</Label>
                      <Field name="email" />
                    </FieldWrapper>
                    <FieldWrapper style={{ flexDirection: "column" }}>
                      <Label name="phone">Phone</Label>
                      <Field name="phone" />
                    </FieldWrapper>
                  </Fragment>
                )}
              </Form>
            )}
          </Mutation>
        </Body>
      </Modal>
    );
  }
}

const addAdmin = gql`
  mutation createAdmin(
    $first_names: String!
    $last_name: String!
    $email: String!
    $phone: String!
  ) {
    createAdmin(
      first_names: $first_names
      last_name: $last_name
      email: $email
      phone: $phone
    ) {
      id
    }
  }
`;
