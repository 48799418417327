import React, { Component, Fragment } from "react";
import { Range } from "rc-slider";
import { Modal } from "components/Modal";
import styled from "styled-components";
import { ArrowIcon } from "components/icons";
import "rc-slider/assets/index.css";
import posed from "react-pose";
import colors from "styles/colors";
import { Form, Formik, FieldArray } from "formik";
import { spacing, sizing } from "styles/sizing";
import { semiBold } from "styles/typography";
import { Autocomplete } from "components/Autocomplete";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { debounce } from "loadsh";
import { CancellableTag } from "components/Tag";
import { Button } from "components/Button";
import { experienceLevels } from "constants/experienceLevels";
import { Loader } from "components/Loader";

const AnimatingDropdown = posed.div({
  open: {
    height: "auto",
    opacity: 1,
    flip: true
  },
  closed: { height: 0, opacity: 0, flip: true }
});

const AnimatingArrow = posed.div({
  open: { rotate: 90 },
  closed: { rotate: 0 }
});

const DropdownWrapper = styled.div`
  color: ${colors.blackRock};
  border-bottom: 1px solid ${colors.snuff};
`;

const DropdownHeader = styled.div`
  padding: ${spacing(2)} 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-size: ${sizing(12)};
    color: ${colors.palatinateBlue};
    padding: 0 ${spacing(1)};
  }
`;
const DropdownTitle = styled.h3`
  margin: 0;
  flex: 1;
`;

const DropdownBody = styled(AnimatingDropdown)`
  overflow: ${props => (props.pose === "open" ? "visible" : "hidden")};
`;

const DropdownContainer = styled.div`
  padding: ${spacing(2)} 0;
`;

const Dropdown = ({ children, title, state, onClick, active }) => (
  <DropdownWrapper>
    <DropdownHeader onClick={onClick}>
      <DropdownTitle>
        {title}
        {!!active && <span>{`${active} selected`}</span>}
      </DropdownTitle>
      <AnimatingArrow pose={state}>
        <ArrowIcon fill={colors.blackRock} />
      </AnimatingArrow>
    </DropdownHeader>
    <DropdownBody pose={state}>
      <DropdownContainer>{children}</DropdownContainer>
    </DropdownBody>
  </DropdownWrapper>
);

const Container = styled.div`
  border: 1px solid ${colors.snuff};
  padding: ${spacing(2)};
  flex: 1;
`;

const Title = styled.h3`
  padding: ${spacing()} 0;
  display: block;
  font-size: ${sizing(18)};
  ${semiBold};
  margin: 0;
  color: ${colors.blackRock};
  text-align: center;
  pointer-events: none;
`;

const TagContainer = styled.div`
  padding: ${spacing(2)} 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: ${spacing(2)} 0;
`;

const Slider = styled.div`
  padding: ${spacing(2)};
  .slider {
    .rc-slider-track {
      background: ${colors.carribeanGreen};
    }

    .rc-slider-handle {
      margin-left: -12px;
      margin-top: -10px;
      width: 24px;
      height: 24px;
      background: ${colors.carribeanGreen};
      border: none;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        transform: translateX(-50%) translateY(-50%);
        background: ${colors.white};
        border-radius: 50%;
      }
    }
  }
`;

export class Filters extends Component {
  state = {
    activeFilter: null
  };

  getDefaultValues = () => {
    const { currentFilters } = this.props;

    const experience =
      typeof currentFilters.experience === "string"
        ? [currentFilters.experience]
        : currentFilters.experience || [];

    return {
      rate: [
        parseInt(currentFilters.day_rate_low, 10) || 0,
        parseInt(currentFilters.day_rate_high, 10) || 3000
      ],
      tags:
        typeof currentFilters.tags === "string"
          ? [currentFilters.tags]
          : currentFilters.tags || [],
      experience: experience.map(key => ({
        id: key,
        value: experienceLevels[key]
      }))
    };
  };

  handleActiveFilter = filter => {
    const { activeFilter } = this.state;
    this.setState({
      activeFilter: activeFilter === filter ? null : filter
    });
  };

  render() {
    const { isActive, onRequestClose, onSubmit } = this.props;
    const { activeFilter } = this.state;
    let defaultValues = this.getDefaultValues();
    return (
      <Modal isOpen={isActive} onRequestClose={onRequestClose}>
        <Title>Filter Roles</Title>
        <Query
          query={queryCurrentTags}
          variables={{
            ids: defaultValues.tags
          }}
        >
          {({ data: tagData, loading: tagLoading }) => {
            if (tagLoading || !tagData) {
              return <Loader />;
            }

            if (tagData) {
              defaultValues = {
                ...defaultValues,
                tags: tagData.tags
              };

              return (
                <Formik initialValues={defaultValues} onSubmit={onSubmit}>
                  {({ values, setFieldValue }) => (
                    <Form>
                      <Container>
                        <Dropdown
                          title="Rate"
                          key="rate"
                          state={activeFilter === "rate" ? "open" : "closed"}
                          onClick={() => this.handleActiveFilter("rate")}
                          active={
                            (values.rate[0] > 0 || values.rate[1]) < 3000 && 1
                          }
                        >
                          <span>
                            Range between:{" "}
                            <span style={{ color: colors.carribeanGreen }}>{`£
                    ${values.rate[0]}`}</span>
                            {" - "}
                            <span style={{ color: colors.carribeanGreen }}>{`£
                    ${values.rate[1]}`}</span>
                          </span>
                          <Slider>
                            <Range
                              id="rate"
                              min={0}
                              max={3000}
                              step={10}
                              pushable={100}
                              defaultValue={values.rate}
                              onChange={value => setFieldValue("rate", value)}
                              className="slider"
                            />
                          </Slider>
                        </Dropdown>
                        <Dropdown
                          title="Tags"
                          key="tags"
                          state={activeFilter === "tags" ? "open" : "closed"}
                          onClick={() => this.handleActiveFilter("tags")}
                          active={values.tags.length}
                        >
                          <FieldArray
                            name="tags"
                            render={arrayHelpers => (
                              <Fragment>
                                <Query
                                  query={queryTags}
                                  variables={{
                                    search: ""
                                  }}
                                >
                                  {({ data, fetchMore }) => (
                                    <Autocomplete
                                      name="tags"
                                      items={data ? data.tags : []}
                                      onChange={value =>
                                        arrayHelpers.push(value)
                                      }
                                      onInputValueChange={value => {
                                        debounce(
                                          () =>
                                            fetchMore({
                                              search: value.toLowerCase()
                                            }),
                                          1000
                                        );
                                      }}
                                    />
                                  )}
                                </Query>
                                <TagContainer>
                                  {values.tags.map((tag, index) => (
                                    <CancellableTag
                                      key={tag.id}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      {tag.value}
                                    </CancellableTag>
                                  ))}
                                </TagContainer>
                              </Fragment>
                            )}
                          />
                        </Dropdown>
                        <Dropdown
                          title="Experience"
                          key="experience"
                          state={
                            activeFilter === "experience" ? "open" : "closed"
                          }
                          onClick={() => this.handleActiveFilter("experience")}
                          active={values.experience.length}
                        >
                          <FieldArray
                            name="experience"
                            render={arrayHelpers => (
                              <Fragment>
                                <Autocomplete
                                  name="experience"
                                  items={Object.keys(experienceLevels).map(
                                    key => ({
                                      id: key,
                                      value: experienceLevels[key]
                                    })
                                  )}
                                  onChange={value => arrayHelpers.push(value)}
                                />
                                <TagContainer>
                                  {values.experience.map(
                                    (experience, index) => (
                                      <CancellableTag
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                        key={experience.id}
                                      >
                                        {experience.value}
                                      </CancellableTag>
                                    )
                                  )}
                                </TagContainer>
                              </Fragment>
                            )}
                          />
                        </Dropdown>
                      </Container>
                      <ButtonContainer>
                        <Button
                          type="button"
                          secondary
                          style={{
                            marginRight: spacing()
                          }}
                          onClick={() => {
                            onRequestClose();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          primary
                          style={{
                            marginLeft: spacing()
                          }}
                        >
                          Apply
                        </Button>
                      </ButtonContainer>
                    </Form>
                  )}
                </Formik>
              );
            }
          }}
        </Query>
      </Modal>
    );
  }
}

const queryTags = gql`
  query tags($search: String!) {
    tags(where: { value_contains: $search }) {
      id
      value
    }
  }
`;

const queryCurrentTags = gql`
  query($ids: [ID!]) {
    tags(where: { id_in: $ids }) {
      id
      value
    }
  }
`;
