import styled, { css } from "styled-components";
import colors from "styles/colors";
import { NavLink } from "react-router-dom";
import { spacing, sizing } from "styles/sizing";
import { semiBold, regular, bold } from "styles/typography";
import { tablet } from "styles/media";

export const SummaryTile = styled(NavLink).attrs({
  activeClassName: "active"
})`
  width: 100%;
  border-bottom: 1px solid ${colors.snuff};
  display: flex;
  text-decoration-line: none;
  overflow: hidden;
  padding: ${spacing(2)} 0;
  color: ${colors.blackRock};
  transition: background 0.4s ease, color 0.4s ease;

  ${tablet(css`
    padding: ${spacing(2)};
  `)}

  &.active, &:hover {
    background: ${colors.carribeanGreen};
    color: ${colors.blackRock};
  }
`;

export const SummaryImage = styled.div`
  min-width: ${props => (props.isSmall ? "60px" : "90px")};
  height: ${props => (props.isSmall ? "60px" : "90px")};

  background-image: url(${props => props.url});
  background-position: 50% 50%;
  background-size: cover;

  border-radius: 50%;
  margin-right: ${spacing(2)};
`;

export const SummaryTitle = styled.h3`
  ${semiBold};
  font-size: ${sizing(18)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  margin: 0;
  text-transform: capitalize;
  ${props => (props.isBold ? bold : regular)};
  width: 300px;
`;

export const SummarySubtitle = styled.h4`
  ${regular};
  font-size: ${sizing(16)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  margin: 0;
  padding-top: ${spacing(0.5)};
  text-transform: capitalize;
  ${props => (props.isBold ? bold : regular)};
`;

export const SummaryDetail = styled.div`
  padding-top: ${spacing()};
  flex: 1;
`;

export const SummaryDate = styled.span`
  ${props => (props.isBold ? semiBold : regular)};
  color: ${colors.palatinateBlue};
  font-size: ${sizing(12)};
  display: block;
  padding-top: ${spacing()};
`;
