import React, { Fragment } from "react";
import Toggle from "react-toggled";
import styled from "styled-components";
import colors from "styles/colors";
import { sizing } from "styles/sizing";
import { bool, func, string } from "prop-types";

const Wrapper = styled.div`
  display: flex;
  border: 1px solid ${colors.brightGray};
  border-radius: 28px;
  height: 44px;
  width: 258px;
  position: relative;

  &:focus {
    outline: none;
  }
`;

const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  text-transform: uppercase;
  text-align: center;
  font-family: "Gilroy-SemiBold";
  font-size: ${sizing(12)};
  color: ${props => (props.active ? colors.carribeanGreen : colors.blackRock)};
  transition: color 0.4s ease;
  overflow: hidden;
  cursor: pointer;

  &:focus,
  &:active {
    background: transparent;
  }
`;

const Slider = styled.div`
  width: 50%;
  position: absolute;
  top: -1px;
  left: 0;
  border: 2px solid ${colors.carribeanGreen};
  border-radius: 28px;
  height: 44px;
  pointer-events: none;
  transform: translateX(
    ${props => (props.on ? "calc(100% + 1px)" : "calc(0% - 1px)")}
  );
  transition: transform 0.4s ease;

  &:focus,
  &:active {
    background: transparent;
  }
`;

export function ToggleSwitch({
  value,
  label,
  activeLabel,
  inactiveLabel,
  onChange,
  ...props
}) {
  return (
    <Toggle defaultOn={value} onToggle={onChange}>
      {({
        on,
        getInputTogglerProps,
        getElementTogglerProps,
        setOn,
        setOff
      }) => (
        <Fragment>
          <Label style={{ display: "none" }} aria-hidden="false">
            {label}
          </Label>
          <input
            style={{ display: "none" }}
            aria-hidden="false"
            {...getInputTogglerProps()}
            type="checkbox"
          />
          <Wrapper {...getElementTogglerProps()} {...props}>
            <Label onClick={setOff} active={!on}>
              {inactiveLabel}
            </Label>
            <Label onClick={setOn} active={on}>
              {activeLabel}
            </Label>
            <Slider on={on} />
          </Wrapper>
        </Fragment>
      )}
    </Toggle>
  );
}

ToggleSwitch.propsTypes = {
  value: bool,
  onChange: func,
  label: string,
  activeLabel: string,
  inactiveLabel: string
};

ToggleSwitch.defaultProps = {
  label: "Are you available?",
  activeLabel: "Available",
  inactiveLabel: "Unavailable"
};
