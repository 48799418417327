import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { withClientState } from "apollo-link-state";
import { ApolloLink, Observable, split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { createUploadLink } from "apollo-upload-client";
import auth0Client from "auth/authClient";

import { WebSocketLink } from "apollo-link-ws";

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_ENDPOINT,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: () => {
      return {
        authToken: auth0Client.getIdToken()
      };
    }
  }
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  createUploadLink({
    uri: process.env.REACT_APP_API_ENDPOINT
  })
);

const cache = new InMemoryCache();

const request = async (operation, props) => {
  return operation.setContext({
    headers: {
      Authorization: `Bearer ${auth0Client.getIdToken()}`
    }
  });
};

const requestLink = props =>
  new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle;
        Promise.resolve(operation)
          .then(oper => request(oper, props))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );

const logoutLink = props =>
  onError(({ networkError }) => {
    console.log(networkError);
  });

export const client = props =>
  new ApolloClient({
    cache,
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        console.error({ graphQLErrors, networkError });
      }),
      logoutLink(props),
      requestLink(props),
      withClientState({
        defaults: () => {
          return {
            networkStatus: {
              __typename: "NetworkStatus",
              isConnected: false
            }
          };
        },
        resolvers: {
          Mutation: {
            updateNetworkStatus: (_, { isConnected }, { cache }) => {
              cache.writeData({ networkStatus: { isConnected } });
              return null;
            }
          }
        },
        cache
      }),
      link
    ])
  });
