import React from "react";
import styled from "styled-components";
import { SearchIcon } from "components/icons";
import colors from "styles/colors";
import { spacing } from "styles/sizing";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.blackRock};
  border-bottom: 1px solid ${colors.blackRock};
  flex: 1;
  margin-bottom: ${spacing(1)};
`;

const Input = styled.input`
  border: none;
  margin: 0;
  background: transparent;
  padding: ${spacing()};
  &:focus {
    outline: none;
  }
`;

export const Search = ({ name, onChange, defaultValue, element }) => (
  <Wrapper>
    <SearchIcon
      style={{
        marginBottom: spacing(1)
      }}
    />
    <Input
      defaultValue={defaultValue}
      placeholder="Search..."
      type="text"
      name={name}
      onChange={onChange}
      innerRef={element}
    />
  </Wrapper>
);
