import React, { Component, Fragment } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Loader } from "components/Loader";
import { NotFound } from "components/illustrations";
import { parse, stringify } from "query-string";
import { Filters } from "./filters";
import { debounce } from "loadsh";
import { Title, Results, Info, IllustrationContainer } from "./style";
import { isTabletAndUp } from "styles/media";
import { PageLayout, PageHeader } from "components/PageLayout";
import { FilterArea, FilterStatus } from "components/Filters";
import { PageTitle } from "components/PageHeader";
import { FloatingButton } from "components/PageLayout/style";
import {
  SummaryTitle,
  SummaryImage,
  SummaryDetail,
  SummaryTile,
  SummarySubtitle
} from "components/Summary";
import Helmet from "react-helmet";

export class Organisations extends Component {
  state = {
    allEntitiesRecieved: false,
    filtersActive: false
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      this.setState({
        allEntitiesRecieved: false
      });
    }
    return true;
  }

  generateFilters = () => {
    const { search, members } = parse(this.props.location.search);

    const filters = {};

    if (search) {
      filters.OR = [
        {
          name_contains: search.toLowerCase()
        }
      ];
    }

    if (members) {
      const arrayMembers = typeof members === "string" ? [members] : members;
      filters.OR = [
        ...arrayMembers.map(i => ({ members_every: { id: i } })),
        ...arrayMembers.map(i => ({ managers_every: { id: i } })),
        ...arrayMembers.map(i => ({ owner: { id: i } }))
      ];
    }
    return filters;
  };

  handleFilterSubmit = values => {
    const current = parse(this.props.location.search);
    this.setState({ filtersActive: false });

    this.props.history.push({
      search: stringify({
        ...current,
        ...{
          members: values.members.map(i => i.id)
        }
      })
    });
  };

  handleSearch = value => {
    const current = parse(this.props.location.search);
    debounce(
      () =>
        this.props.history.push({
          search: stringify({
            ...current,
            ...{
              search: value.toLowerCase()
            }
          })
        }),
      1000
    )();
  };

  render() {
    const { allEntitiesRecieved, filtersActive } = this.state;
    const { tab } = parse(this.props.location.search);
    const filters = this.generateFilters();
    const currentSearch = parse(this.props.location.search);
    const filterCount = Object.keys(currentSearch).filter(
      key => key !== "tab" || (key === "search" && currentSearch[key] !== "")
    ).length;

    return (
      <Query
        query={query}
        variables={{
          skip: 0,
          filters
        }}
        fetchPolicy={"cache-and-network"}
        onCompleted={data => {
          if (
            !allEntitiesRecieved &&
            data &&
            data.organisations &&
            data.organisations.length < 20
          ) {
            this.setState({
              allEntitiesRecieved: true
            });
          }
        }}
      >
        {({ loading, error, data, fetchMore }) => {
          if (
            data &&
            data.organisations &&
            data.organisations[0] &&
            isTabletAndUp() &&
            this.props.match.isExact
          ) {
            this.props.history.push({
              pathname: `/teams/${data.organisations[0].id}`,
              search: this.props.location.search
            });
          }
          return (
            <PageLayout
              isDual
              rightAligned
              header={
                <PageHeader>
                  <Helmet>
                    <title>Teams - Core Consultants</title>
                  </Helmet>
                  <Filters
                    isActive={filtersActive}
                    onRequestClose={() =>
                      this.setState({ filtersActive: false })
                    }
                    onSubmit={this.handleFilterSubmit}
                    currentFilters={currentSearch}
                  />

                  <PageTitle leftAligned>
                    Teams
                    <FloatingButton
                      primary
                      onClick={() =>
                        this.props.history.push("/teams/create-team")
                      }
                    >
                      Create Team
                    </FloatingButton>
                  </PageTitle>
                  <FilterArea
                    defaultValue={currentSearch.search || ""}
                    onChange={e =>
                      this.handleSearch(e.target.value.toLowerCase())
                    }
                    onClick={() => this.setState({ filtersActive: true })}
                  />
                  {filterCount ? (
                    <FilterStatus
                      sticky
                      count={filterCount}
                      onClick={() => {
                        if (this.searchElement) {
                          this.searchElement.value = "";
                        }

                        this.props.history.push({
                          search: stringify({ tab })
                        });
                      }}
                    />
                  ) : null}
                </PageHeader>
              }
              onScrollBottom={() => {
                if (!allEntitiesRecieved) {
                  fetchMore({
                    variables: {
                      skip: data.organisations.length,
                      filters: filters
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult || loading) return prev;

                      if (fetchMoreResult.organisations.length < 20) {
                        this.setState({
                          allEntitiesRecieved: true
                        });
                      }

                      return Object.assign({}, prev, {
                        organisations: [
                          ...prev.organisations,
                          ...fetchMoreResult.organisations
                        ]
                      });
                    }
                  });
                }
              }}
            >
              <Results>
                {loading && !data && <Loader />}
                {data.organisations && !data.organisations.length && (
                  <Fragment>
                    <Title>No results here</Title>
                    <Info>Try a different set of key words</Info>
                    <IllustrationContainer>
                      <NotFound />
                    </IllustrationContainer>
                  </Fragment>
                )}
                {data.organisations && data.organisations.length && (
                  <Fragment>
                    {data.organisations.map((organisation, index) => (
                      <SummaryTile
                        key={`organisation-${index}`}
                        to={{
                          pathname: `/teams/${organisation.id}`,
                          search: this.props.location.search
                        }}
                      >
                        <SummaryImage
                          url={
                            organisation.logo ||
                            "https://s3-eu-west-1.amazonaws.com/cc-app-uploads/organisation.jpg"
                          }
                        />
                        <SummaryDetail>
                          <SummaryTitle>{organisation.name}</SummaryTitle>
                          <SummarySubtitle>{`${organisation.members.length +
                            organisation.managers.length +
                            1} members`}</SummarySubtitle>
                        </SummaryDetail>
                      </SummaryTile>
                    ))}
                    {!allEntitiesRecieved && (
                      <div style={{ height: "50px" }}>
                        <Loader width="40px" />
                      </div>
                    )}
                  </Fragment>
                )}
              </Results>
            </PageLayout>
          );
        }}
      </Query>
    );
  }
}

const query = gql`
  query($skip: Int, $filters: OrganisationWhereInput) {
    organisations(skip: $skip, first: 20, where: $filters) {
      id
      name
      logo
      website
      managers {
        id
      }
      members {
        id
      }
    }
  }
`;
