import styled from "styled-components";
import { regular } from "styles/typography";
import colors from "styles/colors";
import { Link as RouterLink } from "react-router-dom";

export const Text = styled.p`
  ${regular};
  text-align: left;
  ${({ center }) => center && "text-align: center"};
`;

export const Link = styled.a`
  color: ${colors.palatinateBlue};
  ${regular};
`;

export const InternalLink = styled(RouterLink)`
  color: ${colors.palatinateBlue};
  ${regular};
`;
