import React from "react";
import { bool, string } from "prop-types";

import colors from "styles/colors";
import { Title, Wrapper } from "./style.js";

export const Logo = ({ isVertical, width, hasTitle }) => {
  return (
    <Wrapper vertical={isVertical} hasTitle={hasTitle}>
      <svg viewBox="0 0 43 44" width={width}>
        <g
          fill="none"
          fillRule="evenodd"
          strokeWidth="1.78604987"
          transform="translate(-2 -2)"
        >
          <circle
            cx="24.0050092"
            cy="23.7050092"
            r="5.10500919"
            stroke={colors.carribeanGreen}
          />
          <path
            stroke={colors.white}
            strokeLinecap="round"
            d="M36.48079237 19.7629873c-2.21353122-6.8125486-9.53061467-10.54078883-16.34316326-8.3272576-6.8125486 2.21353121-10.54078883 9.53061466-8.3272576 16.34316325 2.21353124 6.8125487 9.53061469 10.54078893 16.34316328 8.3272577 4.03007536-1.30945086 6.98079425-4.40489443 8.26060692-8.1253488"
            opacity=".90000004"
          />
          <path
            stroke={colors.white}
            strokeLinecap="round"
            d="M43.99854206 19.70018728C41.67168919 8.82554105 31.05226735 1.77315416 20.08864256 3.90426693c-11.09229529 2.15612379-18.3364936 12.8960895-16.1803698 23.9883848C6.06439653 38.98494703 16.8043622 46.22914534 27.8966575 44.07302155c8.21140415-1.59613528 14.31399553-7.89633987 16.05532459-15.56121994"
            opacity=".5"
          />
        </g>
      </svg>
      {hasTitle && (
        <Title vertical={isVertical}>
          <strong>Core</strong> Consultants
        </Title>
      )}
    </Wrapper>
  );
};

Logo.propTypes = {
  isVertical: bool,
  hasTitle: bool,
  width: string
};

Logo.defaultProps = {
  width: "100%"
};
