import { css } from "styled-components";

export const regular = css`
  font-family: "Gilroy-Regular";
  font-weight: 400;
`;

export const semiBold = css`
  font-family: "Gilroy-SemiBold";
  font-weight: 400;
`;

export const bold = css`
  font-family: "Gilroy-Bold";
  font-weight: 400;
`;
