import React, { Component, Fragment } from "react";
import { Modal } from "components/Modal";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import { sizing, spacing } from "styles/sizing";
import colors from "styles/colors";
import { semiBold, regular } from "styles/typography";
import { Formik, Form } from "formik";
import { Button } from "components/Button";
import { FieldError, FieldLabel, FieldWrapper } from "components/FormField";
import posed, { PoseGroup } from "react-pose";
import { ApplicationSent } from "components/illustrations";
import { TextEditor } from "components/TextEditor";
import { Autocomplete } from "components/Autocomplete";
import { debounce } from "loadsh";
import { Flag } from "flag";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: ${sizing(18)};
  color: ${colors.blackRock};
  ${semiBold};
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const AnimatedWrapper = posed.div({
  enter: {
    opacity: 1,
    scale: 1,
    duration: 400
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    duration: 400
  }
});

const SubmittedTitle = styled.h1`
  font-size: ${sizing(30)};
  color: ${colors.blackRock};
  ${regular};
  text-align: center;
  padding: ${spacing(4)} 0 ${spacing(1)} 0;
  margin: 0;
`;

const ImageContainer = styled.div`
  width: 250px;
  margin: 0 auto;
  padding-bottom: ${spacing(2)};
`;

export class RolePropose extends Component {
  state = {
    isFormSubmitted: false
  };
  render() {
    const roleId = this.props.match.params.id;
    const {
      User,
      match: {
        params: { id }
      }
    } = this.props;
    return (
      <Modal
        isOpen
        onRequestClose={() => this.props.history.push(`/consultants/${id}`)}
      >
        <PoseGroup>
          {!this.state.isFormSubmitted ? (
            <AnimatedWrapper key="form">
              <Wrapper>
                <Title>Propose Role</Title>
                <Flag name="unsigned">
                  <Fragment>
                    <p>
                      You have documents you need to approve in your settings
                      before you can propose roles.
                    </p>
                    <Button
                      primary
                      onClick={() => this.props.history.push("/settings")}
                    >
                      Go to Settings
                    </Button>
                  </Fragment>
                </Flag>
                <Flag name="signed">
                  <Mutation mutation={createConversation}>
                    {mutation => (
                      <Formik
                        initialValues={{ value: "" }}
                        onSubmit={values => {
                          mutation({
                            variables: {
                              value: values.value,
                              recipient: id,
                              role: values.role.id,
                              sender: User.id,
                              time: new Date().toISOString()
                            }
                          });
                          this.setState({ isFormSubmitted: true });
                        }}
                      >
                        {({ values, setFieldValue, isValid, isSubmitting }) => (
                          <Form>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <FieldLabel name="location">
                                Choose a role:
                              </FieldLabel>
                              <Query
                                query={queryRoles}
                                variables={{ id: User.id, search: "" }}
                              >
                                {({ data, fetchMore }) => (
                                  <Autocomplete
                                    isSingle
                                    style={{ width: "100%" }}
                                    items={
                                      data && data.roles
                                        ? data.roles.map(i => ({
                                            id: i.id,
                                            value: i.title,
                                            ...i
                                          }))
                                        : []
                                    }
                                    onChange={value =>
                                      setFieldValue("role", value)
                                    }
                                    onInputValueChange={value => {
                                      debounce(
                                        () =>
                                          fetchMore({
                                            search: value.toLowerCase(),
                                            id: User.id
                                          }),
                                        1000
                                      );
                                    }}
                                  />
                                )}
                              </Query>
                            </FieldWrapper>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <FieldLabel name="value">
                                What would you like to say?
                              </FieldLabel>
                              <TextEditor
                                defaultValue={values.value}
                                onChange={value =>
                                  setFieldValue("value", value)
                                }
                              />
                              <FieldError name="value" />
                            </FieldWrapper>
                            <ButtonContainer>
                              <Button
                                style={{ marginRight: spacing(1) }}
                                secondary
                                onClick={() =>
                                  this.props.history.push(
                                    `/consultants/${roleId}`
                                  )
                                }
                              >
                                Cancel
                              </Button>
                              <Button
                                style={{ marginLeft: spacing(1) }}
                                disabled={!isValid || isSubmitting}
                                type="submit"
                                primary
                              >
                                Propose
                              </Button>
                            </ButtonContainer>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </Mutation>
                </Flag>
              </Wrapper>
            </AnimatedWrapper>
          ) : (
            <AnimatedWrapper key="result">
              <SubmittedTitle>Proposal sent</SubmittedTitle>
              <ImageContainer>
                <ApplicationSent dark />
              </ImageContainer>
              <Button
                primary
                onClick={() => this.props.history.push(`/consultants/${id}`)}
              >
                Close
              </Button>
            </AnimatedWrapper>
          )}
        </PoseGroup>
      </Modal>
    );
  }
}

const createConversation = gql`
  mutation(
    $sender: ID!
    $recipient: ID!
    $role: ID!
    $value: String!
    $time: DateTime!
  ) {
    createConversation(
      data: {
        recipient: { connect: { id: $recipient } }
        sender: { connect: { id: $sender } }
        role: { connect: { id: $role } }
        type: ROLE
        lastMessageAt: $time
        messages: {
          create: { value: $value, user: { connect: { id: $sender } } }
        }
      }
    ) {
      id
      recipient {
        id
      }
      sender {
        id
      }
      role {
        id
      }
    }
  }
`;

const queryRoles = gql`
  query($id: ID!, $search: String) {
    roles(
      where: {
        closed: false
        title_contains: $search
        OR: [
          { client: { id: $id } }
          {
            organisation: {
              OR: [{ managers_some: { id: $id } }, { owner: { id: $id } }]
            }
          }
        ]
      }
    ) {
      id
      title
    }
  }
`;
