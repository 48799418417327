import React from "react";
import { Formik } from "formik";
import { object, func, string } from "prop-types";
import { StyledForm, FormButtons, FormButton, Container } from "./styles";

export const Form = ({
  children,
  initialValues,
  validationSchema,
  submitText,
  resetText,
  onSubmit,
  onReset,
  isInitialValid,
  ...props
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      isInitialValid={isInitialValid}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
      {...props}
    >
      {({
        errors,
        handleReset,
        isValid,
        isSubmitting,
        values,
        touched,
        setFieldValue
      }) => (
        <StyledForm>
          <Container>
            {children(errors, values, setFieldValue, touched)}
          </Container>

          {/* {typeof errors[Object.keys(errors)[0]] !== "object" &&
            Object.keys(errors).length > 0 && (
              <Errors>
                {Object.keys(errors).map((error, index) => (
                  <StyledFieldError key={index} name={error} />
                ))}
              </Errors>
            )} */}

          <FormButtons>
            <FormButton
              type="button"
              onClick={() => {
                onReset();
                handleReset();
              }}
              secondary
            >
              {resetText}
            </FormButton>
            <FormButton
              type="submit"
              disabled={!isValid || isSubmitting}
              primary
            >
              {submitText}
            </FormButton>
          </FormButtons>
        </StyledForm>
      )}
    </Formik>
  );
};

Form.propTypes = {
  children: func.isRequired,
  initialValues: object,
  validationSchema: object,
  submitText: string,
  resetText: string,
  handleSubmit: func,
  handleReset: func
};

Form.defaultProps = {
  initialValues: {},
  validationSchema: {},
  submitText: "Submit",
  resetText: "Cancel",
  handleSubmit: () => {},
  handleReset: () => {}
};
