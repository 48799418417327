import React from "react";
import styled from "styled-components";
import { spacing, sizing } from "styles/sizing";
import colors from "styles/colors";
import { NavLink } from "react-router-dom";
import { Button } from "components/Button";

const DocumentCardWrapper = styled(NavLink)`
  padding: ${spacing(1)};
  width: 50%;
  text-decoration: none;
`;

const DocumentCardInner = styled.div`
  padding: ${spacing(1)};
  border: 1px solid ${colors.snuff};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background 0.4s ease;

  &:hover {
    background: ${colors.carribeanGreen};
  }
`;

export const DocumentName = styled.span`
  font-size: ${sizing(16)};
  text-align: center;
  padding: ${spacing(1)} 0;
  color: ${colors.blackRock};
  text-decoration: none;
`;

export const DocumentDescription = styled.span`
  font-size: ${sizing(16)};
  text-align: center;
  padding: ${spacing(1)} 0;
  color: ${colors.blackRock};
  text-decoration: none;
`;

export const DocumentText = styled.span`
  font-size: ${sizing(16)};
  text-align: center;
  padding: ${spacing(1)} 0;
  color: ${colors.blackRock};
  text-decoration: none;
`;

export const DocumentAction = styled(Button)`
  cursor: pointer;
  display: block;
  margin: ${spacing(0.5)} 0;
  padding: ${spacing()};
  font-size: ${sizing(14)};
`;

export const DocumentCard = ({ children, to }) => (
  <DocumentCardWrapper to={to}>
    <DocumentCardInner>{children}</DocumentCardInner>
  </DocumentCardWrapper>
);
