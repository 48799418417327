import React, { useState } from "react";
import { shape, arrayOf, func, string, bool } from "prop-types";
import Downshift from "downshift";
import { SearchIcon } from "components/icons";
import { Wrapper, Input, ResultList, ResultItem, Image } from "./style";

export function Autocomplete({
  items,
  onChange,
  onInputValueChange,
  create,
  error,
  isSingle,
  disabled
}) {
  const [isFocused, setFocus] = useState(false);
  return (
    <Downshift
      disabled={disabled}
      onChange={(selection, { setState }) => {
        if (!selection.value) {
          return;
        }

        onChange(selection);
        if (!isSingle) {
          setState({
            inputValue: ""
          });
        }
      }}
      initialInputValue={
        items && items.find(i => i.isActive)
          ? items.find(i => i.isActive).value
          : ""
      }
      itemToString={item => (item ? item.value : "")}
      onInputValueChange={onInputValueChange}
    >
      {({
        getInputProps,
        getItemProps,
        inputValue,
        highlightedIndex,
        selectedItem,
        getRootProps
      }) => (
        <Wrapper
          {...getRootProps({
            refKey: "innerRef"
          })}
        >
          <Input
            {...getInputProps({
              onFocus: () => {
                setFocus(true);
              },
              onBlur: () => {
                setFocus(false);
              }
            })}
            label={!disabled ? <SearchIcon width={22} height={22} /> : null}
            placeholder="Search"
            type="text"
            error={error}
            disabled={disabled}
          />
          {isFocused && (
            <ResultList>
              {inputValue.length > 3 &&
                create &&
                items.every(
                  i => i.value.toLowerCase() !== inputValue.toLowerCase()
                ) && (
                  <ResultItem
                    {...getItemProps({
                      item: { value: inputValue, create: true },
                      index: 0,
                      isActive: highlightedIndex === 0,
                      isSelected: selectedItem === ""
                    })}
                  >{`Create Tag: ${inputValue}`}</ResultItem>
                )}
              {items
                .filter(
                  item =>
                    !inputValue ||
                    item.value.toLowerCase().includes(inputValue.toLowerCase())
                )
                .filter(item => !item.active)
                .map((item, index) => (
                  <ResultItem
                    {...getItemProps({
                      item,
                      index: index + 1,
                      isActive: highlightedIndex === index + 1,
                      isSelected: selectedItem === item
                    })}
                    key={item.id || item.value}
                  >
                    {item.image && <Image src={item.image} />}
                    {item.value}
                  </ResultItem>
                ))}
              {!items.length ? <ResultItem>No Results</ResultItem> : null}
            </ResultList>
          )}
        </Wrapper>
      )}
    </Downshift>
  );
}

Autocomplete.propTypes = {
  items: arrayOf(
    shape({
      id: string.isRequired,
      value: string.isRequired
    })
  ),
  onChange: func.isRequired,
  onInputValueChange: func,
  error: bool,
  create: bool
};

Autocomplete.defaultProps = {
  onInputValueChange: () => null
};
