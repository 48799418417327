import React, { Fragment, Component } from "react";
import { Modal } from "components/Modal";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import { sizing, spacing } from "styles/sizing";
import colors from "styles/colors";
import { semiBold, regular } from "styles/typography";
import { Loader } from "components/Loader";
import { Formik, Form } from "formik";
import { Button } from "components/Button";
import { FieldLabel, FieldWrapper, FieldError } from "components/FormField";
import posed, { PoseGroup } from "react-pose";
import { ApplicationSent } from "components/illustrations";
import { TextEditor } from "components/TextEditor";
import { Flag } from "flag";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: ${sizing(18)};
  color: ${colors.blackRock};
  ${semiBold};
  text-align: center;
  text-transform: capitalize;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const AnimatedWrapper = posed.div({
  enter: {
    opacity: 1,
    scale: 1,
    duration: 400
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    duration: 400
  }
});

const SubmittedTitle = styled.h1`
  font-size: ${sizing(30)};
  color: ${colors.blackRock};
  ${regular};
  text-align: center;
  padding: ${spacing(4)} 0 ${spacing(1)} 0;
  margin: 0;
`;

const SubmittedInfo = styled.span`
  color: ${colors.blackRock};
  ${regular};
  text-align: center;
  padding: ${spacing(2.5)} 0 ${spacing(3)} 0;
  display: block;
  font-size: ${sizing(14)};
  line-height: ${sizing(22)};
`;

const ImageContainer = styled.div`
  width: 250px;
  margin: 0 auto;
  padding-bottom: ${spacing(2)};
`;

export class RoleApply extends Component {
  state = {
    isFormSubmitted: false
  };
  render() {
    const roleId = this.props.match.params.id;
    const {
      User,
      match: {
        params: { id }
      }
    } = this.props;
    return (
      <Modal
        isOpen
        onRequestClose={() => this.props.history.push(`/roles/${roleId}`)}
      >
        <Query query={query} variables={{ id: id }}>
          {({ data, loading }) => (
            <PoseGroup>
              {!this.state.isFormSubmitted ? (
                <AnimatedWrapper key="form">
                  <Fragment>
                    {loading && <Loader />}
                    {data.role && (
                      <Wrapper>
                        <Title>{data.role.title}</Title>
                        <Flag name="unsigned">
                          <Fragment>
                            <p>
                              You have documents you need to approve in your
                              settings before you can apply to roles.
                            </p>
                            <Button
                              primary
                              onClick={() =>
                                this.props.history.push("/settings")
                              }
                            >
                              Go to Settings
                            </Button>
                          </Fragment>
                        </Flag>
                        <Flag name="signed">
                          <Mutation mutation={createApplication}>
                            {mutation => (
                              <Formik
                                initialValues={{ value: "" }}
                                onSubmit={values => {
                                  mutation({
                                    variables: {
                                      value: values.value,
                                      sender: User.id,
                                      role: id,
                                      recipient: data.role.client.id,
                                      time: new Date().toISOString()
                                    }
                                  });
                                  this.setState({ isFormSubmitted: true });
                                }}
                              >
                                {({
                                  errors,
                                  isValid,
                                  handleChange,
                                  isSubmitting,
                                  values,
                                  setFieldValue
                                }) => (
                                  <Form>
                                    <FieldWrapper>
                                      <FieldLabel name="value">
                                        Your message
                                      </FieldLabel>
                                      <TextEditor
                                        defaultValue={values.value}
                                        onChange={value =>
                                          setFieldValue("value", value)
                                        }
                                      />
                                      <FieldError name="value" />
                                    </FieldWrapper>
                                    <ButtonContainer>
                                      <Button
                                        style={{ marginRight: spacing(1) }}
                                        secondary
                                        onClick={() =>
                                          this.props.history.push(
                                            `/roles/${roleId}`
                                          )
                                        }
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        style={{ marginLeft: spacing(1) }}
                                        disabled={!isValid || isSubmitting}
                                        type="submit"
                                        primary
                                      >
                                        Apply Now
                                      </Button>
                                    </ButtonContainer>
                                  </Form>
                                )}
                              </Formik>
                            )}
                          </Mutation>
                        </Flag>
                      </Wrapper>
                    )}
                  </Fragment>
                </AnimatedWrapper>
              ) : (
                <AnimatedWrapper key="result">
                  <SubmittedTitle>
                    Your application was successful!
                  </SubmittedTitle>
                  <SubmittedInfo>
                    {`You applied for ${data.role.title}`}
                  </SubmittedInfo>
                  <ImageContainer>
                    <ApplicationSent dark />
                  </ImageContainer>
                  <Button
                    primary
                    onClick={() => this.props.history.push(`/roles/${roleId}`)}
                  >
                    Close
                  </Button>
                </AnimatedWrapper>
              )}
            </PoseGroup>
          )}
        </Query>
      </Modal>
    );
  }
}

const query = gql`
  query($id: ID!) {
    role(where: { id: $id }) {
      id
      title
      day_rate
      location
      experience
      full_time
      client {
        id
      }
    }
  }
`;

const createApplication = gql`
  mutation(
    $recipient: ID!
    $sender: ID!
    $role: ID!
    $value: String!
    $time: DateTime!
  ) {
    createConversation(
      data: {
        recipient: { connect: { id: $sender } }
        sender: { connect: { id: $recipient } }
        role: { connect: { id: $role } }
        type: ROLE
        lastMessageAt: $time
        messages: {
          create: { value: $value, user: { connect: { id: $sender } } }
        }
      }
    ) {
      id
      recipient {
        id
      }
      sender {
        id
      }
      role {
        id
      }
    }
  }
`;
