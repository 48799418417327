import styled from "styled-components";
import { ProgressBar } from "components/ProgressBar";
import { sizing, spacing } from "styles/sizing";
import { regular, semiBold } from "styles/typography";
import colors from "styles/colors";
import { desk } from "styles/media";
import { rgba } from "polished";
import { Loader } from "components/Loader";
import { NavLink } from "react-router-dom";

export const StyledLoader = styled(Loader)`
  background-color: ${colors.blackRock};
`;

export const StyledProgressBar = styled(ProgressBar)`
  width: 111px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${spacing(1)};
  margin-top: ${spacing(2)};
`;

export const Name = styled.h1`
  margin: 0;
  font-size: ${sizing(32)};
  line-height: ${sizing(36)};
  letter-spacing: -0.76px;
  text-align: center;
  ${regular};

  ${desk(`
    font-size: ${sizing(48)};
    line-height: ${sizing(54)};
  `)};
`;

export const Title = styled.span`
  margin-bottom: ${spacing(1)};
  font-size: ${sizing(14)};
  line-height: ${sizing(22)};
  ${semiBold};
  text-align: center;
  flex: 1;

  ${desk(`
    font-size: ${sizing(18)};
    line-height: ${sizing(28)};
  `)};
`;

export const Container = styled.div`
  background-color: ${colors.white};
  width: 100%;
  flex: 1;
`;

export const Copy = styled.span`
  font-size: ${sizing(14)};
  line-height: ${sizing(24)};
  color: ${rgba(colors.liquorice, 0.8)};
  ${regular};
`;

export const Tabs = styled.div`
  width: 100%;
  display: flex;
  padding-top: ${spacing(2)};
  border-bottom: 1px solid ${colors.snuff};
`;

export const Tab = styled(NavLink)`
  text-decoration: none;
  color: ${colors.frenchGray};
  border-bottom: 1px solid ${colors.snuff};
  margin-right: ${spacing(2)};
  margin-bottom: -1px;
  ${semiBold};
  font-size: ${sizing(14)};
  text-transform: uppercase;
  cursor: pointer;

  transition: color 0.4s ease, border-color 0.4s ease;

  &:hover,
  &:focus {
    color: ${colors.carribeanGreen};
    border-color: ${colors.carribeanGreen};
  }

  &.active {
    color: ${colors.carribeanGreen};
    border-color: ${colors.carribeanGreen};
  }
`;

export const RolesContainer = styled.div``;

export const Results = styled.div`
  width: 100%;
  flex: 1;
  background: ${colors.white};
  padding: ${spacing(2)} 0;
  display: flex;
  flex-direction: column;
`;

export const IllustrationContainer = styled.div`
  padding: ${spacing(2)} ${spacing(5)};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${sizing(18)};
  color: ${colors.blackRock};
  ${semiBold};
`;
