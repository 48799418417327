import React, { Fragment } from "react";
import { FieldArray } from "formik";
import { Autocomplete } from "components/Autocomplete";
import { CancellableTag } from "components/Tag";
import { Query } from "react-apollo";
import { debounce } from "loadsh";
import styled from "styled-components";
import { spacing } from "styles/sizing";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TagContainer = styled.div`
  padding: ${spacing(2)} 0;
  flex: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const TagImage = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: ${spacing(0.5)};
`;

export const UserInput = ({ values, query, style, name, id, isSingular }) => {
  return (
    <Wrapper style={style}>
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <Fragment>
            <Query
              query={query}
              variables={{
                search: "",
                id
              }}
            >
              {({ data, fetchMore }) => (
                <Autocomplete
                  name={name}
                  isSingle={isSingular}
                  items={
                    data && data.users
                      ? data.users
                          .filter(i =>
                            values ? values.every(v => v.id !== i.id) : i
                          )
                          .map(i => ({
                            id: i.id,
                            value: `${i.first_names} ${i.last_name}`,
                            image: i.avatar,
                            ...i
                          }))
                      : []
                  }
                  onChange={value => {
                    arrayHelpers.push(value);
                  }}
                  onInputValueChange={value => {
                    debounce(
                      () =>
                        fetchMore({
                          search: value.toLowerCase()
                        }),
                      1000
                    );
                  }}
                />
              )}
            </Query>
            {!isSingular && (
              <TagContainer>
                {values &&
                  values.map((user, index) => (
                    <CancellableTag
                      key={user.id}
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <TagImage src={user.avatar} />
                      {`${user.first_names} ${user.last_name}`}
                    </CancellableTag>
                  ))}
              </TagContainer>
            )}
          </Fragment>
        )}
      />
    </Wrapper>
  );
};
