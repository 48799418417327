import styled from "styled-components";
import colors from "styles/colors";
import { spacing, sizing } from "styles/sizing";
import { regular, semiBold } from "styles/typography";

export const Header = styled.div`
  width: 100%;
`;

export const Results = styled.div`
  width: 100%;
  flex: 1;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  ${regular};
  font-size: ${sizing(30)};
  text-align: center;
  margin: 0;
  padding: ${spacing(3)} 0 ${spacing()} 0;
`;

export const Info = styled.span`
  display: block;
  ${semiBold};
  color: ${colors.liquorice};
  font-size: ${sizing(14)};
  text-align: center;
  margin: 0;
  padding-bottom: ${spacing()};
`;

export const IllustrationContainer = styled.div`
  padding: ${spacing(2)} ${spacing(5)};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const JobTitle = styled.h4`
  ${semiBold};
  font-size: ${sizing(16)};
  color: ${colors.frenchGray};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  margin: 0;
  padding-top: ${spacing(1)};
`;

export const RoleDetail = styled.div`
  padding-top: ${spacing()};
  flex: 1;
`;

export const FlexWrapper = styled.div`
  display: flex;
  padding: ${spacing(2)} 0;
`;
