import React, { useState, Fragment } from "react";
import { Logo } from "components/Logo";
import { PageContainer } from "components/PageContainer";
import colors from "styles/colors";
import styled from "styled-components";
import { ArrowMessage } from "components/ArrowMessage";
import auth0Client from "auth/authClient";
import { Loader } from "components/Loader";
import { Form } from "containers/getStarted/Form";
import { CardHeading, Card, CardWrapper } from "components/Card";
import { Text, InternalLink } from "components/Text";
import { semiBold } from "styles/typography";
import { spacing, sizing } from "styles/sizing";

const AbsoluteLeft = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  transform: translateY(-50%);
`;

const Error = styled.div`
  ${semiBold};
  font-size: ${sizing(14)};
  color: ${colors.white};
  width: 100%;
  text-align: center;
  padding: ${spacing(1)};
  background: ${colors.red};
  margin: ${spacing(2)} 0;
  border-radius: 4px;
`;

const formConfig = {
  fields: [
    {
      name: "email",
      type: "email",
      label: "Email",
      options: {
        placeholder: "Email",
        autoComplete: "email",
        className: "block"
      },
      required: true
    },
    {
      name: "password",
      type: "password",
      label: "Password",
      options: {
        placeholder: "Password",
        autoComplete: "password",
        className: "block"
      },
      required: true
    }
  ],
  submitText: "Login"
};

export const ClientLogin = ({ history: { push } }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = (values, setSubmitting) => {
    setError(null);
    setLoading(true);
    auth0Client.login(
      {
        ...values,
        realm: "Username-Password-Authentication"
      },
      error => {
        if (error) {
          setError(error);
          setLoading(false);
        }
      }
    );
  };

  return (
    <PageContainer>
      <CardWrapper>
        <Logo width="60px" isVertical />
        <CardHeading>Enter your details to continue</CardHeading>
        <AbsoluteLeft>
          <ArrowMessage to="/" left>
            Back
          </ArrowMessage>
        </AbsoluteLeft>
        <Card>
          {loading && navigator.onLine && <Loader />}
          {!loading && navigator.onLine && (
            <Fragment>
              {error && <Error>{error.error_description}</Error>}
              <Form config={formConfig} onSubmit={handleLogin} />
              <Text>
                <InternalLink to="/forgot-password">
                  Forget your details?
                </InternalLink>
              </Text>
              <Text>
                Not yet signed up?{" "}
                <InternalLink to="/">Join us now</InternalLink>
              </Text>
            </Fragment>
          )}
          {!navigator.onLine && (
            <p>You are currently offline. Please reconnect to login.</p>
          )}
        </Card>
      </CardWrapper>
    </PageContainer>
  );
};
