import styled, { css } from "styled-components";
import { spacing, sizing } from "styles/sizing";
import colors from "styles/colors";
import { tablet } from "styles/media";
import { regular } from "styles/typography";

export const ContentArea = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ContentBlock = styled.div`
  padding: ${spacing()};
  margin-top: ${spacing()};
  border: 1px solid ${colors.snuff};
  position: relative;

  ${tablet(css`
    padding: ${spacing(2)};
    border: 1px solid ${colors.snuff};
    margin-top: ${spacing(2)};
  `)}
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing(0)};
  width: 100%;

  ${tablet(css`
    width: 50%;
    padding: ${spacing(2)};
  `)}
`;

export const ContentTitle = styled.h2`
  margin: 0;
  width: 100%;
  font-size: ${sizing(24)};
  ${regular};
  text-align: center;
  padding: ${spacing(2)} ${spacing(1)};
`;
