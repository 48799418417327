import React, { Component } from "react";
import { Logo } from "components/Logo";
import { PageContainer } from "components/PageContainer";
import colors from "styles/colors";
import { Button } from "components/Button";
import styled from "styled-components";
import { semiBold, regular } from "styles/typography";
import { spacing, sizing } from "styles/sizing";
import { Formik, Form } from "formik";
import { Field, FieldError } from "components/FormField";
import { tablet } from "styles/media";
import * as Yup from "yup";
import { ArrowMessage } from "components/ArrowMessage";
import { ApplicationSent } from "components/illustrations";
import posed, { PoseGroup } from "react-pose";

const Title = styled.h1`
  font-size: ${sizing(20)};
  color: ${colors.white};
  ${regular};
  text-align: center;
  padding: ${spacing(2)} 0;

  ${tablet(`
    font-size: ${sizing(24)};
  `)};
`;

const SubmittedTitle = styled.h1`
  font-size: ${sizing(30)};
  color: ${colors.white};
  ${regular};
  text-align: center;
  padding: ${spacing(4)} 0 ${spacing(1)} 0;
  margin: 0;
`;

const Card = styled.section`
  width: 100%;
  flex: 1;
  background: ${colors.white};
  border-radius: 2px;
  padding: ${spacing(3)};

  ${tablet(`
    padding: ${spacing(6)} ${spacing(12)};
  `)};
`;

const Info = styled.span`
  color: ${colors.blackRock};
  ${regular};
  text-align: center;
  padding: ${spacing(2.5)} 0 ${spacing(3)} 0;
  display: block;
  font-size: ${sizing(14)};
`;

const SubmittedInfo = styled.span`
  color: ${colors.white};
  ${regular};
  text-align: center;
  padding: ${spacing(2.5)} 0 ${spacing(3)} 0;
  display: block;
  font-size: ${sizing(14)};
  line-height: ${sizing(22)};
`;

const LogoContainer = styled.div`
  width: 60px;
  margin: 0 auto;

  ${tablet(`
    width: 150px;
  `)};
`;

const AbsoluteLeft = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  transform: translateY(-50%);
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ImageContainer = styled.div`
  width: 250px;
  margin: 0 auto;
  padding-bottom: ${spacing(2)};
`;

const AnimatedWrapper = posed.div({
  enter: {
    opacity: 1,
    scale: 1,
    duration: 400
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    duration: 400
  }
});

const InlineLink = styled.a`
  ${semiBold};
  font-size: ${sizing(14)};
  color: ${colors.blackRock};
  text-decoration: none;
`;

export class Signup extends Component {
  state = {
    isFormSubmitted: false
  };
  render() {
    const { isFormSubmitted } = this.state;
    return (
      <PageContainer>
        <Wrapper>
          <LogoContainer>
            <Logo isVertical />
          </LogoContainer>
          <PoseGroup>
            {!isFormSubmitted ? (
              <AnimatedWrapper key="form">
                <Title>Great to hear you want to join us!</Title>
                <AbsoluteLeft>
                  <ArrowMessage to="/" left>
                    Back
                  </ArrowMessage>
                </AbsoluteLeft>
                <Card>
                  <strong style={{ textAlign: "center", display: "block" }}>
                    Request to join as a hiring firm
                  </strong>
                  <Info>
                    Tell us your email and/or phone number and we will be in
                    touch to open your account.
                    <br />
                    <br />
                    Alternatively, email us{" "}
                    <InlineLink href="mailto:support@coreconsultants.io">
                      here
                    </InlineLink>
                  </Info>
                  <Formik
                    validationSchema={Yup.object().shape({
                      first_names: Yup.string().required(
                        "Please enter your first name."
                      ),
                      last_name: Yup.string().required(
                        "Please enter your last name."
                      ),
                      email: Yup.string()
                        .email("Please enter a valid email")
                        .required("Please enter your email"),
                      phone: Yup.string()
                        .matches(
                          /^(0\d{10,12}|44\d{10,12})$/s,
                          "Please enter a valid phone number"
                        )
                        .required("Please enter your phone number"),
                      company: Yup.string().required(
                        "Please enter your company name."
                      )
                    })}
                    validateOnBlur={true}
                    onSubmit={values =>
                      fetch(process.env.REACT_APP_REGISTER_CLIENT_INTEREST, {
                        method: "POST",
                        mode: "no-cors",
                        headers: {
                          "Content-Type": "application/json; charset=utf-8"
                        },
                        body: JSON.stringify({
                          ...values,
                          type: "Hiring Firm"
                        })
                      }).then(() => this.setState({ isFormSubmitted: true }))
                    }
                  >
                    {({ isValid, isSubmitting, setFieldValue, values }) => (
                      <Form>
                        <Field
                          name="first_names"
                          type="text"
                          placeholder="First Names"
                          autoComplete="given-name"
                          className="block"
                        />
                        <FieldError name="first_names" />
                        <Field
                          name="last_name"
                          type="text"
                          placeholder="Last Name"
                          autoComplete="family-name"
                          className="block"
                        />
                        <FieldError name="last_name" />
                        <Field
                          name="email"
                          type="email"
                          placeholder="Email"
                          autoComplete="email"
                          className="block"
                        />
                        <FieldError name="email" />
                        <Field
                          name="phone"
                          type="tel"
                          placeholder="Phone"
                          autoComplete="tel"
                          className="block"
                        />
                        <FieldError name="phone" />
                        <Field
                          name="company"
                          type="text"
                          placeholder="Your Company"
                          className="block"
                        />
                        <FieldError name="company" />
                        <Button
                          disabled={!isValid || isSubmitting}
                          type="submit"
                          primary
                        >
                          Request to join
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Card>
              </AnimatedWrapper>
            ) : (
              <AnimatedWrapper key="result">
                <SubmittedTitle>Thank you, we will be in touch!</SubmittedTitle>
                <SubmittedInfo>
                  One of our team members will be in touch to open your account
                  in the next 48 hours.
                </SubmittedInfo>
                <ImageContainer>
                  <ApplicationSent />
                </ImageContainer>
                <Button
                  tertiary
                  onClick={() =>
                    (window.location = "http://coreconsultants.io")
                  }
                >
                  Return to home
                </Button>
              </AnimatedWrapper>
            )}
          </PoseGroup>
        </Wrapper>
      </PageContainer>
    );
  }
}
