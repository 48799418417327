import styled from "styled-components";
import Modal from "react-modal";
import colors from "styles/colors";
import { tabletStart } from "styles/consts";
import { spacing, sizing } from "styles/sizing";
import { semiBold } from "styles/typography";
import { tablet } from "styles/media";

export const StyledModal = styled(Modal)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: ${tabletStart}px;
  max-height: 100%;
  margin: 0;
  padding: ${spacing(3)};
  background-color: ${colors.white};

  ${tablet(`
    margin: auto;
    max-height: 90%;
  `)};
`;

export const Title = styled.h1`
  margin: 0 0 ${spacing(2)};
  font-size: ${sizing(18)};
  line-height: ${sizing(26)};
  text-align: center;
  color: ${colors.liquorice};
  ${semiBold};

  ${tablet(`
    font-size: ${sizing(20)};
  `)};
`;
