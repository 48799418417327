import React, { Component, Fragment } from "react";
import { Modal } from "components/Modal";
import { Title, Header, Body } from "./style";
import { Form } from "components/Form";
import * as Yup from "yup";
import { Field, FieldWrapper } from "components/FormField";
import { Label } from "components/FormField/styles";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { Autocomplete } from "components/Autocomplete";
import { locations } from "constants/locations";
import { ConfirmationDialog } from "components/ConfirmationDialog";
import { AnimatedWrapper } from "containers/OrganisationDelete/styles";
import { PoseGroup } from "react-pose";
import {
  SubmittedTitle,
  ImageContainer
} from "containers/OrganisationInvite/styles";
import { NotFound, ApplicationSent } from "components/illustrations";
import { Button } from "components/Button";
import auth0Client from "auth/authClient";

export class CreateClient extends Component {
  state = {
    isFormSubmitted: false,
    error: false,
    loading: false
  };
  render() {
    const { isFormSubmitted, error } = this.state;
    return (
      <Modal
        isOpen
        onRequestClose={() =>
          ConfirmationDialog({
            message: "Are you sure you wish to exit, your data will be lost?",
            primaryText: "Close",
            primaryAction: () => this.props.history.push("/clients"),
            secondaryText: "Cancel",
            secondaryAction: () => null
          })
        }
      >
        <PoseGroup>
          {!isFormSubmitted ? (
            <AnimatedWrapper key="form">
              <Header>
                <Title>Create Client</Title>
              </Header>
              <Body>
                <Mutation
                  mutation={createClient}
                  onCompleted={data => {
                    this.setState({ loading: false });

                    auth0Client.changePassword(
                      {
                        email: data.createClient.email,
                        connection: "Username-Password-Authentication"
                      },
                      err => {
                        if (err) {
                          console.log(err.error_description);
                        }
                      }
                    );
                  }}
                >
                  {mutation => (
                    <Form
                      initialValues={{
                        first_names: "",
                        last_name: "",
                        email: "",
                        phone: "",
                        job_title: "",
                        location: {
                          id: null,
                          value: null
                        }
                      }}
                      validationSchema={Yup.object().shape({
                        first_names: Yup.string().required(
                          "Please enter a first name"
                        ),
                        last_name: Yup.string().required(
                          "Please enter a last name"
                        ),
                        email: Yup.string()
                          .email("Please enter a valid email")
                          .required("Please enter an email"),
                        phone: Yup.string()
                          .matches(
                            /^[+#*()[\]]*([0-9][ ext+-pw#*()[\]]*){6,45}$/,
                            {
                              excludeEmptyString: true,
                              message: "Please enter a valid phone number"
                            }
                          )
                          .required("Please enter a phone number"),
                        job_title: Yup.string().required(
                          "Please enter a job title"
                        ),
                        location: Yup.object()
                          .shape({
                            id: Yup.string(),
                            value: Yup.string()
                          })
                          .required("Please include a location")
                      })}
                      onReset={() => this.props.history.push("/clients")}
                      onSubmit={values => {
                        mutation({
                          variables: {
                            ...values,
                            first_names: values.first_names.toLowerCase(),
                            last_name: values.last_name.toLowerCase(),
                            job_title: values.job_title.toLowerCase(),
                            location: values.location.id
                          }
                        });
                        this.setState({ isFormSubmitted: true });
                      }}
                    >
                      {(errors, values, setFieldValue, touched) => (
                        <Fragment>
                          <FieldWrapper style={{ flexDirection: "column" }}>
                            <Label name="title">First names</Label>
                            <Field name="first_names" />
                          </FieldWrapper>
                          <FieldWrapper style={{ flexDirection: "column" }}>
                            <Label name="last_name">Last name</Label>
                            <Field name="last_name" />
                          </FieldWrapper>
                          <FieldWrapper style={{ flexDirection: "column" }}>
                            <Label name="email">Email</Label>
                            <Field name="email" />
                          </FieldWrapper>
                          <FieldWrapper style={{ flexDirection: "column" }}>
                            <Label name="location">Location</Label>
                            <Autocomplete
                              style={{ width: "100%" }}
                              isSingle
                              items={Object.keys(locations).map(key => ({
                                id: key,
                                isActive: key === values.location.id,
                                value: locations[key]
                              }))}
                              onChange={value =>
                                setFieldValue("location", value)
                              }
                            />
                          </FieldWrapper>
                          <FieldWrapper style={{ flexDirection: "column" }}>
                            <Label name="phone">Phone</Label>
                            <Field name="phone" />
                          </FieldWrapper>
                          <FieldWrapper style={{ flexDirection: "column" }}>
                            <Label name="job_title">Job Title</Label>
                            <Field name="job_title" />
                          </FieldWrapper>
                        </Fragment>
                      )}
                    </Form>
                  )}
                </Mutation>
              </Body>
            </AnimatedWrapper>
          ) : (
            <AnimatedWrapper key="result">
              <SubmittedTitle>
                {error ? "There was an error" : "your client has been created"}
              </SubmittedTitle>
              <ImageContainer>
                {error ? <NotFound dark /> : <ApplicationSent dark />}
              </ImageContainer>
              <Button
                primary
                type="button"
                onClick={() => this.props.history.push(`/clients`)}
              >
                Close
              </Button>
            </AnimatedWrapper>
          )}
        </PoseGroup>
      </Modal>
    );
  }
}

const createClient = gql`
  mutation createClient(
    $first_names: String!
    $last_name: String!
    $email: String!
    $phone: String!
    $job_title: String!
    $location: Location!
  ) {
    createClient(
      first_names: $first_names
      last_name: $last_name
      email: $email
      phone: $phone
      job_title: $job_title
      location: $location
    ) {
      id
      first_names
      last_name
      email
      data {
        job_title
        phone
      }
    }
  }
`;
