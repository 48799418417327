import React, { Component } from "react";
import { Modal } from "components/Modal";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import { sizing, spacing } from "styles/sizing";
import colors from "styles/colors";
import { semiBold, regular } from "styles/typography";
import { Formik, Form } from "formik";
import { Button } from "components/Button";
import { FieldError } from "components/FormField";
import posed, { PoseGroup } from "react-pose";
import { ApplicationSent } from "components/illustrations";
import { TextEditor } from "components/TextEditor";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: ${sizing(18)};
  color: ${colors.blackRock};
  ${semiBold};
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const AnimatedWrapper = posed.div({
  enter: {
    opacity: 1,
    scale: 1,
    duration: 400
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    duration: 400
  }
});

const SubmittedTitle = styled.h1`
  font-size: ${sizing(30)};
  color: ${colors.blackRock};
  ${regular};
  text-align: center;
  padding: ${spacing(4)} 0 ${spacing(1)} 0;
  margin: 0;
`;

const ImageContainer = styled.div`
  width: 250px;
  margin: 0 auto;
  padding-bottom: ${spacing(2)};
`;

export default class ConsultantContact extends Component {
  state = {
    isFormSubmitted: false
  };
  render() {
    const {
      User: { id }
    } = this.props;

    const consultantId = this.props.match.params.id;
    return (
      <Modal
        isOpen
        onRequestClose={() =>
          this.props.history.push(`/consultants/${consultantId}`)
        }
      >
        <PoseGroup>
          {!this.state.isFormSubmitted ? (
            <AnimatedWrapper key="form">
              <Wrapper>
                <Title>What would you like to say?</Title>
                <Mutation mutation={createConversation}>
                  {mutation => (
                    <Formik
                      initialValues={{ value: "" }}
                      onSubmit={values => {
                        mutation({
                          variables: {
                            value: values.value,
                            sender: id,
                            recipient: consultantId,
                            time: new Date().toISOString()
                          }
                        });
                        this.setState({ isFormSubmitted: true });
                      }}
                    >
                      {({ isValid, values, setFieldValue, isSubmitting }) => (
                        <Form>
                          <TextEditor
                            defaultValue={values.value}
                            onChange={value => setFieldValue("value", value)}
                          />
                          <FieldError name="value" />
                          <ButtonContainer>
                            <Button
                              style={{ marginRight: spacing(1) }}
                              secondary
                              onClick={() =>
                                this.props.history.push(
                                  `/consultants/${consultantId}`
                                )
                              }
                            >
                              Cancel
                            </Button>
                            <Button
                              style={{ marginLeft: spacing(1) }}
                              disabled={!isValid || isSubmitting}
                              type="submit"
                              primary
                            >
                              Start chat
                            </Button>
                          </ButtonContainer>
                        </Form>
                      )}
                    </Formik>
                  )}
                </Mutation>
              </Wrapper>
            </AnimatedWrapper>
          ) : (
            <AnimatedWrapper key="result">
              <SubmittedTitle>Your message was sent.</SubmittedTitle>
              <ImageContainer>
                <ApplicationSent dark />
              </ImageContainer>
              <Button
                primary
                onClick={() =>
                  this.props.history.push(`/consultants/${consultantId}`)
                }
              >
                Close
              </Button>
            </AnimatedWrapper>
          )}
        </PoseGroup>
      </Modal>
    );
  }
}

const createConversation = gql`
  mutation($value: String!, $sender: ID!, $recipient: ID!, $time: DateTime!) {
    createConversation(
      data: {
        lastMessageAt: $time
        sender: { connect: { id: $sender } }
        recipient: { connect: { id: $recipient } }
        messages: {
          create: { user: { connect: { id: $sender } }, value: $value }
        }
        type: SUPPORT
      }
    ) {
      id
    }
  }
`;
