import React, { Component, Fragment } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import { Loader } from "components/Loader";
import { regular, semiBold } from "styles/typography";
import colors from "styles/colors";
import { sizing, spacing } from "styles/sizing";
import { NotFound } from "components/illustrations";
import { isTabletAndUp } from "styles/media";
import { distanceInWordsToNow } from "date-fns";
import { PageLayout, PageHeader } from "components/PageLayout";
import { PageTitle } from "components/PageTitle";
import {
  SummaryTile,
  SummaryImage,
  SummaryDetail,
  SummaryTitle,
  SummaryDate
} from "components/Summary";
import Helmet from "react-helmet";

const Applications = styled.div`
  width: 100%;
  flex: 1;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  ${regular};
  color: ${colors.liquorice};
  font-size: ${sizing(30)};
  text-align: center;
  margin: 0;
  padding: ${spacing(3)} 0 ${spacing()} 0;
`;

const Info = styled.span`
  display: block;
  ${semiBold};
  color: ${colors.liquorice};
  font-size: ${sizing(14)};
  text-align: center;
  margin: 0;
  padding-bottom: ${spacing()};
`;

const IllustrationContainer = styled.div`
  padding: ${spacing(2)} ${spacing(5)};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export class Messages extends Component {
  state = {
    allEntitiesRecieved: false
  };

  getSupportChat = (conversation, index) => (
    <SummaryTile
      key={`conversation--${index}`}
      to={`/messages/${conversation.id}`}
    >
      <SummaryImage
        isSmall
        url="https://s3-eu-west-1.amazonaws.com/cc-app-uploads/logo.png"
      />
      <SummaryDetail>
        <SummaryTitle isBold={conversation.messages[0]}>Core Team</SummaryTitle>
        <SummaryDate isBold={conversation.messages[0]}>
          {`${distanceInWordsToNow(conversation.lastMessageAt)} ago`}
        </SummaryDate>
      </SummaryDetail>
    </SummaryTile>
  );

  getInviteChat = (conversation, index) => (
    <SummaryTile
      key={`conversation--${index}`}
      to={`/messages/${conversation.id}`}
    >
      <SummaryImage isSmall url={conversation.organisation.logo} />

      <SummaryDetail>
        <SummaryTitle isBold={conversation.messages[0]}>
          {conversation.organisation.name}
        </SummaryTitle>
        <SummaryDate isBold={conversation.messages[0]}>
          {`${distanceInWordsToNow(conversation.lastMessageAt)} ago`}
        </SummaryDate>
      </SummaryDetail>
    </SummaryTile>
  );

  getApplicationChat = (conversation, index) => (
    <SummaryTile
      key={`conversation--${index}`}
      to={`/messages/${conversation.id}`}
    >
      <SummaryImage isSmall url={conversation.organisation.logo} />
      <SummaryDetail>
        <SummaryTitle isBold={conversation.messages[0]}>
          {conversation.organisation.name}
        </SummaryTitle>
        <SummaryDate isBold={conversation.messages[0]}>
          {`${distanceInWordsToNow(conversation.lastMessageAt)} ago`}
        </SummaryDate>
      </SummaryDetail>
    </SummaryTile>
  );

  getRoleChat = (conversation, index) => (
    <SummaryTile
      key={`conversation--${index}`}
      to={`/messages/${conversation.id}`}
    >
      <SummaryImage isSmall url={conversation.recipient.avatar} />
      <SummaryDetail>
        <SummaryTitle isBold={conversation.messages[0]}>
          {`${conversation.sender.first_names} ${
            conversation.sender.last_name
          }`}
        </SummaryTitle>
        <SummaryDate isBold={conversation.messages[0]}>
          {`${distanceInWordsToNow(conversation.lastMessageAt)} ago`}
        </SummaryDate>
      </SummaryDetail>
    </SummaryTile>
  );

  getAnnouncementChat = (conversation, index) => (
    <SummaryTile
      key={`conversation--${index}`}
      to={`/messages/${conversation.id}`}
    >
      <SummaryImage
        isSmall
        url="https://s3-eu-west-1.amazonaws.com/cc-app-uploads/logo.png"
      />
      <SummaryDetail>
        <SummaryTitle isBold={conversation.messages[0]}>Core Team</SummaryTitle>
        <SummaryDate isBold={conversation.messages[0]}>
          {`${distanceInWordsToNow(conversation.lastMessageAt)} ago`}
        </SummaryDate>
      </SummaryDetail>
    </SummaryTile>
  );

  getMessageChat = (conversation, index) => (
    <SummaryTile
      key={`conversation--${index}`}
      to={`/messages/${conversation.id}`}
    >
      <SummaryImage
        isSmall
        url={
          this.props.User.id === conversation.recipient.id
            ? conversation.sender.avatar
            : conversation.recipient.avatar
        }
      />
      <SummaryDetail>
        <SummaryTitle isBold={conversation.messages[0]}>
          {this.props.User.id === conversation.recipient.id
            ? `${conversation.sender.first_names} ${
                conversation.sender.last_name
              }`
            : `${conversation.recipient.first_names} ${
                conversation.recipient.last_name
              }`}
        </SummaryTitle>
        <SummaryDate isBold={conversation.messages[0]}>
          {`${distanceInWordsToNow(conversation.lastMessageAt)} ago`}
        </SummaryDate>
      </SummaryDetail>
    </SummaryTile>
  );

  getType = (conversation, index) =>
    ({
      SUPPORT: () => this.getSupportChat(conversation, index),
      INVITE: () => this.getInviteChat(conversation, index),
      APPLICATION: () => this.getApplicationChat(conversation, index),
      ROLE: () => this.getRoleChat(conversation, index),
      ANNOUNCEMENT: () => this.getAnnouncementChat(conversation, index),
      MESSAGE: () => this.getMessageChat(conversation, index)
    }[conversation.type]());

  getFilters = () => {
    const {
      User: { id, role }
    } = this.props;
    if (role === "ADMIN") {
      return {};
    }

    return {
      OR: [
        { recipient: { id: id } },
        { sender: { id: id } },
        {
          organisation: {
            OR: [{ owner: { id: id } }, { managers_some: { id: id } }]
          }
        }
      ]
    };
  };

  render() {
    const { allEntitiesRecieved } = this.state;
    const {
      User: { id }
    } = this.props;
    return (
      <Query
        query={query}
        variables={{
          skip: 0,
          filter: this.getFilters(),
          user: id
        }}
        fetchPolicy={"cache-and-network"}
        onCompleted={data => {
          if (!allEntitiesRecieved && data.conversations.length < 20) {
            this.setState({
              allEntitiesRecieved: true
            });
          }
        }}
      >
        {({ loading, error, data, fetchMore }) => {
          if (
            data.conversations &&
            data.conversations[0] &&
            isTabletAndUp() &&
            this.props.match.isExact
          ) {
            this.props.history.push(`/messages/${data.conversations[0].id}`);
          }

          return (
            <PageLayout
              isDual
              RightAligned
              header={
                <PageHeader>
                  <Helmet>
                    <title>Messages - Core Consultants</title>
                  </Helmet>
                  <PageTitle>Messages</PageTitle>
                </PageHeader>
              }
              onScrollBottom={() => {
                if (!allEntitiesRecieved) {
                  fetchMore({
                    variables: {
                      skip: data.conversations.length
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult || loading) return prev;

                      if (fetchMoreResult.conversations.length < 20) {
                        this.setState({
                          allEntitiesRecieved: true
                        });
                      }

                      return Object.assign({}, prev, {
                        conversations: [
                          ...prev.conversations,
                          ...fetchMoreResult.conversations
                        ]
                      });
                    }
                  });
                }
              }}
            >
              <Applications>
                {loading && !data && !data.conversations && <Loader />}
                {data.conversations && !data.conversations.length && (
                  <Fragment>
                    <Title>No results here</Title>
                    <Info>Try a different set of key words</Info>
                    <IllustrationContainer>
                      <NotFound />
                    </IllustrationContainer>
                  </Fragment>
                )}
                {data.conversations &&
                  data.conversations.length &&
                  data.conversations.map((conversation, index) =>
                    this.getType(conversation, index)
                  )}
              </Applications>
            </PageLayout>
          );
        }}
      </Query>
    );
  }
}

const query = gql`
  query($skip: Int, $filter: ConversationWhereInput!, $user: ID!) {
    conversations(
      first: 20
      skip: $skip
      where: $filter
      orderBy: lastMessageAt_DESC
    ) {
      id
      lastMessageAt
      sender {
        id
        first_names
        last_name
        avatar
      }
      recipient {
        id
        first_names
        last_name
        avatar
      }
      role {
        id
        title
      }
      type
      accepted
      rejected
      manager
      organisation {
        id
        name
        logo
      }
      messages(
        last: 1
        where: { user: { id_not: $user }, read_reciept: false }
      ) {
        id
        value
      }
    }
  }
`;
