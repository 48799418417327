import React from "react";
import { node, number } from "prop-types";

export const Icon = ({ width, height, children, ...props }) => (
  <svg width={width} height={height} {...props}>
    <g>{children}</g>
  </svg>
);

Icon.propTypes = {
  children: node.isRequired,
  width: number,
  height: number
};

Icon.defaultProps = {
  width: 16,
  height: 16
};
