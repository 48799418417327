import React from "react";
import PropTypes from "prop-types";

import {
  ProfileIcon,
  RolesIcon,
  ChatIcon,
  SettingsIcon,
  DashboardIcon,
  ConsultantsIcon,
  OrganisationIcon
} from "components/icons";
import { MenuItem, MenuItemContainer, MenuText, Badge } from "./style";

const icons = {
  ProfileIcon: ProfileIcon,
  RolesIcon: RolesIcon,
  ChatIcon: ChatIcon,
  SettingsIcon: SettingsIcon,
  DashboardIcon: DashboardIcon,
  ConsultantsIcon: ConsultantsIcon,
  OrganisationIcon: OrganisationIcon
};
function getIcon(icon) {
  return icons[icon];
}

export const NavigationItem = ({ icon, text, to, badge }) => {
  const IconTag = getIcon(icon);

  return (
    <MenuItemContainer>
      <MenuItem activeClassName="is-active" title={text} to={to}>
        <IconTag width={20} height={20} />
        <MenuText>{text}</MenuText>
        {badge && <Badge>{badge}</Badge>}
      </MenuItem>
    </MenuItemContainer>
  );
};

NavigationItem.propTypes = {
  icon: PropTypes.oneOf([
    "ProfileIcon",
    "RolesIcon",
    "ChatIcon",
    "SettingsIcon",
    "DashboardIcon",
    "ConsultantsIcon",
    "OrganisationIcon"
  ]),
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  badge: PropTypes.string
};
