import React from "react";
import { Icon } from "./Icon";

export const RolesIcon = props => (
  <Icon {...props} viewBox="0 0 22 18">
    <path
      fill="currentcolor"
      fillRule="nonzero"
      d="M1 11h20V4H1v7zm0 1v5h20v-5H1zm6-9h8V1H7v2zm0 1h8-8zm9-1h5.1a.9.9 0 0 1 .9.9v13.2a.9.9 0 0 1-.9.9H.9a.9.9 0 0 1-.9-.9V3.9A.9.9 0 0 1 .9 3H6V.72c0-.398.403-.72.9-.72h8.2c.497 0 .9.322.9.72V3z"
    />
  </Icon>
);
