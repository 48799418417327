import React, { Component } from "react";
import { Modal } from "components/Modal";
import { sizing, spacing } from "styles/sizing";
import colors from "styles/colors";
import { regular, semiBold } from "styles/typography";
import styled from "styled-components";
import posed, { PoseGroup } from "react-pose";
import { Query, Mutation } from "react-apollo";
import { ApplicationSent } from "components/illustrations";
import { Button } from "components/Button";
import { Field, FieldLabel } from "components/FormField";
import gql from "graphql-tag";
import * as Yup from "yup";
import { TagInput } from "components/TagInput";
import { Autocomplete } from "components/Autocomplete";
import { Form } from "components/Form";
import { experienceLevels } from "constants/experienceLevels";
import { locations } from "constants/locations";
import { Loader } from "components/Loader";
import { ToggleSwitch } from "components/ToggleSwitch";
import { TextEditor } from "components/TextEditor";
import { OrganisationInput } from "components/OrganisationInput";
import { ConfirmationDialog } from "components/ConfirmationDialog";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 100%;
`;

const Title = styled.h1`
  font-size: ${sizing(18)};
  color: ${colors.blackRock};
  ${semiBold};
  text-align: center;
`;

const AnimatedWrapper = posed.div({
  enter: {
    opacity: 1,
    scale: 1,
    duration: 400
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    duration: 400
  }
});

const SubmittedTitle = styled.h1`
  font-size: ${sizing(30)};
  color: ${colors.blackRock};
  ${regular};
  text-align: center;
  padding: ${spacing(4)} 0 ${spacing(1)} 0;
  margin: 0;
`;

const ImageContainer = styled.div`
  width: 250px;
  margin: 0 auto;
  padding-bottom: ${spacing(2)};
`;

const Label = styled(FieldLabel)`
  color: ${colors.blackRock};
  display: block;
  padding: ${spacing(3)} 0 ${spacing(1)};
  text-align: left;
  width: 100%;
`;

const Header = styled.header`
  width: 100%;
  padding: 0 ${spacing(2)};
  border-bottom: 1px solid ${colors.snuff};
`;

const Body = styled.section`
  padding: ${spacing(2)} 0;
  width: 100%;
  overflow: scroll;
  border-bottom: 1px solid ${colors.snuff};
`;

export class CreateOrEditRole extends Component {
  state = {
    isFormSubmitted: false
  };

  render() {
    const { isFormSubmitted } = this.state;
    const { User, history, match } = this.props;
    const formType = this.props.type;

    return (
      <Modal
        isOpen
        onRequestClose={() =>
          ConfirmationDialog({
            message: "Do you wish to close all data will be lost?",
            primaryText: "Close",
            primaryAction: () =>
              formType === "edit"
                ? history.push(`/dashboard/${match.params.id}`)
                : history.push("/dashboard"),
            secondaryText: "Cancel",
            secondaryAction: () => null
          })
        }
      >
        <PoseGroup>
          {!isFormSubmitted ? (
            <AnimatedWrapper key="form">
              <Wrapper>
                <Header>
                  <Title>
                    {formType === "create" && "Create Role"}
                    {formType === "duplicate" && "Duplicate Role"}
                    {formType === "edit" && "Edit Role"}
                  </Title>
                </Header>
                <Query
                  skip={!match.params.id || match.params.id === "create"}
                  variables={{ id: match.params.id }}
                  query={query}
                >
                  {({ data, loading, error }) => {
                    if (formType !== "create" && (!data || !data.role)) {
                      return <Loader />;
                    }
                    return (
                      <Mutation mutation={createRole}>
                        {mutation => (
                          <Mutation mutation={updateRole}>
                            {updateMutation => (
                              <Form
                                isInitialValid={
                                  data && data.role && formType === "duplicate"
                                }
                                initialValues={
                                  data && data.role
                                    ? {
                                        ...data.role,
                                        ...{
                                          experience: {
                                            id: data.role.experience,
                                            value:
                                              experienceLevels[
                                                data.role.experience
                                              ]
                                          },
                                          location: {
                                            id: data.role.location,
                                            value: locations[data.role.location]
                                          },
                                          organisation: data.organisation
                                            ? {
                                                id: data.organisation.id,
                                                value: data.organisation.name
                                              }
                                            : undefined
                                        }
                                      }
                                    : {
                                        title: "",
                                        day_rate: 0,
                                        description: "",
                                        full_time: true,
                                        location: "",
                                        experience: "",
                                        tags: []
                                      }
                                }
                                validationSchema={Yup.object().shape({
                                  title: Yup.string().required(
                                    "Please include a job title"
                                  ),
                                  day_rate: Yup.number()
                                    .min(1, "please include a day rate")
                                    .required("please include a day Rate"),
                                  description: Yup.string().required(
                                    "Please include a description"
                                  ),
                                  experience: Yup.object()
                                    .shape({
                                      id: Yup.string(),
                                      value: Yup.string()
                                    })
                                    .required(
                                      "Please include an experience level"
                                    ),
                                  location: Yup.object()
                                    .shape({
                                      id: Yup.string(),
                                      value: Yup.string()
                                    })
                                    .required("Please include a location"),
                                  tags: Yup.array()
                                    .of(
                                      Yup.object().shape({
                                        id: Yup.string(),
                                        value: Yup.string()
                                      })
                                    )
                                    .min(
                                      5,
                                      "You have to have a minimum of 5 tags"
                                    )
                                })}
                                onSubmit={values => {
                                  if (
                                    formType === "create" ||
                                    formType === "duplicate"
                                  ) {
                                    mutation({
                                      variables: {
                                        data: {
                                          title: values.title.toLowerCase(),
                                          day_rate: values.day_rate,
                                          experience: values.experience.id,
                                          location: values.location.id,
                                          description: values.description,
                                          closed: false,
                                          full_time: values.full_time,
                                          tags: {
                                            connect: values.tags
                                              .filter(i => !i.create)
                                              .map(i => ({
                                                id: i.id
                                              })),
                                            create: values.tags
                                              .filter(i => i.create)
                                              .map(i => ({
                                                value: i.value
                                              }))
                                          },
                                          client: {
                                            connect: {
                                              id: User.id
                                            }
                                          },
                                          organisation: values.organisation
                                            ? {
                                                connect: {
                                                  id: values.organisation.id
                                                }
                                              }
                                            : null
                                        }
                                      }
                                    });
                                  }

                                  if (formType === "edit") {
                                    updateMutation({
                                      variables: {
                                        id: data.role.id,
                                        data: {
                                          title: values.title.toLowerCase(),
                                          day_rate: values.day_rate,
                                          experience: values.experience.id,
                                          location: values.location.id,
                                          description: values.description,
                                          full_time: values.full_time,
                                          tags: {
                                            connect: values.tags
                                              .filter(i => !i.create)
                                              .map(i => ({
                                                id: i.id
                                              })),
                                            disconnect: data.role.tags
                                              .filter(i =>
                                                values.tags.every(
                                                  p => p.id !== i.id
                                                )
                                              )
                                              .map(i => ({ id: i.id })),
                                            create: values.tags
                                              .filter(i => i.create)
                                              .map(i => ({
                                                value: i.value
                                              }))
                                          },
                                          organisation: values.organisation
                                            ? {
                                                connect: {
                                                  id: values.organisation.id
                                                }
                                              }
                                            : null
                                        }
                                      }
                                    });
                                  }
                                  this.setState({ isFormSubmitted: true });
                                }}
                                onReset={() =>
                                  formType === "edit"
                                    ? history.push(
                                        `/dashboard/${match.params.id}`
                                      )
                                    : history.push("/dashboard")
                                }
                              >
                                {(errors, values, setFieldValue, touched) => (
                                  <Body>
                                    <Label name="title">Job Title</Label>
                                    <Field
                                      name="title"
                                      error={errors.title && touched.title}
                                    />
                                    <Label name="day_rate">Day Rate</Label>
                                    <Field
                                      name="day_rate"
                                      type="number"
                                      error={
                                        errors.day_rate && touched.day_rate
                                      }
                                    />
                                    <Label name="experience">
                                      Experience Level
                                    </Label>
                                    <Autocomplete
                                      isSingle
                                      items={Object.keys(experienceLevels).map(
                                        key => ({
                                          id: key,
                                          isActive:
                                            key === values.experience.id,
                                          value: experienceLevels[key]
                                        })
                                      )}
                                      onChange={value =>
                                        setFieldValue("experience", value)
                                      }
                                      error={
                                        errors.experience && touched.experience
                                      }
                                    />
                                    <Label name="location">Location</Label>
                                    <Autocomplete
                                      isSingle
                                      style={{ width: "100%" }}
                                      items={Object.keys(locations).map(
                                        key => ({
                                          id: key,
                                          isActive: key === values.location.id,
                                          value: locations[key]
                                        })
                                      )}
                                      onChange={value =>
                                        setFieldValue("location", value)
                                      }
                                      error={
                                        errors.location && touched.location
                                      }
                                    />
                                    <Label name="tags">Tags</Label>
                                    <TagInput
                                      name="tags"
                                      query={queryTags}
                                      values={values.tags}
                                    />
                                    <Label name="tags">Organisation</Label>
                                    <OrganisationInput
                                      isSingle
                                      name="organisation"
                                      id={User.id}
                                      query={queryOrganisations}
                                      values={values.organisation}
                                      onChange={value =>
                                        setFieldValue("organisation", value)
                                      }
                                    />
                                    <Label name="full_time">full Time</Label>
                                    <ToggleSwitch
                                      value={values.full_time}
                                      activeLabel="Full Time"
                                      inactiveLabel="Part Time"
                                      onChange={value =>
                                        setFieldValue("full_time", value)
                                      }
                                    />
                                    <Label name="description">
                                      Description
                                    </Label>
                                    <TextEditor
                                      defaultValue={values.description}
                                      onChange={value =>
                                        setFieldValue("description", value)
                                      }
                                    />
                                  </Body>
                                )}
                              </Form>
                            )}
                          </Mutation>
                        )}
                      </Mutation>
                    );
                  }}
                </Query>
              </Wrapper>
            </AnimatedWrapper>
          ) : (
            <AnimatedWrapper key="result">
              <SubmittedTitle>
                {formType === "create" && "Your role was created"}
                {formType === "duplicate" && "Your role was created"}
                {formType === "edit" && "Your role was updated"}
              </SubmittedTitle>
              <ImageContainer>
                <ApplicationSent dark />
              </ImageContainer>
              <Button
                primary
                type="button"
                onClick={() =>
                  formType === "edit"
                    ? history.push(`/dashboard/${match.params.id}`)
                    : history.push("/dashboard")
                }
              >
                Close
              </Button>
            </AnimatedWrapper>
          )}
        </PoseGroup>
      </Modal>
    );
  }
}

const createRole = gql`
  mutation createRole($data: RoleCreateInput) {
    createRole(data: $data) {
      id
      title
      description
      closed
      experience
      location
      tags {
        id
        value
      }
      day_rate
      client {
        id
      }
    }
  }
`;

const queryTags = gql`
  query tags($search: String!) {
    tags(where: { value_contains: $search }) {
      id
      value
    }
  }
`;

const queryOrganisations = gql`
  query organisations($search: String!, $id: ID!) {
    organisations(
      where: {
        name_contains: $search
        OR: [
          { owner: { id: $id } }
          { members_some: { id: $id } }
          { managers_some: { id: $id } }
        ]
      }
    ) {
      id
      name
      logo
    }
  }
`;

const query = gql`
  query($id: ID!) {
    role(where: { id: $id }) {
      id
      title
      description
      day_rate
      location
      experience
      full_time
      closed
      tags {
        id
        value
      }
      organisation {
        id
        name
      }
    }
  }
`;

const updateRole = gql`
  mutation updateRole($id: ID!, $data: RoleUpdateInput!) {
    updateRole(where: { id: $id }, data: $data) {
      id
      closed
    }
  }
`;
