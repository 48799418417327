import React, { Fragment, Component } from "react";
import {
  Wrapper,
  Body,
  FloatingButton,
  Shadow,
  BackButton,
  Footer
} from "./style";
import { throttle } from "lodash";
import { Button } from "components/Button";
import { ArrowIcon } from "components/icons";

export class PageLayout extends Component {
  componentDidMount() {
    if (this.props.subscribeToMore) {
      this.props.subscribeToMore();
    }
    if (this.scrollContainer) {
      if (this.props.onScrollBottom) {
        this.scrollContainer.addEventListener("scroll", this.handleThrottle);
      }

      if (this.props.onScrollTop && this.props.items) {
        setTimeout(() => {
          if (this.scrollContainer) {
            this.scrollContainer.scrollTo({
              top:
                this.scrollContainer.clientHeight +
                this.scrollContainer.scrollHeight
            });
            this.scrollContainer.addEventListener(
              "scroll",
              this.handleTopThrottle
            );
          }
        }, 600);
      }
    }
  }

  componentWillUnmount() {
    if (this.props.onScrollBottom) {
      this.scrollContainer.removeEventListener("scroll", this.handleThrottle);
    }
    if (this.props.onScrollTop && this.props.items) {
      this.scrollContainer.removeEventListener(
        "scroll",
        this.handleTopThrottle
      );
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.onScrollTop && this.props.items) {
      const { scrollHeight, clientHeight, scrollTop } = this.scrollContainer;
      const buffer = 50;
      this.pinToBottom = clientHeight + scrollTop + buffer >= scrollHeight;

      if (prevProps.items.length < this.props.items.length) {
        return (
          this.scrollContainer.scrollHeight - this.scrollContainer.scrollTop
        );
      }
      return null;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.onScrollTop && this.props.items) {
      if (this.pinToBottom) {
        this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
      }
      if (snapshot !== null) {
        this.scrollContainer.scrollTop =
          this.scrollContainer.scrollHeight - snapshot;
      }
    }
  }

  handleScrollTop = () => {
    if (this.scrollContainer && this.scrollContainer.scrollTop < 50) {
      this.props.onScrollTop();
    }
  };

  handleScrollBottom = () => {
    if (
      this.scrollContainer &&
      this.scrollContainer.scrollHeight -
        (this.scrollContainer.scrollTop + this.scrollContainer.clientHeight) <
        50
    ) {
      this.props.onScrollBottom();
    }
  };

  handleTopThrottle = throttle(this.handleScrollTop, 1000, { leading: true });

  handleThrottle = throttle(this.handleScrollBottom, 1000, { leading: false });

  render() {
    const {
      header,
      children,
      isDual,
      leftAligned,
      rightAligned,
      backLink,
      footer
    } = this.props;
    return (
      <Wrapper
        innerRef={ref => (this.scrollContainer = ref)}
        isDual={isDual}
        leftAligned={leftAligned}
        rightAligned={rightAligned}
      >
        {backLink && (
          <BackButton to={backLink}>
            <ArrowIcon rotate={180} /> Back
          </BackButton>
        )}
        {header}
        <Shadow />
        <Body
          isDual={isDual}
          leftAligned={leftAligned}
          rightAligned={rightAligned}
        >
          {children}
        </Body>
        {footer && <Footer>{footer}</Footer>}
      </Wrapper>
    );
  }
}

export const PageHeader = ({ children }) => <Fragment>{children}</Fragment>;

export const HeaderButton = ({ children, ...props }) => (
  <FloatingButton>
    <Button {...props}>{children}</Button>
  </FloatingButton>
);
