import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import "react-datepicker/dist/react-datepicker.css";
import "styles/global";
import Modal from "react-modal";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Login } from "containers/Login";
import { Signup } from "containers/Signup";
import { ClientLogin } from "containers/ClientLogin";
import { ForgotPassword } from "containers/ForgotPassword";
import { client } from "./client";
import { GetStarted } from "containers/getStarted";
import Helmet from "react-helmet";
import auth0Client from "auth/authClient";
import Callback from "containers/Callback";

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

class Root extends Component {
  async componentDidMount() {
    try {
      await auth0Client.silentAuth();
      this.forceUpdate();
    } catch (err) {
      if (err.error !== "login_required") console.log(err.error);
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Route
          path="*"
          render={props => (
            <Fragment>
              <Helmet>
                <title>Core Consultants</title>
              </Helmet>
              <ApolloProvider client={client(props)}>
                <Route exact path="/callback" component={Callback} />
                {!auth0Client.isAuthenticated() && (
                  <Switch>
                    <Route path="/" component={Login} exact />

                    <Route path="/signup" component={Signup} exact />
                    <Route path="/login" component={ClientLogin} exact />
                    <Route
                      path="/forgot-password"
                      component={ForgotPassword}
                      exact
                    />
                    <Route exact path="/get-started" component={GetStarted} />
                    <Redirect to={"/"} />
                  </Switch>
                )}
                {auth0Client.isAuthenticated() && (
                  <Switch>
                    <Route path="/" component={App} />
                    <Redirect to={"/dashboard"} />
                  </Switch>
                )}
              </ApolloProvider>
            </Fragment>
          )}
        />
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (process.env.NODE_ENV === "production") {
  serviceWorker.register();
}
