import React, { Component, Fragment } from "react";
import { Modal } from "components/Modal";
import styled from "styled-components";
import { ArrowIcon } from "components/icons";
import "rc-slider/assets/index.css";
import posed from "react-pose";
import colors from "styles/colors";
import { Form, Formik, FieldArray } from "formik";
import { spacing, sizing } from "styles/sizing";
import { semiBold } from "styles/typography";
import { Autocomplete } from "components/Autocomplete";
import { CancellableTag } from "components/Tag";
import { Button } from "components/Button";
import { locations } from "constants/locations";

const AnimatingDropdown = posed.div({
  open: {
    height: "auto",
    opacity: 1,
    flip: true
  },
  closed: { height: 0, opacity: 0, flip: true }
});

const AnimatingArrow = posed.div({
  open: { rotate: 90 },
  closed: { rotate: 0 }
});

const DropdownWrapper = styled.div`
  color: ${colors.blackRock};
  border-bottom: 1px solid ${colors.snuff};
`;

const DropdownHeader = styled.div`
  padding: ${spacing(2)} 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-size: ${sizing(12)};
    color: ${colors.palatinateBlue};
    padding: 0 ${spacing(1)};
  }
`;
const DropdownTitle = styled.h3`
  margin: 0;
  flex: 1;
`;

const DropdownBody = styled(AnimatingDropdown)`
  overflow: ${props => (props.pose === "open" ? "visible" : "hidden")};
`;

const DropdownContainer = styled.div`
  padding: ${spacing(2)} 0;
`;

const Dropdown = ({ children, title, state, onClick, active }) => (
  <DropdownWrapper>
    <DropdownHeader onClick={onClick}>
      <DropdownTitle>
        {title}
        {!!active && <span>{`${active} selected`}</span>}
      </DropdownTitle>
      <AnimatingArrow pose={state}>
        <ArrowIcon fill={colors.blackRock} />
      </AnimatingArrow>
    </DropdownHeader>
    <DropdownBody pose={state}>
      <DropdownContainer>{children}</DropdownContainer>
    </DropdownBody>
  </DropdownWrapper>
);

const Container = styled.div`
  border: 1px solid ${colors.snuff};
  padding: ${spacing(2)};
  flex: 1;
`;

const Title = styled.h3`
  padding: ${spacing()} 0;
  display: block;
  font-size: ${sizing(18)};
  ${semiBold};
  margin: 0;
  color: ${colors.blackRock};
  text-align: center;
  pointer-events: none;
`;

const TagContainer = styled.div`
  padding: ${spacing(2)} 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: ${spacing(2)} 0;
`;

export class Filters extends Component {
  state = {
    activeFilter: null
  };

  getDefaultValues = () => {
    const { currentFilters } = this.props;

    const locations =
      typeof currentFilters.locations === "string"
        ? [currentFilters.locations]
        : currentFilters.locations || [];

    return {
      locations: locations.map(key => ({
        id: key,
        value: locations[key]
      }))
    };
  };

  handleActiveFilter = filter => {
    const { activeFilter } = this.state;
    this.setState({
      activeFilter: activeFilter === filter ? null : filter
    });
  };

  render() {
    const { isActive, onRequestClose, onSubmit } = this.props;
    const { activeFilter } = this.state;
    let defaultValues = this.getDefaultValues();
    return (
      <Modal isOpen={isActive} onRequestClose={onRequestClose}>
        <Title>Filter Clients</Title>
        <Formik initialValues={defaultValues} onSubmit={onSubmit}>
          {({ values, setFieldValue }) => (
            <Form>
              <Container>
                <Dropdown
                  title="Locations"
                  key="locations"
                  state={activeFilter === "locations" ? "open" : "closed"}
                  onClick={() => this.handleActiveFilter("locations")}
                  active={values.locations.length}
                >
                  <FieldArray
                    name="locations"
                    render={arrayHelpers => (
                      <Fragment>
                        <Autocomplete
                          name="locations"
                          items={Object.keys(locations).map(key => ({
                            id: key,
                            value: locations[key]
                          }))}
                          onChange={value => arrayHelpers.push(value)}
                        />
                        <TagContainer>
                          {values.locations.map((location, index) => (
                            <CancellableTag
                              onClick={() => arrayHelpers.remove(index)}
                              key={location.id}
                            >
                              {location.value}
                            </CancellableTag>
                          ))}
                        </TagContainer>
                      </Fragment>
                    )}
                  />
                </Dropdown>
              </Container>
              <ButtonContainer>
                <Button
                  type="button"
                  secondary
                  style={{
                    marginRight: spacing()
                  }}
                  onClick={() => {
                    onRequestClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  primary
                  style={{
                    marginLeft: spacing()
                  }}
                >
                  Apply
                </Button>
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}
