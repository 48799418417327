import React, { Component } from "react";
import { Modal } from "components/Modal";
import { PoseGroup } from "react-pose";
import { Button } from "components/Button";
import { ApplicationSent } from "components/illustrations";
import {
  SubmittedTitle,
  ImageContainer,
  AnimatedWrapper,
  Header,
  Wrapper,
  Title,
  Body
} from "./styles";
import { Form, Formik } from "formik";
import {
  FieldLabel,
  FieldWrapper,
  Field,
  FieldError
} from "components/FormField";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import * as Yup from "yup";

export class OrganisationDelete extends Component {
  state = {
    isFormSubmitted: false
  };
  render() {
    const {
      match: {
        params: { id }
      },
      history: { push }
    } = this.props;

    const { isFormSubmitted } = this.state;
    return (
      <Modal isOpen onRequestClose={() => push(`/teams/${id}`)}>
        <PoseGroup>
          {!isFormSubmitted ? (
            <AnimatedWrapper key="form">
              <Wrapper>
                <Header>
                  <Title>Delete Team</Title>
                </Header>
                <Body>
                  <Mutation mutation={mutation}>
                    {mutation => (
                      <Formik
                        initialValues={{
                          confirmation: ""
                        }}
                        onSubmit={values => {
                          mutation({
                            variables: {
                              organisation: id
                            }
                          });
                          this.setState({ isFormSubmitted: true });
                        }}
                        validationSchema={Yup.object().shape({
                          confirmation: Yup.string()
                            .matches(/(delete)/, {
                              excludeEmptyString: true,
                              message: 'Please enter "delete"'
                            })
                            .required('Please enter "delete"')
                        })}
                      >
                        {() => (
                          <Form>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <FieldLabel name="confirmation">
                                Please type "delete" in the field below to
                                confirm you wish to delete this Team, This
                                action cannot be reversed.
                              </FieldLabel>
                              <Field
                                placeholder={"delete"}
                                type="text"
                                name="confirmation"
                              />
                              <FieldError
                                style={{ paddingTop: "16px" }}
                                name="confirmation"
                              />
                            </FieldWrapper>
                            <Button primary type="submit">
                              Delete Team
                            </Button>
                            <Button
                              secondary
                              type="button"
                              onClick={() => push(`/teams/${id}`)}
                            >
                              Cancel
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </Mutation>
                </Body>
              </Wrapper>
            </AnimatedWrapper>
          ) : (
            <AnimatedWrapper key="result">
              <SubmittedTitle>The Team has been deleted</SubmittedTitle>
              <ImageContainer>
                <ApplicationSent dark />
              </ImageContainer>
              <Button primary type="button" onClick={() => push(`/teams`)}>
                Close
              </Button>
            </AnimatedWrapper>
          )}
        </PoseGroup>
      </Modal>
    );
  }
}

const mutation = gql`
  mutation($organisation: ID!) {
    deleteOrganisation(where: { id: $organisation }) {
      id
    }
  }
`;
