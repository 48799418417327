import React, { Component, Fragment } from "react";
import { PageTitle } from "components/PageTitle";
import { PageLayout, PageHeader } from "components/PageLayout";
import { Button } from "components/Button";
import {
  Admin,
  Section,
  SectionTitle,
  Stats,
  Stat,
  StatTitle,
  StatValue,
  StatInner,
  FieldContainer,
  Close
} from "./style";
import { Query, Mutation } from "react-apollo";
import { Loader } from "components/Loader";
import gql from "graphql-tag";
import { FieldArray } from "formik";
import { FieldWrapper, FieldLabel, Field } from "components/FormField";
import { Form } from "components/Form";

export class AdminDashboard extends Component {
  render() {
    return (
      <PageLayout
        autoHeight
        header={
          <PageHeader>
            {[
              <PageTitle key="title" sticky>
                Dashboard
              </PageTitle>
            ]}
          </PageHeader>
        }
      >
        <Section>
          <SectionTitle>Stats</SectionTitle>
          <Stats>
            <Stat>
              <StatInner>
                <StatTitle>Number of Clients</StatTitle>
                <Query query={numberOfClients}>
                  {({ data, loading }) => (
                    <Fragment>
                      {!data && loading && <Loader />}
                      {data && data.usersConnection && (
                        <StatValue>
                          {data.usersConnection.aggregate.count}
                        </StatValue>
                      )}
                    </Fragment>
                  )}
                </Query>
                <StatTitle>Number of Consultants</StatTitle>
                <Query query={numberOfConsultants}>
                  {({ data, loading }) => (
                    <Fragment>
                      {!data && loading && <Loader />}
                      {data && data.usersConnection && (
                        <StatValue>
                          {data.usersConnection.aggregate.count}
                        </StatValue>
                      )}
                    </Fragment>
                  )}
                </Query>
              </StatInner>
            </Stat>
            <Stat>
              <StatInner>
                <StatTitle>Number of Teams with Clients</StatTitle>
                <Query query={organisationsWithClients}>
                  {({ data, loading }) => (
                    <Fragment>
                      {!data && loading && <Loader />}
                      {data && data.organisationsConnection && (
                        <StatValue>
                          {data.organisationsConnection.aggregate.count}
                        </StatValue>
                      )}
                    </Fragment>
                  )}
                </Query>
                <StatTitle>Number of Teams with Consultants</StatTitle>
                <Query query={organisationsWithConsultants}>
                  {({ data, loading }) => (
                    <Fragment>
                      {!data && loading && <Loader />}
                      {data && data.organisationsConnection && (
                        <StatValue>
                          {data.organisationsConnection.aggregate.count}
                        </StatValue>
                      )}
                    </Fragment>
                  )}
                </Query>
              </StatInner>
            </Stat>
            <Stat>
              <StatInner>
                <StatTitle>Number of Roles open</StatTitle>
                <Query query={rolesOpen}>
                  {({ data, loading }) => (
                    <Fragment>
                      {!data && loading && <Loader />}
                      {data && data.rolesConnection && (
                        <StatValue>
                          {data.rolesConnection.aggregate.count}
                        </StatValue>
                      )}
                    </Fragment>
                  )}
                </Query>
                <StatTitle>Number of Roles closed</StatTitle>
                <Query query={rolesClosed}>
                  {({ data, loading }) => (
                    <Fragment>
                      {!data && loading && <Loader />}
                      {data && data.rolesConnection && (
                        <StatValue>
                          {data.rolesConnection.aggregate.count}
                        </StatValue>
                      )}
                    </Fragment>
                  )}
                </Query>
              </StatInner>
            </Stat>
            <Stat>
              <StatInner>
                <StatTitle>Applications for open Roles</StatTitle>
                <Query query={numberOfApplicationsForOpenRoles}>
                  {({ data, loading }) => (
                    <Fragment>
                      {!data && loading && <Loader />}
                      {data && data.conversationsConnection && (
                        <StatValue>
                          {data.conversationsConnection.aggregate.count}
                        </StatValue>
                      )}
                    </Fragment>
                  )}
                </Query>
                <StatTitle>Deleted Accounts</StatTitle>
                <Query query={deletedUsers}>
                  {({ data, loading }) => (
                    <Fragment>
                      {!data && loading && <Loader />}
                      {data && data.usersConnection && (
                        <StatValue>
                          {data.usersConnection.aggregate.count}
                        </StatValue>
                      )}
                    </Fragment>
                  )}
                </Query>
              </StatInner>
            </Stat>
          </Stats>
        </Section>
        <Section>
          <SectionTitle>Rates</SectionTitle>
          <p>
            This is where the rates are set. Zero is not editable, All values
            are the amounts of money that that rate starts at. The rate will be
            set for values between that one and the next highest. You can add as
            many tiers as you wish.
          </p>
          <Query query={getRate}>
            {({ data, loading }) => (
              <Mutation mutation={createRate}>
                {mutation => (
                  <Fragment>
                    {!data && loading && <Loader />}
                    {data && data.rates && (
                      <Form
                        initialValues={{
                          rates: Object.keys(data.rates[0].rate).map(i => ({
                            value: i,
                            percentage: data.rates[0].rate[i]
                          }))
                        }}
                        onSubmit={values => {
                          mutation({
                            variables: {
                              rate: values.rates.reduce((acc, curr) => {
                                acc[curr.value] = curr.percentage;
                                return acc;
                              }, {})
                            }
                          });
                        }}
                      >
                        {(errors, values) => {
                          return (
                            <FieldArray
                              name="rates"
                              render={arrayHelpers => (
                                <Fragment>
                                  {values.rates.map((rate, index) => (
                                    <FieldWrapper key={`rate--${index}`}>
                                      <FieldContainer>
                                        <FieldLabel
                                          name={`rates.${index}.value`}
                                        >
                                          Value
                                        </FieldLabel>
                                        <Field
                                          disabled={index === 0}
                                          type="number"
                                          name={`rates.${index}.value`}
                                        />
                                      </FieldContainer>
                                      <FieldContainer>
                                        <FieldLabel
                                          name={`rates.${index}.percentage`}
                                        >
                                          Percentage
                                        </FieldLabel>
                                        <Field
                                          type="number"
                                          max={100}
                                          name={`rates.${index}.percentage`}
                                        />
                                      </FieldContainer>
                                      {index !== 0 ? (
                                        <Close
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          &times;
                                        </Close>
                                      ) : (
                                        <div style={{ width: "30px" }} />
                                      )}
                                    </FieldWrapper>
                                  ))}
                                  <Button
                                    primary
                                    inline
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        value: 0,
                                        percentage: 0
                                      })
                                    }
                                  >
                                    Add Rate
                                  </Button>
                                </Fragment>
                              )}
                            />
                          );
                        }}
                      </Form>
                    )}
                  </Fragment>
                )}
              </Mutation>
            )}
          </Query>
        </Section>
        <Section>
          <SectionTitle>Actions</SectionTitle>
          <Button
            type="button"
            primary
            onClick={() => this.props.history.push("/dashboard/tag-manager")}
          >
            Open Tag Manager
          </Button>
          <Button
            type="button"
            primary
            onClick={() => this.props.history.push("/dashboard/add-admin")}
          >
            Add Admin
          </Button>
        </Section>
        <Section>
          <SectionTitle>Admins</SectionTitle>
          <Query query={adminQuery}>
            {({ data, loading }) => (
              <Fragment>
                {loading && <Loader />}
                {data &&
                  data.admins &&
                  data.admins.map(admin => (
                    <Admin key={admin.id}>
                      <p>{`${admin.first_names} ${admin.last_name}`}</p>
                      <a href={`tel:${admin.data.phone}`}>{admin.data.phone}</a>
                      <a href={`mailto:${admin.email}`}>{admin.email}</a>
                    </Admin>
                  ))}
              </Fragment>
            )}
          </Query>
        </Section>
      </PageLayout>
    );
  }
}

const adminQuery = gql`
  query {
    admins {
      id
      first_names
      last_name
      email
      data {
        phone
      }
    }
  }
`;

const numberOfClients = gql`
  query {
    usersConnection(where: { role: CLIENT }) {
      aggregate {
        count
      }
    }
  }
`;

const numberOfConsultants = gql`
  query {
    usersConnection(where: { role: CONSULTANT }) {
      aggregate {
        count
      }
    }
  }
`;

const rolesOpen = gql`
  query {
    rolesConnection(where: { closed: false }) {
      aggregate {
        count
      }
    }
  }
`;

const rolesClosed = gql`
  query {
    rolesConnection(where: { closed: true }) {
      aggregate {
        count
      }
    }
  }
`;

const organisationsWithClients = gql`
  query {
    organisationsConnection(
      where: {
        OR: [
          { members_some: { role: CLIENT } }
          { managers_some: { role: CLIENT } }
          { owner: { role: CLIENT } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const organisationsWithConsultants = gql`
  query {
    organisationsConnection(
      where: {
        OR: [
          { members_some: { role: CONSULTANT } }
          { managers_some: { role: CONSULTANT } }
          { owner: { role: CONSULTANT } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const numberOfApplicationsForOpenRoles = gql`
  query {
    conversationsConnection(where: { type: ROLE, role: { closed: false } }) {
      aggregate {
        count
      }
    }
  }
`;

const deletedUsers = gql`
  query {
    usersConnection(where: { deleted: true }) {
      aggregate {
        count
      }
    }
  }
`;

const getRate = gql`
  query {
    rates {
      id
      rate
      updatedBy {
        id
        first_names
        last_name
      }
    }
  }
`;

const createRate = gql`
  mutation($rate: Json!) {
    createRate(data: { rate: $rate }) {
      id
      rate
      updatedBy {
        id
        first_names
        last_name
      }
    }
  }
`;
