import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import auth0Client from "auth/authClient";
import { Loader } from "components/Loader";

class Callback extends Component {
  async componentDidMount() {
    await auth0Client.handleAuthentication();
    this.props.history.replace("/");
  }

  render() {
    return <Loader />;
  }
}

export default withRouter(Callback);
