import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { PageTitle, PageSubTitle } from "components/PageHeader";
import { ArrowIcon } from "components/icons";
import { DeleteButton, Break } from "./styles";
import { Loader } from "components/Loader";
import auth0Client from "auth/authClient";
import { Button } from "components/Button";
import { Flag } from "flag";
import { PageLayout, PageHeader } from "components/PageLayout";
import {
  ContentArea,
  ContentBlock,
  ContentColumn
} from "components/ContentBlock";
import { StyledProgressBar } from "containers/Consultant";
import { FloatingButton } from "components/PageLayout/style";
import { locations } from "constants/locations";
import {
  NaturalBlock,
  NaturalIntro,
  NaturalData
} from "components/NaturalBlocks";
import Helmet from "react-helmet";
import {
  DocumentCard,
  DocumentName,
  DocumentAction,
  DocumentText,
  DocumentDescription
} from "components/DocumentCard";
import { format } from "date-fns";

export const Settings = props => (
  <Query query={query} fetchPolicy={"cache-and-network"}>
    {({ loading, data, error }) => {
      return (
        <PageLayout
          autoHeight
          header={
            <PageHeader>
              <Helmet>
                <title>Settings - Core Consultants</title>
              </Helmet>
              <PageTitle>Settings</PageTitle>
            </PageHeader>
          }
        >
          {data && data.User ? (
            <ContentArea>
              <ContentColumn>
                <ContentBlock>
                  <PageSubTitle style={{ paddingBottom: "16px" }}>
                    Account Details
                  </PageSubTitle>
                  <FloatingButton
                    secondary
                    onClick={() => props.history.push("/settings/edit")}
                  >
                    Edit
                  </FloatingButton>
                  <Flag name="clientOrAdmin">
                    <StyledProgressBar
                      profile_completness={100}
                      image={data.User.avatar}
                    />

                    <NaturalBlock>
                      <NaturalIntro>Your name is:</NaturalIntro>
                      <NaturalData style={{ textTransform: "capitalize" }}>
                        {data.User.first_names} {data.User.last_name}
                      </NaturalData>
                    </NaturalBlock>

                    <NaturalBlock>
                      <NaturalIntro>Your location is:</NaturalIntro>
                      <NaturalData>
                        {locations[data.User.data.location]}
                      </NaturalData>
                    </NaturalBlock>

                    <NaturalBlock>
                      <NaturalIntro>Your job title is:</NaturalIntro>
                      <NaturalData>{data.User.data.job_title}</NaturalData>
                    </NaturalBlock>
                  </Flag>
                  <NaturalBlock>
                    <NaturalIntro>Your phone number is:</NaturalIntro>
                    <NaturalData>{data.User.data.phone}</NaturalData>
                  </NaturalBlock>

                  <NaturalBlock>
                    <NaturalIntro>Your email is:</NaturalIntro>
                    <NaturalData>{data.User.email}</NaturalData>
                  </NaturalBlock>
                </ContentBlock>
              </ContentColumn>
              <ContentColumn>
                <ContentBlock>
                  <Button
                    secondary
                    onClick={() => {
                      auth0Client.signOut();
                    }}
                  >
                    Log out
                  </Button>
                  <Break />
                  <PageSubTitle>Change Password</PageSubTitle>
                  <p>
                    Click here to send a password reset email with a link to
                    reset your password.
                  </p>
                  <Button
                    primary
                    onClick={() => {
                      auth0Client.changePassword(
                        {
                          connection: "Username-Password-Authentication",
                          email: data.User.email
                        },
                        err => {
                          if (err) {
                            alert("there was an error please try again");
                          } else {
                            alert("password reset sent");
                          }
                        }
                      );
                    }}
                  >
                    Reset Password
                  </Button>
                  <Break />
                  <PageSubTitle>Help Desk</PageSubTitle>
                  <p>
                    Do you have a question, a bug to report or a suggestion for
                    our service?
                  </p>
                  <Button
                    primary
                    onClick={() => props.history.push("/settings/support")}
                  >
                    Chat with the Core Team
                  </Button>
                  <Break />
                  <DeleteButton
                    transparent
                    onClick={() => props.history.push("/settings/delete")}
                  >
                    <span>Delete my account</span>
                    <ArrowIcon fill="currentcolor" width={24} height={24} />
                  </DeleteButton>
                </ContentBlock>

                <Query
                  query={documentQuery}
                  variables={{
                    client: data.User.role === "CLIENT",
                    consultant: data.User.role === "CONSULTANT",
                    id: data.User.id
                  }}
                >
                  {({ data: documentData }) => {
                    return (
                      <ContentBlock>
                        <PageSubTitle>Documents</PageSubTitle>
                        {!documentData ||
                          (!documentData.documents && <Loader />)}
                        {documentData &&
                          documentData.documents &&
                          documentData.documents.map(document => (
                            <DocumentCard
                              key={document.id}
                              to={`/settings/documents/${document.id}`}
                            >
                              <DocumentName>{document.name}</DocumentName>
                              <DocumentDescription>
                                {document.description}
                              </DocumentDescription>
                              {document.signatures[0] && (
                                <DocumentText>
                                  Document signed on{" "}
                                  {format(
                                    document.signatures[0].createdAt,
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  by {document.signatures[0].legal_name}
                                  {document.signatures[0].company && (
                                    <span>
                                      {" "}
                                      on behalf of{" "}
                                      {document.signatures[0].company}
                                    </span>
                                  )}
                                  .
                                </DocumentText>
                              )}
                              {!document.signatures[0] && (
                                <DocumentAction
                                  primary
                                  onClick={() =>
                                    props.history.push(
                                      `/settings/documents/${document.id}`
                                    )
                                  }
                                >
                                  Sign
                                </DocumentAction>
                              )}
                            </DocumentCard>
                          ))}
                      </ContentBlock>
                    );
                  }}
                </Query>
              </ContentColumn>
            </ContentArea>
          ) : (
            <Loader />
          )}
        </PageLayout>
      );
    }}
  </Query>
);

const query = gql`
  query {
    User {
      id
      email
      first_names
      last_name
      avatar
      role
      data {
        id
        phone
        location
        job_title
      }
    }
  }
`;

const documentQuery = gql`
  query($id: ID!, $client: Boolean, $consultant: Boolean) {
    documents(where: { client: $client, consultant: $consultant }) {
      id
      name
      description
      signatures(where: { user: { id: $id } }) {
        id
        legal_name
        company
        createdAt
      }
    }
  }
`;
