import styled from "styled-components";
import { Button } from "components/Button";
import { spacing, sizing } from "styles/sizing";
import colors from "styles/colors";

export const Break = styled.hr`
  width: 100%;
  margin: ${spacing(3)} 0 ${spacing(3)};
  border-top: 1px solid #dfdeeb;
`;

export const DeleteButton = styled(Button)`
  display: flex;
  align-items: center;
  margin: 0 0 ${spacing(3)};
  padding: 0;
  color: ${colors.liquorice};
  font-size: ${sizing(16)};

  * + * {
    margin-left: ${spacing(2)};
  }
`;
