import React, { Component, Fragment } from "react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Button } from "components/Button";
import { Formik, Form } from "formik";
import { format } from "date-fns";
import { Loader } from "components/Loader";
import { MessageContainer } from "./message";
import {
  Contact,
  Avatar,
  Content,
  DateDisplay,
  Image,
  Footer,
  TopStatusBar
} from "./styles";
import { TextEditor } from "components/TextEditor";
import { ButtonContainer } from "containers/Profile/styles";
import { PageLayout, PageHeader } from "components/PageLayout";
import { PageTitle } from "components/PageTitle";
import { PageSubTitle, PageButton } from "components/PageHeader";
import { Flag } from "flag";

export class Message extends Component {
  state = {
    allMessagesReceived: false
  };

  getType = (conversation, index) =>
    ({
      SUPPORT: () => this.getSupportType(conversation, index),
      INVITE: () => this.getInviteType(conversation, index),
      APPLICATION: () => this.getApplicationType(conversation, index),
      ROLE: () => this.getRoleType(conversation, index),
      ANNOUCEMENT: () => this.getAnnouncementType(conversation, index)
    }[conversation.type]());

  getFlags = type => ({
    support: type === "SUPPORT",
    role: type === "ROLE",
    invite: type === "INVITE",
    application: type === "APPLICATION",
    announcement: type === "ANNOUCEMENT"
  });

  render() {
    const conversationId = this.props.match.params.id;
    const User = this.props.User;
    const { allMessagesReceived } = this.state;
    return (
      <Query
        variables={{ id: conversationId }}
        query={conversationQuery}
        fetchPolicy="cache-and-network"
      >
        {({ data }) => {
          if (!data || !data.conversation) {
            return <Loader />;
          }

          if (data && data.conversation) {
            const conversation = data.conversation;
            const contact =
              conversation.sender &&
              this.props.User.id === conversation.sender.id
                ? conversation.recipient
                : conversation.sender;

            return (
              <Query
                variables={{ id: conversationId, skip: 0 }}
                query={messagesQuery}
                fetchPolicy="cache-and-network"
                onCompleted={data => {
                  if (
                    data.messages &&
                    !allMessagesReceived &&
                    data.messages.length < 16
                  ) {
                    this.setState({
                      allMessagesReceived: true
                    });
                  }
                }}
              >
                {({
                  loading,
                  data: { messages },
                  fetchMore,
                  subscribeToMore
                }) => (
                  <PageLayout
                    backLink="/messages"
                    isDual
                    leftAligned
                    header={
                      <PageHeader>
                        <PageTitle>
                          {conversation.type === "ROLE" &&
                            conversation.role.title}
                          {conversation.type === "SUPPORT" &&
                            "Chat with the Core team"}

                          {conversation.type === "APPLICATION" &&
                            `Application to join 
                              ${conversation.organisation.name}`}

                          {conversation.type === "INVITE" &&
                            `Invite to join ${conversation.organisation.name}`}

                          {conversation.type === "ANNOUNCEMENT" &&
                            `Core Message`}
                        </PageTitle>
                        <PageSubTitle center>
                          {conversation.type === "SUPPORT" && "Core Team"}

                          {conversation.type === "ROLE" &&
                            `${contact.first_names} ${contact.last_name}`}

                          {conversation.type === "APPLICATION" &&
                            contact &&
                            `${contact.first_names} ${contact.last_name}`}

                          {conversation.type === "INVITE" &&
                            `${contact.first_names} ${contact.last_name}`}
                          {conversation.type === "ANNOUCEMENT" &&
                            `Core Consultants`}
                        </PageSubTitle>
                        {conversation.type === "ROLE" && (
                          <PageButton
                            secondary
                            onClick={() => {
                              this.props.history.push(
                                `/roles/${conversation.role.id}`
                              );
                            }}
                          >
                            View Role
                          </PageButton>
                        )}

                        {conversation.type === "APPLICATION" && contact && (
                          <PageButton
                            secondary
                            onClick={() => {
                              this.props.history.push(
                                `/teams/${conversation.organisation.id}`
                              );
                            }}
                          >
                            View Team
                          </PageButton>
                        )}

                        {conversation.type === "INVITE" && (
                          <PageButton
                            secondary
                            onClick={() => {
                              this.props.history.push(
                                `/teams/${conversation.organisation.id}`
                              );
                            }}
                          >
                            View Team
                          </PageButton>
                        )}
                      </PageHeader>
                    }
                    footer={
                      <Fragment>
                        {conversation && conversation.type === "ROLE" && (
                          <Flag name="signed">
                            <Mutation mutation={CreateMessage}>
                              {mutation => (
                                <Formik
                                  initialValues={{
                                    value: ""
                                  }}
                                  onSubmit={(values, { resetForm }) => {
                                    mutation({
                                      variables: {
                                        value: values.value,
                                        conversation: conversation.id,
                                        user: User.id
                                      }
                                    });
                                    resetForm({});
                                  }}
                                >
                                  {({
                                    setFieldValue,
                                    values,
                                    isValid,
                                    isSubmitting
                                  }) => (
                                    <Form style={{ width: "100%" }}>
                                      {!isSubmitting ? (
                                        <TextEditor
                                          messageBox
                                          defaultValue={values.value || ""}
                                          onChange={value =>
                                            setFieldValue("value", value)
                                          }
                                        />
                                      ) : (
                                        <Loader width="80px" />
                                      )}
                                      <Footer>
                                        <Button
                                          type="submit"
                                          disabled={!isValid || isSubmitting}
                                          primary
                                        >
                                          Send message
                                        </Button>
                                      </Footer>
                                    </Form>
                                  )}
                                </Formik>
                              )}
                            </Mutation>
                          </Flag>
                        )}
                        {conversation &&
                          (conversation.type === "SUPPORT" ||
                            conversation.type === "MESSAGE") && (
                            <Mutation mutation={CreateMessage}>
                              {mutation => (
                                <Formik
                                  initialValues={{
                                    value: ""
                                  }}
                                  onSubmit={(values, { resetForm }) => {
                                    mutation({
                                      variables: {
                                        value: values.value,
                                        conversation: conversation.id,
                                        user: User.id
                                      }
                                    });
                                    resetForm({});
                                  }}
                                >
                                  {({
                                    setFieldValue,
                                    values,
                                    isValid,
                                    isSubmitting
                                  }) => (
                                    <Form style={{ width: "100%" }}>
                                      {!isSubmitting ? (
                                        <TextEditor
                                          messageBox
                                          defaultValue={values.value || ""}
                                          onChange={value =>
                                            setFieldValue("value", value)
                                          }
                                        />
                                      ) : (
                                        <Loader width="80px" />
                                      )}
                                      <Footer>
                                        <Button
                                          type="submit"
                                          disabled={!isValid || isSubmitting}
                                          primary
                                        >
                                          Send message
                                        </Button>
                                      </Footer>
                                    </Form>
                                  )}
                                </Formik>
                              )}
                            </Mutation>
                          )}
                      </Fragment>
                    }
                    subscribeToMore={() =>
                      subscribeToMore({
                        document: messageSubscription,
                        variables: { id: conversationId },
                        updateQuery: (prev, { subscriptionData }) => {
                          if (!subscriptionData.data) return prev;
                          const newMessage = subscriptionData.data.message.node;
                          return {
                            messages: [...prev.messages, newMessage]
                          };
                        }
                      })
                    }
                    onScrollTop={() => {
                      if (!allMessagesReceived) {
                        fetchMore({
                          variables: {
                            skip: messages.length
                          },
                          updateQuery: (prev, { fetchMoreResult }) => {
                            if (!fetchMoreResult || loading) return prev;
                            if (fetchMoreResult.messages.length < 16) {
                              this.setState({
                                allMessagesReceived: true
                              });
                            }
                            return Object.assign({}, prev, {
                              messages: [
                                ...fetchMoreResult.messages,
                                ...prev.messages
                              ]
                            });
                          }
                        });
                      }
                    }}
                    items={messages || []}
                  >
                    {conversation && (
                      <Fragment>
                        <Query
                          variables={{ id: conversationId, User: User.id }}
                          query={unreadMessagesQuery}
                          pollInterval={1000}
                        >
                          {({ data }) =>
                            data &&
                            data.messagesConnection &&
                            data.messagesConnection.aggregate.count > 0 ? (
                              <TopStatusBar>{`You have ${
                                data.messagesConnection.aggregate.count
                              } unread messages above`}</TopStatusBar>
                            ) : null
                          }
                        </Query>
                        {!allMessagesReceived && (
                          <div style={{ height: "50px" }}>
                            <Loader width="40px" />
                          </div>
                        )}
                        {messages &&
                          messages.map((message, index) => {
                            if (
                              conversation.type === "ROLE" &&
                              this.props.unsigned
                            ) {
                              return (
                                <Fragment key="unsigned">
                                  <p>
                                    You have documents you need to approve in
                                    your settings before you can message about
                                    roles.
                                  </p>
                                  <Button
                                    primary
                                    onClick={() =>
                                      this.props.history.push("/settings")
                                    }
                                  >
                                    Go to Settings
                                  </Button>
                                </Fragment>
                              );
                            }
                            return (
                              <MessageContainer
                                key={index}
                                read_reciept={message.read_reciept}
                                id={message.id}
                                disabled={
                                  (message.user &&
                                    message.user.id === User.id) ||
                                  message.read_reciept === true
                                }
                              >
                                {message.user && message.user.role !== "ADMIN" && (
                                  <Avatar>
                                    <Image url={message.user.avatar} />
                                  </Avatar>
                                )}
                                {message.user && message.user.role === "ADMIN" && (
                                  <Avatar>
                                    <Image
                                      url={
                                        "https://s3-eu-west-1.amazonaws.com/cc-app-uploads/logo.png"
                                      }
                                    />
                                  </Avatar>
                                )}

                                <Content>
                                  {message.user &&
                                    message.user.role !== "ADMIN" && (
                                      <Contact>
                                        {`${message.user.first_names} ${
                                          message.user.last_name
                                        }`}
                                      </Contact>
                                    )}
                                  {message.user &&
                                    message.user.role === "ADMIN" && (
                                      <Contact>Core Team</Contact>
                                    )}
                                  <DateDisplay>
                                    {format(message.createdAt, "DD/MM HH:mm A")}
                                  </DateDisplay>
                                  <TextEditor
                                    readOnly
                                    defaultValue={message.value}
                                    key={message.id}
                                  />
                                  {conversation.type === "APPLICATION" &&
                                  !conversation.accepted &&
                                  !conversation.rejected ? (
                                    this.props.User.id !==
                                    conversation.sender.id ? (
                                      <Mutation mutation={acceptApplication}>
                                        {mutation => (
                                          <ButtonContainer>
                                            <Button
                                              primary
                                              style={{
                                                marginRight: "8px"
                                              }}
                                              onClick={() =>
                                                mutation({
                                                  variables: {
                                                    data: {
                                                      accepted: true,
                                                      lastMessageAt: new Date().toISOString(),
                                                      messages: {
                                                        create: {
                                                          user: {
                                                            connect: {
                                                              id: User.id
                                                            }
                                                          },
                                                          value:
                                                            "I have accepted this application"
                                                        }
                                                      },
                                                      organisation: {
                                                        update: conversation.manager
                                                          ? {
                                                              managers: {
                                                                connect: {
                                                                  id:
                                                                    conversation
                                                                      .sender.id
                                                                }
                                                              }
                                                            }
                                                          : {
                                                              members: {
                                                                connect: {
                                                                  id:
                                                                    conversation
                                                                      .sender.id
                                                                }
                                                              }
                                                            }
                                                      }
                                                    },
                                                    id: conversation.id
                                                  }
                                                })
                                              }
                                            >
                                              Accept
                                            </Button>
                                            <Button
                                              secondary
                                              style={{
                                                marginLeft: "8px"
                                              }}
                                              onClick={() =>
                                                mutation({
                                                  variables: {
                                                    data: {
                                                      rejected: true,
                                                      lastMessageAt: new Date().toISOString(),
                                                      messages: {
                                                        create: {
                                                          user: {
                                                            connect: {
                                                              id: User.id
                                                            }
                                                          },
                                                          value:
                                                            "I have rejected this application."
                                                        }
                                                      }
                                                    },
                                                    id: conversation.id
                                                  }
                                                })
                                              }
                                            >
                                              Reject
                                            </Button>
                                          </ButtonContainer>
                                        )}
                                      </Mutation>
                                    ) : (
                                      <p>
                                        This application has not been replied to
                                        yet.
                                      </p>
                                    )
                                  ) : null}

                                  {conversation.type === "INVITE" &&
                                  !conversation.accepted &&
                                  !conversation.rejected ? (
                                    this.props.User.id !==
                                    conversation.sender.id ? (
                                      <Mutation mutation={acceptApplication}>
                                        {mutation => (
                                          <ButtonContainer>
                                            <Button
                                              primary
                                              style={{
                                                marginRight: "8px"
                                              }}
                                              onClick={() =>
                                                mutation({
                                                  variables: {
                                                    data: {
                                                      accepted: true,
                                                      lastMessageAt: new Date().toISOString(),
                                                      messages: {
                                                        create: {
                                                          user: {
                                                            connect: {
                                                              id: User.id
                                                            }
                                                          },
                                                          value:
                                                            "I have accepted this invite."
                                                        }
                                                      },
                                                      organisation: {
                                                        update: conversation.manager
                                                          ? {
                                                              managers: {
                                                                connect: {
                                                                  id:
                                                                    conversation
                                                                      .recipient
                                                                      .id
                                                                }
                                                              }
                                                            }
                                                          : {
                                                              members: {
                                                                connect: {
                                                                  id:
                                                                    conversation
                                                                      .recipient
                                                                      .id
                                                                }
                                                              }
                                                            }
                                                      }
                                                    },
                                                    id: conversation.id
                                                  }
                                                })
                                              }
                                            >
                                              Accept
                                            </Button>
                                            <Button
                                              secondary
                                              style={{
                                                marginLeft: "8px"
                                              }}
                                              onClick={() =>
                                                mutation({
                                                  variables: {
                                                    data: {
                                                      rejected: true,
                                                      lastMessageAt: new Date().toISOString(),
                                                      messages: {
                                                        create: {
                                                          user: {
                                                            connect: {
                                                              id: User.id
                                                            }
                                                          },
                                                          value:
                                                            "I have rejected this invite."
                                                        }
                                                      }
                                                    },
                                                    id: conversation.id
                                                  }
                                                })
                                              }
                                            >
                                              Reject
                                            </Button>
                                          </ButtonContainer>
                                        )}
                                      </Mutation>
                                    ) : (
                                      <p>
                                        This invite has not been replied to yet.
                                      </p>
                                    )
                                  ) : null}
                                </Content>
                              </MessageContainer>
                            );
                          })}
                      </Fragment>
                    )}
                  </PageLayout>
                )}
              </Query>
            );
          }
        }}
      </Query>
    );
  }
}

const messagesQuery = gql`
  query messages($id: ID!, $skip: Int!) {
    messages(
      where: { conversation: { id: $id } }
      orderBy: createdAt_ASC
      skip: $skip
      last: 16
    ) {
      id
      value
      createdAt
      read_reciept
      user {
        id
        avatar
        first_names
        last_name
        role
      }
    }
  }
`;

const conversationQuery = gql`
  query conversation($id: ID!) {
    conversation(where: { id: $id }) {
      id
      role {
        id
        title
      }
      type
      recipient {
        id
        first_names
        last_name
        avatar
      }
      sender {
        id
        first_names
        last_name
        avatar
      }
      accepted
      rejected
      manager
      organisation {
        id
        name
        logo
      }
    }
  }
`;

const CreateMessage = gql`
  mutation createMessage($value: String!, $conversation: ID!, $user: ID!) {
    createMessage(value: $value, conversation: $conversation, user: $user) {
      id
      value
      createdAt
      conversation {
        id
      }
      user {
        avatar
        first_names
        last_name
      }
    }
  }
`;

const messageSubscription = gql`
  subscription($id: ID!) {
    message(
      where: { node: { conversation: { id: $id } }, mutation_in: [CREATED] }
    ) {
      mutation
      node {
        id
        value
        createdAt
        read_reciept
        user {
          id
          avatar
          first_names
          last_name
          role
        }
      }
    }
  }
`;

const unreadMessagesQuery = gql`
  query unreadMessages($id: ID!, $User: ID!) {
    messagesConnection(
      where: {
        AND: {
          read_reciept: false
          conversation: { id: $id }
          user: { id_not: $User }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const acceptApplication = gql`
  mutation($data: ConversationUpdateInput!, $id: ID!) {
    updateConversation(data: $data, where: { id: $id }) {
      id
      role {
        id
        title
      }
      type
      accepted
      rejected
      manager
      organisation {
        id
        name
        logo
      }
    }
  }
`;
