import styled, { css } from "styled-components";
import colors from "styles/colors";
import { spacing, sizing } from "styles/sizing";
import { tablet } from "styles/media";
import { regular } from "styles/typography";

export const CardWrapper = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
`;

export const Card = styled.section`
  width: 100%;
  flex: 1;
  background: ${colors.white};
  border-radius: 2px;
  padding: ${spacing(3)};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${tablet(css`
    min-width: 480px;
    padding: ${spacing(4)} ${spacing(8)};
  `)};
`;

export const CardHeading = styled.h1`
  font-size: ${sizing(20)};
  color: ${colors.white};
  ${regular};
  text-align: center;
  padding: ${spacing(2)} 0;

  ${tablet(css`
    font-size: ${sizing(24)};
  `)};
`;
