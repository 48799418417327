import styled, { css } from "styled-components";
import colors from "styles/colors";
import { spacing, sizing } from "styles/sizing";
import { bool } from "prop-types";
import { bold } from "styles/typography";
import { darken } from "polished";

export const Button = styled.button`
  border: 1px solid transparent;
  border-radius: 2px;

  ${bold};
  text-align: center;
  width: 100%;
  transition: border-color 0.4s ease, background 0.4s ease;

  padding: ${spacing(1.5)} ${spacing(2)};
  margin: ${spacing(1)} 0;
  font-size: ${sizing(14)};

  ${props =>
    props.primary &&
    css`
      background: ${colors.palatinateBlue};
      border-color: ${colors.palatinateBlue};
      color: ${colors.white};

      &:focus {
        outline: 1px dashed ${colors.palatinateBlue};
      }

      &:disabled {
        background-color: ${colors.frenchGray};
        border-color: transparent;
      }
    `};

  ${props =>
    props.secondary &&
    css`
      background: ${colors.white};
      border-color: ${colors.palatinateBlue};
      color: ${colors.palatinateBlue};

      &:focus {
        outline: 1px solid ${colors.palatinateBlue};
      }
    `};

  ${props =>
    props.tertiary &&
    css`
      background: ${colors.liquorice};
      border-color: ${colors.white};
      color: ${colors.white};

      &:focus {
        outline: 1px dashed ${colors.white};
      }
    `};

  ${props =>
    props.transparent &&
    css`
      background-color: transparent;
      &:focus {
        outline: none;
      }
    `};

  &:focus {
    outline-offset: 2px;
  }

  &:active {
    ${props =>
      props.primary &&
      css`
        background: ${darken(0.2, colors.palatinateBlue)};
      `};

    ${props =>
      props.secondary &&
      css`
        border-color: ${darken(0.2, colors.palatinateBlue)};
      `};

    ${props =>
      props.tertiary &&
      css`
        border-width: 2px;
      `};
  }

  &:hover:not(:disabled) {
    ${props =>
      props.primary &&
      css`
        background: ${darken(0.1, colors.palatinateBlue)};
      `};

    ${props =>
      props.secondary &&
      css`
        border-color: ${darken(0.1, colors.palatinateBlue)};
      `};

    ${props =>
      props.tertiary &&
      css`
        border-width: 2px;
      `};
  }
`;

Button.propTypes = {
  primary: bool,
  secondary: bool,
  tertiary: bool,
  transparent: bool
};

Button.defaultProps = {
  primary: false,
  secondary: false,
  tertiary: false,
  transparent: false
};
