import { sizing } from "./sizing";
import {
  mobileEnd,
  tabletStart,
  tabletEnd,
  deskStart,
  deskEnd,
  deskWideStart
} from "./consts";
import { css } from "styled-components";

export const isMobile = () =>
  window.matchMedia(`screen and (max-width: ${sizing(mobileEnd)})`).matches;

export const isTablet = () =>
  window.matchMedia(
    `screen and (min-width: ${sizing(tabletStart)}) and (max-width: ${sizing(
      tabletEnd
    )})`
  ).matches;

export const isDesk = () =>
  window.matchMedia(
    `screen and (min-width: ${sizing(deskStart)}) and (max-width: ${sizing(
      deskEnd
    )})`
  ).matches;

export const isDeskWide = () =>
  window.matchMedia(`screen and (min-width: ${sizing(deskWideStart)})`).matches;

export const isTabletAndUp = () =>
  window.matchMedia(`screen and (min-width: ${sizing(tabletStart)})`).matches;

export const isDeskAndUp = () =>
  window.matchMedia(`screen and (min-width: ${sizing(deskStart)})`).matches;

export const tablet = content => css`
  @media screen and (min-width: ${sizing(tabletStart)}) {
    ${content}
  }
`;

export const desk = content => css`
  @media screen and (min-width: ${sizing(deskStart)}) {
    ${content}
  }
`;

export const deskWide = content => css`
  @media screen and (min-width: ${sizing(deskWideStart)}) {
    ${content}
  }
`;
