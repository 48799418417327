import styled from "styled-components";
import { spacing } from "styles/sizing";
import colors from "styles/colors";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import React from "react";
import { Button } from "components/Button";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  margin: 0;
  padding: ${spacing(3)};
  background-color: ${colors.white};
  z-index: 100;
`;

const Message = styled.p`
  color: ${colors.blackRock};
`;

export const ConfirmationDialog = ({
  message,
  primaryAction,
  primaryText,
  secondaryAction,
  secondaryText
}) =>
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <Wrapper>
          <Message>{message}</Message>
          {primaryAction && primaryText && (
            <Button
              onClick={() => {
                primaryAction();
                onClose();
              }}
              primary
            >
              {primaryText}
            </Button>
          )}
          {secondaryAction && secondaryText && (
            <Button
              onClick={() => {
                secondaryAction();
                onClose();
              }}
              secondary
            >
              {secondaryText}
            </Button>
          )}
        </Wrapper>
      );
    }
  });
