import styled from "styled-components";
import { sizing, spacing } from "styles/sizing";
import { regular } from "styles/typography";
import colors from "styles/colors";

export const NaturalBlock = styled.dl`
  font-size: ${sizing(16)};
  line-height: ${sizing(24)};
  ${regular};
  display: inline-block;
  padding: ${spacing(1)} 0;
  margin: 0;
`;

export const NaturalData = styled.dd`
  margin: 0 ${spacing()};
  padding: ${spacing(1)};
  border-radius: 2px;
  background-color: ${colors.palatinateBlue};
  color: ${colors.white};
  font-size: ${sizing(16)};
  ${regular};
  display: inline-block;
`;

export const NaturalIntro = styled.dt`
  display: inline-block;
`;
