import React, { Component, Fragment } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import { Loader } from "components/Loader";
import { regular, semiBold } from "styles/typography";
import colors from "styles/colors";
import { sizing, spacing } from "styles/sizing";
import { NotFound } from "components/illustrations";
import { NavLink } from "react-router-dom";
import { Tag } from "components/Tag";
import { format } from "date-fns";
import { isTabletAndUp } from "styles/media";
import { parse, stringify } from "query-string";
import { Filters } from "./filters";
import { debounce } from "loadsh";
import { PageLayout, PageHeader } from "components/PageLayout";
import { FilterStatus, FilterArea } from "components/Filters";
import { Flag } from "flag";
import { locations } from "constants/locations";
import { experienceLevels } from "constants/experienceLevels";
import { PageTitle } from "components/PageTitle";
import Helmet from "react-helmet";
import { FloatingButton } from "components/PageLayout/style";
import {
  SummaryTile,
  SummaryDetail,
  SummaryImage,
  SummaryDate,
  SummaryTitle
} from "components/Summary";

const Results = styled.div`
  width: 100%;
  flex: 1;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  ${regular};
  font-size: ${sizing(30)};
  text-align: center;
  margin: 0;
  padding: ${spacing(3)} 0 ${spacing()} 0;
`;

const Info = styled.span`
  display: block;
  ${semiBold};
  color: ${colors.liquorice};
  font-size: ${sizing(14)};
  text-align: center;
  margin: 0;
  padding-bottom: ${spacing()};
`;

const IllustrationContainer = styled.div`
  padding: ${spacing(2)} ${spacing(5)};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Tabs = styled.div`
  width: 100%;
  display: flex;
  padding: 0 ${spacing(2)};
`;

const Tab = styled(NavLink)`
  text-decoration: none;
  color: ${colors.frenchGray};
  border-bottom: 1px solid ${colors.snuff};
  margin-right: ${spacing(2)};
  margin-bottom: -1px;
  ${regular};
  font-size: ${sizing(16)};
  text-transform: uppercase;
  cursor: pointer;

  transition: color 0.4s ease, border-color 0.4s ease;

  &:hover,
  &:focus {
    color: ${colors.carribeanGreen};
    border-color: ${colors.carribeanGreen};
  }

  &.active {
    color: ${colors.carribeanGreen};
    border-color: ${colors.carribeanGreen};
  }
`;

export class Roles extends Component {
  state = {
    allEntitiesRecieved: false,
    filtersActive: false
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      this.setState({
        allEntitiesRecieved: false
      });
    }
    return true;
  }

  generateFilters = () => {
    const {
      tab,
      search,
      tags,
      day_rate_low,
      day_rate_high,
      experience,
      organisations,
      locations,
      avaliability
    } = parse(this.props.location.search);
    const { User } = this.props;

    const filters = {
      closed: false
    };

    if (day_rate_low) {
      filters.day_rate_gte = parseInt(day_rate_low, 10);
    }

    if (day_rate_high) {
      filters.day_rate_lte = parseInt(day_rate_high, 10);
    }

    if (experience) {
      filters.experience_in = experience;
    }

    if (organisations) {
      filters.organisation = {
        id_in: organisations
      };
    }

    if (avaliability) {
      filters.full_time = avaliability;
    }

    if (locations) {
      filters.location_in = locations;
    }

    if (tags) {
      filters.tags_some = {
        id_in: tags
      };
    }

    if (search) {
      filters.title_contains = search;
    }

    if (tab === "applied") {
      filters.conversations_some = {
        OR: [{ recipient: { id: User.id } }, { sender: { id: User.id } }]
      };
    }

    return filters;
  };

  handleFilterSubmit = values => {
    const current = parse(this.props.location.search);
    this.setState({ filtersActive: false });

    const rate = {};

    if (values.rate[0] > 0) {
      rate.day_rate_low = values.rate[0];
    }
    if (values.rate[1] > 0) {
      rate.day_rate_high = values.rate[1];
    }

    this.props.history.push({
      search: stringify({
        ...current,
        ...{
          tags: values.tags.map(i => i.id),
          organisations: values.organisations.map(i => i.id),
          locations: values.locations.map(i => i.id),
          experience: values.experience.map(i => i.id)
        },
        ...rate
      })
    });
  };

  handleSearch = value => {
    const current = parse(this.props.location.search);
    debounce(
      () =>
        this.props.history.push({
          search: stringify({
            ...current,
            ...{
              search: value.toLowerCase()
            }
          })
        }),
      1000
    )();
  };

  render() {
    const { allEntitiesRecieved, filtersActive } = this.state;
    const { tab } = parse(this.props.location.search);
    const filters = this.generateFilters();
    const { User } = this.props;
    const currentSearch = parse(this.props.location.search);
    const filterCount = Object.keys(currentSearch).filter(
      key => key !== "tab" || (key === "search" && currentSearch[key] !== "")
    ).length;

    return (
      <Query
        query={query}
        variables={{
          skip: 0,
          filters
        }}
        fetchPolicy={"cache-and-network"}
        onCompleted={data => {
          if (!allEntitiesRecieved && data.roles.length < 20) {
            this.setState({
              allEntitiesRecieved: true
            });
          }
        }}
      >
        {({ loading, error, data, fetchMore }) => (
          <Query
            query={totalCountQuery}
            variables={{
              filters: { ...filters, conversations_some: undefined }
            }}
            fetchPolicy={"cache-and-network"}
          >
            {({ data: { rolesConnection } }) => (
              <Query
                query={appliedCountQuery}
                variables={{
                  filters: {
                    ...filters,
                    conversations_some: {
                      OR: [
                        { recipient: { id: User.id } },
                        { sender: { id: User.id } }
                      ]
                    }
                  }
                }}
                fetchPolicy={"cache-and-network"}
              >
                {({ data: { rolesApplied } }) => {
                  if (
                    data.roles &&
                    data.roles[0] &&
                    isTabletAndUp() &&
                    this.props.match.isExact
                  ) {
                    this.props.history.push({
                      pathname: `/roles/${data.roles[0].id}`,
                      search: this.props.location.search
                    });
                  }
                  return (
                    <PageLayout
                      isDual
                      rightAligned
                      header={
                        <PageHeader>
                          <Helmet>
                            <title>Roles - Core Consultants</title>
                          </Helmet>
                          <Filters
                            isActive={filtersActive}
                            onRequestClose={() =>
                              this.setState({ filtersActive: false })
                            }
                            onSubmit={this.handleFilterSubmit}
                            currentFilters={currentSearch}
                          />

                          <PageTitle left>
                            Roles{" "}
                            <Flag name="clientOrAdmin">
                              <FloatingButton
                                primary
                                onClick={() =>
                                  this.props.history.push("/roles/create")
                                }
                              >
                                Create Role
                              </FloatingButton>
                            </Flag>
                          </PageTitle>
                          <FilterArea
                            defaultValue={currentSearch.search || ""}
                            onChange={e => this.handleSearch(e.target.value)}
                            onClick={() =>
                              this.setState({ filtersActive: true })
                            }
                          />
                          {filterCount ? (
                            <FilterStatus
                              sticky
                              count={filterCount}
                              onClick={() => {
                                if (this.searchElement) {
                                  this.searchElement.value = "";
                                }

                                this.props.history.push({
                                  search: stringify({ tab })
                                });
                              }}
                            />
                          ) : null}
                          <Tabs>
                            <Tab
                              activeClassName="active"
                              isActive={() => !tab}
                              to={{
                                search: stringify({
                                  ...currentSearch,
                                  ...{
                                    tab: undefined
                                  }
                                })
                              }}
                            >
                              {`All (${
                                rolesConnection && rolesConnection.aggregate
                                  ? rolesConnection.aggregate.count
                                  : ""
                              })`}
                            </Tab>
                            <Tab
                              activeClassName="active"
                              isActive={() => tab === "applied"}
                              to={{
                                search: stringify({
                                  ...currentSearch,
                                  ...{
                                    tab: "applied"
                                  }
                                })
                              }}
                            >
                              {`Applied For (${
                                rolesApplied && rolesApplied.aggregate
                                  ? rolesApplied.aggregate.count
                                  : ""
                              })`}
                            </Tab>
                          </Tabs>
                        </PageHeader>
                      }
                      onScrollBottom={() => {
                        if (!allEntitiesRecieved) {
                          fetchMore({
                            variables: {
                              skip: data.roles.length,
                              filters: filters
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                              if (!fetchMoreResult || loading) return prev;

                              if (fetchMoreResult.roles.length < 20) {
                                this.setState({
                                  allEntitiesRecieved: true
                                });
                              }

                              return Object.assign({}, prev, {
                                roles: [...prev.roles, ...fetchMoreResult.roles]
                              });
                            }
                          });
                        }
                      }}
                    >
                      <Results>
                        {loading && !data.roles && <Loader />}
                        {data.roles && !data.roles.length && (
                          <Fragment>
                            <Title>No results here</Title>
                            <Info>Try a different set of key words</Info>
                            <IllustrationContainer>
                              <NotFound />
                            </IllustrationContainer>
                          </Fragment>
                        )}
                        {data.roles && data.roles.length && (
                          <Fragment>
                            {data.roles.map((role, index) => (
                              <SummaryTile
                                key={`role-${index}`}
                                to={`/roles/${role.id}`}
                              >
                                {role.organisation ? (
                                  <SummaryImage url={role.organisation.logo} />
                                ) : (
                                  <SummaryImage url={role.client.avatar} />
                                )}
                                <SummaryDetail>
                                  <SummaryTitle>{role.title}</SummaryTitle>
                                  {role.location && (
                                    <Tag dark>{locations[role.location]}</Tag>
                                  )}
                                  {role.day_rate && (
                                    <Tag dark>{`£${role.day_rate} p/d`}</Tag>
                                  )}
                                  {role.experience && (
                                    <Tag dark>
                                      {experienceLevels[role.experience]}
                                    </Tag>
                                  )}
                                  {role.full_time && (
                                    <Tag dark>
                                      {role.full_time
                                        ? "Full Time"
                                        : "Part Time"}
                                    </Tag>
                                  )}
                                  <SummaryDate>
                                    {`Posted on: ${format(
                                      role.createdAt,
                                      "DD/MM/YYYY"
                                    )}`}
                                  </SummaryDate>
                                </SummaryDetail>
                              </SummaryTile>
                            ))}
                            {!allEntitiesRecieved && (
                              <div style={{ height: "50px" }}>
                                <Loader width="40px" />
                              </div>
                            )}
                          </Fragment>
                        )}
                      </Results>
                    </PageLayout>
                  );
                }}
              </Query>
            )}
          </Query>
        )}
      </Query>
    );
  }
}

const query = gql`
  query($skip: Int, $filters: RoleWhereInput) {
    roles(skip: $skip, first: 20, where: $filters, orderBy: createdAt_DESC) {
      id
      title
      day_rate
      location
      experience
      full_time
      createdAt
      organisation {
        id
        logo
      }
      client {
        id
        avatar
      }
    }
  }
`;

const totalCountQuery = gql`
  query($filters: RoleWhereInput) {
    rolesConnection(where: $filters) {
      aggregate {
        count
      }
    }
  }
`;

const appliedCountQuery = gql`
  query($filters: RoleWhereInput) {
    rolesApplied: rolesConnection(where: $filters) {
      aggregate {
        count
      }
    }
  }
`;
