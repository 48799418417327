import { spacing, sizing } from "styles/sizing";
import styled from "styled-components";
import colors from "styles/colors";
import { semiBold } from "styles/typography";

export const Title = styled.h1`
  font-size: ${sizing(18)};
  color: ${colors.blackRock};
  ${semiBold};
  text-align: left;
  padding: ${spacing(1)} 0;
  border-bottom: 1px solid ${colors.snuff};
  margin-bottom: ${spacing(2)};
`;

export const Members = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const WebsiteLink = styled.a.attrs({
  target: "_blank"
})`
  color: ${colors.blackRock};
  display: block;
  padding: ${spacing(1)} 0;
  text-align: center;
`;
