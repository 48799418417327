import styled from "styled-components";

import colors from "styles/colors";
import { spacing, sizing } from "styles/sizing";
import { semiBold, regular } from "styles/typography";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${props => props.vertical && "justify-content: center;"}

  ${props =>
    props.vertical &&
    props.hasTitle &&
    `
    flex-direction: column;
    margin-top: 0;
    padding: ${spacing(4)} ${spacing(2)};
    text-align: center;
  `};
`;

export const Title = styled.h1`
  display: block;
  margin: 0;
  padding-top: ${spacing(0.5)};
  padding-left: ${spacing()};
  color: ${colors.white};
  ${regular};
  font-size: ${sizing(22)};

  strong {
    ${semiBold};
  }

  ${props =>
    props.vertical &&
    `
    padding-top: ${spacing(1)};
    padding-left: 0;
    font-size: ${sizing(34)};
  `};
`;
