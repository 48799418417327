const colors = {
  white: "#FFFFFF",
  black: "#000000",
  red: "#FF0000",
  blackRock: "#302E3C",
  carribeanGreen: "#16D68E",
  brightGray: "#3D3F4F",
  frenchGray: "#BEBFC5",
  neonBlue: "#4734FF",
  palatinateBlue: "#2F1ECF",
  liquorice: "#313347",
  ghost: "#CCCBD4",
  scarpaFlow: "#54525F",
  snuff: "#DFDEEB",
  iris: "#5E53D0",
  solitude: "#EAEAF2",
  antiFlashWhite: "#f2f2f5",
  whiteLilac: "#FAFAFD"
};

export default colors;
