import { css } from "styled-components";
import colors from "./colors";
import { regular } from "./typography";
import { spacing, sizing } from "./sizing";

export const datePickerStyles = css`
  .react-datepicker {
    ${regular};
    border-radius: 0;
    border: none;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.5);

    &__portal {
      position: fixed;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.8);
      left: 0;
      top: 0;
      justify-content: center;
      align-items: center;
      display: flex;
      z-index: 2147483647;
    }

    &__day {
      border: 1px solid ${colors.carribeanGreen};
      border-radius: 0;

      &:hover,
      &:focus {
        border-radius: 0;
        background: ${colors.carribeanGreen};
      }

      &--selected {
        background: ${colors.carribeanGreen};
      }
    }

    &__header {
      padding: ${spacing(2)} ${spacing(2)} 0;
      background-color: ${colors.blackRock};
      border-radius: 0;
      border-bottom: 1px solid ${colors.snuff};

      &__dropdown {
        padding: 0 ${spacing(2)};
      }
    }

    &__current-month {
      font-size: ${sizing(16)} !important;
      color: ${colors.white};
      padding-bottom: ${spacing(1)};
    }

    &__navigation {
      border-width: ${sizing(8)} !important;
      top: ${spacing(2)};

      &:focus {
        outline: none;
      }

      &--years-upcoming {
        top: 0;
        border-bottom-color: ${colors.blackRock} !important;
      }

      &--years-previous {
        top: 0;
        border-top-color: ${colors.blackRock} !important;
      }

      &--previous {
        border-right-color: ${colors.white} !important;
      }

      &--next {
        border-left-color: ${colors.white} !important;
      }
    }

    &__day-name {
      color: ${colors.white};
    }

    &__month-read-view--selected-month {
      color: ${colors.white};
      font-size: ${sizing(14)};
    }

    &__month-read-view--down-arrow {
      top: 6px;
      border-top-color: ${colors.white};
      margin-bottom: 0;
      margin-left: ${spacing()};
      border-width: ${sizing(5)};

      &:before {
        border-top-color: ${colors.white};
      }
    }

    &__year-read-view--selected-year {
      color: ${colors.white};
      font-size: ${sizing(14)};
    }

    &__year-read-view--down-arrow {
      top: 6px;
      border-top-color: ${colors.white};
      margin-bottom: 0;
      margin-left: ${spacing()};
      border-width: ${sizing(5)};

      &:before {
        border-top-color: ${colors.white};
      }
    }

    &__month-dropdown {
      border-radius: 0;
      background-color: ${colors.white};
      border: 1px solid ${colors.blackRock};
      max-height: 250px;
      overflow: scroll;
    }

    &__month-option {
      font-size: ${sizing(14)};
      padding: ${spacing(1)} 0;
      color: ${colors.blackRock};
      background-color: ${colors.white};
      transition: color 0.4s ease, background-color 0.4s ease;

      &--selected {
        display: none;
      }

      &:hover {
        color: ${colors.white};
        background-color: ${colors.blackRock};
      }
    }

    &__year-dropdown {
      border-radius: 0;
      background-color: ${colors.white};
      border: 1px solid ${colors.blackRock};
      max-height: 250px;
      overflow: scroll;
    }

    &__year-option {
      font-size: ${sizing(14)};
      padding: ${spacing(1)} 0;
      color: ${colors.blackRock};
      background-color: ${colors.white};
      transition: color 0.4s ease, background-color 0.4s ease;
      border-radius: 0 !important;

      &--selected {
        display: none;
      }

      &:hover {
        color: ${colors.white};
        background-color: ${colors.blackRock};

        .react-datepicker__navigation--years-upcoming {
          border-bottom-color: ${colors.white} !important;
        }

        .react-datepicker__navigation--years-previous {
          border-top-color: ${colors.white} !important;
        }
      }
    }

    &__triangle {
      border-bottom-color: ${colors.blackRock} !important;
    }
  }
`;
