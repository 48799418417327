import React from "react";
import { Icon } from "./Icon";

export const SettingsIcon = props => (
  <Icon {...props} viewBox="0 0 20 20">
    <path
      fill="currentcolor"
      fillRule="nonzero"
      d="M9 18.442a8.459 8.459 0 0 1-4.262-1.766l-1.102 1.102-1.414-1.414 1.102-1.102A8.459 8.459 0 0 1 1.558 11H0V9h1.558a8.459 8.459 0 0 1 1.766-4.262L2.222 3.636l1.414-1.414 1.102 1.102A8.459 8.459 0 0 1 9 1.558V0h2v1.558a8.459 8.459 0 0 1 4.262 1.766l1.102-1.102 1.414 1.414-1.102 1.102A8.459 8.459 0 0 1 18.442 9H20v2h-1.558a8.459 8.459 0 0 1-1.766 4.262l1.102 1.102-1.414 1.414-1.102-1.102A8.459 8.459 0 0 1 11 18.442V20H9v-1.558zm1-.942a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15zm0-2a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0-1a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9z"
    />
  </Icon>
);
