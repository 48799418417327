import React from "react";
import Html from "slate-html-serializer";

const BLOCK_TAGS = {
  blockquote: "blockquote",
  p: "paragraph",
  a: "link",
  ul: "bulleted-list",
  ol: "numbered-list",
  li: "list-item",
  h3: "title",
  span: "span"
};

const MARK_TAGS = {
  em: "italic",
  strong: "bold",
  u: "underlined",
  strike: "strike-through"
};

const rules = [
  {
    deserialize(el, next) {
      const type = BLOCK_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: "block",
          type: type,
          data: {
            href: el.getAttribute("href")
          },
          nodes: next(el.childNodes)
        };
      }
    },
    serialize(obj, children) {
      if (obj.type === "paragraph" && !obj.text) {
        return null;
      }

      if (obj.object === "block") {
        switch (obj.type) {
          case "paragraph":
            return <p>{children}</p>;
          case "blockquote":
            return <blockquote>{children}</blockquote>;
          case "bulleted-list":
            return <ul>{children}</ul>;
          case "list-item":
            return <li>{children}</li>;
          case "numbered-list":
            return <ol>{children}</ol>;
          case "title":
            return <h3>{children}</h3>;
          case "span":
            return <span>{children}</span>;
          default:
            return "";
        }
      }
    }
  },
  {
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: "mark",
          type: type,
          nodes: next(el.childNodes)
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === "mark") {
        switch (obj.type) {
          case "bold":
            return <strong>{children}</strong>;
          case "italic":
            return <em>{children}</em>;
          case "underlined":
            return <u>{children}</u>;
          case "strike-through":
            return <strike>{children}</strike>;
          case "list-item":
            return <li>{children}</li>;
          default:
            return "";
        }
      }
    }
  }
];

export const serializer = new Html({ rules });
