import React, { Component } from "react";
import { node, string } from "prop-types";
import { Wrapper, Label, Input, Error } from "./styles.js";
import { isMobile } from "styles/media.js";
import DatePicker from "react-datepicker";
import colors from "styles/colors.js";
import { Button } from "components/Button/index.js";
import { spacing } from "styles/sizing.js";

export const FieldWrapper = ({ children, width, ...props }) => (
  <Wrapper width={width} {...props}>
    {children}
  </Wrapper>
);

FieldWrapper.propTypes = {
  children: node,
  width: string
};

export const FieldLabel = ({ name, children, ...props }) => (
  <Label htmlFor={name} {...props}>
    {children}
  </Label>
);

FieldLabel.propTypes = {
  name: string,
  children: node || string
};

export const FieldError = ({ name, component, ...props }) => (
  <Error name={name} component={component} {...props} />
);

FieldError.propTypes = {
  name: string,
  component: string
};

FieldError.defaultProps = {
  component: "div"
};

export class Field extends Component {
  render() {
    const { name, ...props } = this.props;
    return <Input id={name} name={name} {...props} />;
  }
}

Field.propTypes = {
  name: string,
  children: node || string
};

export const FormField = ({
  className,
  name,
  type,
  label,
  error,
  value,
  errorMessage,
  placeholder,
  onChange,
  onBlur,
  touched,
  min,
  max,
  step,
  width,
  children,
  defaultValue,
  ...props
}) => {
  return (
    <Wrapper width={width} className={className}>
      <Label aria-hidden="false" htmlFor={name}>
        {label}
      </Label>
      {type !== "select" && type !== "textarea" && (
        <Input
          onBlur={onBlur}
          onChange={onChange}
          id={name}
          type={type}
          value={value}
          min={min}
          max={max}
          step={step}
          width={width}
          error={error}
          placeholder={placeholder}
          {...props}
        />
      )}
      {type === "select" && (
        <Input
          onBlur={onBlur}
          onChange={onChange}
          id={name}
          component={type}
          width={width}
          value={value}
          error={error}
          {...props}
        >
          {children}
        </Input>
      )}
      {type === "textarea" && (
        <Input
          onBlur={onBlur}
          onChange={onChange}
          id={name}
          component={type}
          width={width}
          error={error}
          value={value}
          {...props}
        />
      )}
    </Wrapper>
  );
};

class DateCustomInput extends Component {
  render() {
    const { value, defaultValue, ...props } = this.props;
    return (
      <Field
        style={{ color: colors.blackRock }}
        value={defaultValue === null ? "Current" : value}
        {...props}
        type="text"
      />
    );
  }
}

export class DateInput extends Component {
  render() {
    const { defaultValue, onChange, minDate, hasCurrent } = this.props;
    return (
      <DatePicker
        ref={element => (this.element = element)}
        customInput={<DateCustomInput defaultValue={defaultValue} />}
        selected={defaultValue !== null ? new Date(defaultValue) : new Date()}
        onChange={onChange}
        withPortal={isMobile()}
        minDate={minDate}
        showMonthDropdown
        showYearDropdown
        yearDropdownItemNumber={5}
        dateFormat="dd/MM/YYYY"
        popperModifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "scrollParent"
          }
        }}
        calendarContainer={({ children, className }) => (
          <div className={className}>
            {children}
            {hasCurrent && (
              <Button
                style={{
                  width: `calc(100% - ${spacing(2)})`,
                  marginLeft: spacing(1)
                }}
                onClick={() => {
                  onChange(null);
                  if (this.element) {
                    this.element.setOpen(false);
                  }
                }}
                type="button"
                primary
              >
                Current
              </Button>
            )}
          </div>
        )}
      />
    );
  }
}
