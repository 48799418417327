import React, { Component, Fragment } from "react";
import { Modal } from "components/Modal";
import { Title, Header, Body } from "./style";
import { Form } from "components/Form";
import * as Yup from "yup";
import { Field, FieldWrapper, FieldError } from "components/FormField";
import { Label } from "components/FormField/styles";
import gql from "graphql-tag";
import { Mutation, Query } from "react-apollo";
import { Autocomplete } from "components/Autocomplete";
import { locations } from "constants/locations";
import { ImageUploader } from "components/ImageUploader";
import { AnimatedWrapper } from "containers/DeleteAccount/styles";
import { SubmittedTitle } from "containers/OrginisationJoin/styles";
import { ImageContainer } from "containers/OrganisationInvite/styles";
import { ApplicationSent, NotFound } from "components/illustrations";
import { Button } from "components/Button";
import { PoseGroup } from "react-pose";
import { Loader } from "components/Loader";
import { ConfirmationDialog } from "components/ConfirmationDialog";
import { Flag } from "flag";

export class EditSettings extends Component {
  state = {
    isFormSubmitted: false,
    error: false,
    loading: false
  };

  render() {
    const { isFormSubmitted, error } = this.state;
    return (
      <Modal
        isOpen
        onRequestClose={() =>
          ConfirmationDialog({
            message:
              "Are you sure you wish to exit, any changed data will be lost?",
            primaryText: "Close",
            primaryAction: () => this.props.history.push("/settings"),
            secondaryText: "Cancel",
            secondaryAction: () => null
          })
        }
      >
        <PoseGroup>
          {!isFormSubmitted ? (
            <AnimatedWrapper key="form">
              <Header>
                <Title>Edit Settings</Title>
              </Header>
              <Body>
                <Query query={query}>
                  {({ data }) => {
                    if (!data || !data.User) {
                      return <Loader />;
                    }

                    return (
                      <Mutation mutation={createConsultant}>
                        {(mutation, { error, loading }) => {
                          if (error) {
                            this.setState({ error });
                          }

                          if (loading) {
                            return <Loader />;
                          }

                          return (
                            <Form
                              initialValues={{
                                avatar: data.User.avatar,
                                first_names: data.User.first_names,
                                last_name: data.User.last_name,
                                job_title: data.User.data.job_title,
                                location: {
                                  id: data.User.data.location,
                                  value: locations[data.User.data.location]
                                },
                                email: data.User.email,
                                phone: data.User.data.phone
                              }}
                              validationSchema={Yup.object().shape({
                                first_names: Yup.string().required(
                                  "Forenames is a required field"
                                ),
                                last_name: Yup.string().required(
                                  "Surname is a required field"
                                ),
                                job_title: Yup.string().required(
                                  "Job Title is a required field"
                                ),
                                location: Yup.object()
                                  .shape({
                                    id: Yup.string(),
                                    value: Yup.string()
                                  })
                                  .required("Please include a location"),
                                email: Yup.string()
                                  .email("Invalid email")
                                  .required("Email is a required field"),
                                phone: Yup.string()
                                  .matches(
                                    /^(0\d{10,12}|44\d{10,12})$/s,
                                    "Please enter a valid phone number"
                                  )
                                  .required("Phone Number is a required field")
                              })}
                              onReset={() =>
                                this.props.history.push("/settings")
                              }
                              onSubmit={values => {
                                mutation({
                                  variables: {
                                    id: this.props.User.id,
                                    avatar: values.avatar,
                                    first_names: values.first_names.toLowerCase(),
                                    last_name: values.last_name.toLowerCase(),
                                    job_title: values.job_title.toLowerCase(),
                                    location: values.location.id,
                                    email: values.email,
                                    phone: values.phone
                                  }
                                });
                                this.setState({ isFormSubmitted: true });
                              }}
                            >
                              {(errors, values, setFieldValue, touched) => (
                                <Fragment>
                                  <Flag name="clientOrAdmin">
                                    <ImageUploader
                                      onChange={file =>
                                        setFieldValue("avatar", file)
                                      }
                                      image={values.avatar}
                                    />
                                    <FieldWrapper
                                      style={{ flexDirection: "column" }}
                                    >
                                      <Label name="title">First names</Label>
                                      <Field name="first_names" />
                                      <FieldError name="first_names" />
                                    </FieldWrapper>
                                    <FieldWrapper
                                      style={{ flexDirection: "column" }}
                                    >
                                      <Label name="last_name">Last name</Label>
                                      <Field name="last_name" />
                                      <FieldError name="last_name" />
                                    </FieldWrapper>

                                    <FieldWrapper
                                      style={{ flexDirection: "column" }}
                                    >
                                      <Label name="location">Location</Label>
                                      <Autocomplete
                                        isSingle
                                        style={{ width: "100%" }}
                                        items={Object.keys(locations).map(
                                          key => ({
                                            id: key,
                                            isActive:
                                              key === values.location.id,
                                            value: locations[key]
                                          })
                                        )}
                                        onChange={value =>
                                          setFieldValue("location", value)
                                        }
                                      />
                                      <FieldError name="location" />
                                    </FieldWrapper>
                                    <FieldWrapper
                                      style={{ flexDirection: "column" }}
                                    >
                                      <Label name="job_title">Job Title</Label>
                                      <Field name="job_title" />
                                      <FieldError name="job_title" />
                                    </FieldWrapper>
                                  </Flag>
                                  <FieldWrapper
                                    style={{ flexDirection: "column" }}
                                  >
                                    <Label name="email">Email</Label>
                                    <Field name="email" />
                                    <FieldError name="email" />
                                  </FieldWrapper>
                                  <FieldWrapper
                                    style={{ flexDirection: "column" }}
                                  >
                                    <Label name="phone">Phone</Label>
                                    <Field name="phone" />
                                    <FieldError name="phone" />
                                  </FieldWrapper>
                                </Fragment>
                              )}
                            </Form>
                          );
                        }}
                      </Mutation>
                    );
                  }}
                </Query>
              </Body>
            </AnimatedWrapper>
          ) : (
            <AnimatedWrapper key="result">
              <SubmittedTitle>
                {error
                  ? "There was an error"
                  : "your settings have been updated"}
              </SubmittedTitle>
              <ImageContainer>
                {error ? <NotFound dark /> : <ApplicationSent dark />}
              </ImageContainer>
              <Button
                primary
                type="button"
                onClick={() => this.props.history.push(`/settings`)}
              >
                Close
              </Button>
            </AnimatedWrapper>
          )}
        </PoseGroup>
      </Modal>
    );
  }
}

const createConsultant = gql`
  mutation updateUser(
    $id: ID!
    $avatar: Upload
    $first_names: String!
    $last_name: String!
    $job_title: String!
    $phone: String!
    $email: String!
    $location: Location
  ) {
    updateUser(
      id: $id

      first_names: $first_names
      last_name: $last_name
      avatar: $avatar
      email: $email
      job_title: $job_title
      phone: $phone
      location: $location
    ) {
      id
      first_names
      last_name
      email
      avatar
      data {
        id
        job_title
        phone
        location
      }
    }
  }
`;

const query = gql`
  query {
    User {
      id
      first_names
      last_name
      email
      avatar
      data {
        id
        job_title
        phone
        location
      }
    }
  }
`;
