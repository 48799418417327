import React, { Component, Fragment } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Loader } from "components/Loader";
import { NotFound } from "components/illustrations";
import { parse, stringify } from "query-string";
import { Filters } from "./filters";
import { debounce } from "loadsh";
import { Title, Results, Info, IllustrationContainer } from "./style";
import { isTabletAndUp } from "styles/media";
import { PageLayout, PageHeader } from "components/PageLayout";
import { FilterArea, FilterStatus } from "components/Filters";
import { PageTitle } from "components/PageTitle";
import { FloatingButton } from "components/PageLayout/style";
import {
  SummaryTile,
  SummaryImage,
  SummaryDetail,
  SummaryTitle,
  SummarySubtitle
} from "components/Summary";
import Helmet from "react-helmet";

export class Clients extends Component {
  state = {
    allEntitiesRecieved: false,
    filtersActive: false
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      this.setState({
        allEntitiesRecieved: false
      });
    }
    return true;
  }

  generateFilters = () => {
    const { search, locations } = parse(this.props.location.search);

    const filters = { data: {} };

    if (search) {
      filters.OR = [
        {
          first_names_contains: search.toLowerCase()
        },
        {
          last_name_contains: search.toLowerCase()
        },
        {
          data: {
            job_title_contains: search.toLowerCase()
          }
        }
      ];
    }

    if (locations) {
      filters.data.location_in = locations;
    }

    return filters;
  };

  handleFilterSubmit = values => {
    const current = parse(this.props.location.search);
    this.setState({ filtersActive: false });

    this.props.history.push({
      search: stringify({
        ...current,
        ...{
          locations: values.locations.map(i => i.id)
        }
      })
    });
  };

  handleSearch = value => {
    const current = parse(this.props.location.search);
    debounce(
      () =>
        this.props.history.push({
          search: stringify({
            ...current,
            ...{
              search: value.toLowerCase()
            }
          })
        }),
      1000
    )();
  };

  render() {
    const { allEntitiesRecieved, filtersActive } = this.state;
    const { tab } = parse(this.props.location.search);
    const filters = this.generateFilters();
    const currentSearch = parse(this.props.location.search);
    const filterCount = Object.keys(currentSearch).filter(
      key => key !== "tab" || (key === "search" && currentSearch[key] !== "")
    ).length;

    return (
      <Query
        query={query}
        variables={{
          skip: 0,
          filters
        }}
        fetchPolicy={"cache-and-network"}
        onCompleted={data => {
          if (
            !allEntitiesRecieved &&
            data &&
            data.clients &&
            data.clients.length < 20
          ) {
            this.setState({
              allEntitiesRecieved: true
            });
          }
        }}
      >
        {({ loading, error, data, fetchMore }) => {
          if (
            data &&
            data.clients &&
            data.clients[0] &&
            isTabletAndUp() &&
            this.props.match.isExact
          ) {
            this.props.history.push(`/clients/${data.clients[0].id}`);
          }
          return (
            <PageLayout
              isDual
              rightAligned
              header={
                <PageHeader>
                  <Helmet>
                    <title>Clients - Core Consultants</title>
                  </Helmet>
                  <Filters
                    isActive={filtersActive}
                    onRequestClose={() =>
                      this.setState({ filtersActive: false })
                    }
                    onSubmit={this.handleFilterSubmit}
                    currentFilters={currentSearch}
                  />

                  <PageTitle>
                    Clients{" "}
                    <FloatingButton
                      primary
                      onClick={() =>
                        this.props.history.push("/clients/create-client")
                      }
                    >
                      Add Client
                    </FloatingButton>
                  </PageTitle>
                  <FilterArea
                    defaultValue={currentSearch.search || ""}
                    onChange={e => this.handleSearch(e.target.value)}
                    onClick={() => this.setState({ filtersActive: true })}
                  />
                  {filterCount ? (
                    <FilterStatus
                      sticky
                      count={filterCount}
                      onClick={() => {
                        if (this.searchElement) {
                          this.searchElement.value = "";
                        }

                        this.props.history.push({
                          search: stringify({ tab })
                        });
                      }}
                    />
                  ) : null}
                </PageHeader>
              }
              onScrollBottom={() => {
                if (!allEntitiesRecieved) {
                  fetchMore({
                    variables: {
                      skip: data.clients.length,
                      filters: filters
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult || loading) return prev;

                      if (fetchMoreResult.clients.length < 20) {
                        this.setState({
                          allEntitiesRecieved: true
                        });
                      }

                      return Object.assign({}, prev, {
                        roles: [...prev.clients, ...fetchMoreResult.clients]
                      });
                    }
                  });
                }
              }}
            >
              <Results>
                {loading && data && !data.clients && <Loader />}
                {data.clients && !data.clients.length && (
                  <Fragment>
                    <Title>No results here</Title>
                    <Info>Try a different set of key words</Info>
                    <IllustrationContainer>
                      <NotFound />
                    </IllustrationContainer>
                  </Fragment>
                )}
                {data.clients && data.clients.length && (
                  <Fragment>
                    {data.clients.map((client, index) => (
                      <SummaryTile
                        key={`client-${index}`}
                        to={`/clients/${client.id}`}
                      >
                        <SummaryImage url={client.avatar} />
                        <SummaryDetail>
                          <SummaryTitle>{`${client.first_names} ${
                            client.last_name
                          }`}</SummaryTitle>
                          <SummarySubtitle>
                            {client.data.job_title}
                          </SummarySubtitle>
                        </SummaryDetail>
                      </SummaryTile>
                    ))}
                    {!allEntitiesRecieved && (
                      <div style={{ height: "50px" }}>
                        <Loader width="40px" />
                      </div>
                    )}
                  </Fragment>
                )}
              </Results>
            </PageLayout>
          );
        }}
      </Query>
    );
  }
}

const query = gql`
  query($skip: Int, $filters: UserWhereInput) {
    clients(skip: $skip, first: 20, where: $filters) {
      id
      first_names
      last_name
      email
      avatar
      data {
        job_title
        phone
      }
    }
  }
`;
