import React, { Component } from "react";
import { Modal } from "components/Modal";
import { PoseGroup } from "react-pose";
import { Button } from "components/Button";
import { ApplicationSent } from "components/illustrations";
import {
  SubmittedTitle,
  ImageContainer,
  AnimatedWrapper,
  Header,
  Wrapper,
  Title,
  Body
} from "./styles";
import { Form, Formik } from "formik";
import { FieldWrapper, FieldLabel } from "components/FormField";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { UserInput } from "components/UserInput";

export class OrganisationInvite extends Component {
  state = {
    isFormSubmitted: false
  };
  render() {
    const {
      match: {
        params: { id }
      },
      history: { push },
      User: { id: UserID }
    } = this.props;

    const { isFormSubmitted } = this.state;
    return (
      <Modal isOpen onRequestClose={() => push(`/teams/${id}`)}>
        <PoseGroup>
          {!isFormSubmitted ? (
            <AnimatedWrapper key="form">
              <Wrapper>
                <Header>
                  <Title>Invite members to the Team</Title>
                </Header>
                <Body>
                  <Mutation mutation={mutation}>
                    {mutation => (
                      <Formik
                        initialValues={{
                          members: []
                        }}
                        onSubmit={values => {
                          mutation({
                            variables: {
                              id: id,
                              conversations: {
                                create: values.members.map(member => ({
                                  sender: {
                                    connect: {
                                      id: UserID
                                    }
                                  },
                                  recipient: {
                                    connect: {
                                      id: member.id
                                    }
                                  },
                                  type: "INVITE",
                                  lastMessageAt: new Date().toISOString(),
                                  messages: {
                                    create: {
                                      user: {
                                        connect: {
                                          id: UserID
                                        }
                                      },
                                      value: `<p>You have been invited to join this team. Please Respond.</p>`
                                    }
                                  }
                                }))
                              }
                            }
                          });
                          this.setState({ isFormSubmitted: true });
                        }}
                      >
                        {({ values }) => (
                          <Form>
                            <FieldWrapper style={{ flexDirection: "column" }}>
                              <FieldLabel name="name">
                                Invite Members
                              </FieldLabel>
                              <UserInput
                                name="members"
                                query={queryUsers}
                                id={id}
                                values={values.members}
                              />
                            </FieldWrapper>
                            <Button primary type="submit">
                              Save
                            </Button>
                            <Button
                              secondary
                              type="button"
                              onClick={() => push(`/teams/${id}`)}
                            >
                              Cancel
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </Mutation>
                </Body>
              </Wrapper>
            </AnimatedWrapper>
          ) : (
            <AnimatedWrapper key="result">
              <SubmittedTitle>The members have been invited</SubmittedTitle>
              <ImageContainer>
                <ApplicationSent dark />
              </ImageContainer>
              <Button
                primary
                type="button"
                onClick={() => push(`/teams/${id}`)}
              >
                Close
              </Button>
            </AnimatedWrapper>
          )}
        </PoseGroup>
      </Modal>
    );
  }
}

const mutation = gql`
  mutation updateOrganisation(
    $conversations: ConversationUpdateManyWithoutOrganisationInput
    $id: ID!
  ) {
    updateOrganisation(where: { id: $id }, conversations: $conversations) {
      id
    }
  }
`;

const queryUsers = gql`
  query users($search: String!, $id: ID!) {
    users(
      where: {
        AND: [
          {
            OR: [
              { first_names_contains: $search }
              { last_name_contains: $search }
            ]
          }
          { organisationMember_none: { id: $id } }
          { organisationManager_none: { id: $id } }
          { organisationOwnership_none: { id: $id } }
        ]
      }
    ) {
      id
      first_names
      last_name
      avatar
    }
  }
`;
