import React, { Fragment } from "react";
import { FieldArray } from "formik";
import styled from "styled-components";
import { spacing, sizing } from "styles/sizing";
import {
  Field as BasicField,
  FieldWrapper,
  FieldLabel,
  FieldError,
  DateInput
} from "components/FormField";
import { Button } from "components/Button";
import colors from "styles/colors";
import { semiBold, regular } from "styles/typography";
import { TextEditor } from "components/TextEditor";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ExperiencesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  flex: 1;
`;

const ExperienceContainer = styled.div`
  margin: ${spacing(1)} 0;
  ${props => !props.isSingular && `border: 1px solid ${colors.snuff}`};
  ${props => !props.isSingular && `padding: ${spacing(2)}`};
  position: relative;
`;

const Label = styled(FieldLabel)`
  display: block;
  font-size: ${sizing(14)};
  color: ${colors.blackRock};
  ${semiBold};
  text-align: left;
  width: 100%;
  padding-bottom: ${spacing(2)};
`;

const Field = styled(BasicField)`
  text-align: left;
  color: ${colors.blackRock};
  padding-left: 0;
  width: 100%;
  align-self: flex-start;
`;

const Error = styled(FieldError)`
  padding: ${spacing(1)};
`;

export const Close = styled.button`
  border: none;
  background: transparent;
  font-size: ${sizing(24)};
  ${regular};
  color: ${colors.blackRock};
  position: absolute;
  top: 16px;
  right: 16px;

  &:focus {
    outline: none;
  }
`;

export const ExperienceInput = ({
  values,
  errors,
  isSingular,
  setFieldValue
}) => {
  return (
    <Wrapper>
      <FieldArray
        name="experience"
        render={arrayHelpers => (
          <Fragment>
            {!isSingular && (
              <Button
                primary
                type="button"
                onClick={() =>
                  arrayHelpers.unshift({
                    title: "",
                    company: "",
                    start_date: new Date().toISOString(),
                    end_date: new Date().toISOString(),
                    description: "",
                    create: true
                  })
                }
              >
                + Add Experience
              </Button>
            )}
            {isSingular &&
              !values.length &&
              arrayHelpers.unshift({
                title: "",
                company: "",
                start_date: new Date().toISOString(),
                end_date: new Date().toISOString(),
                description: "",
                create: true
              })}
            <ExperiencesContainer>
              {values.map((value, index) => (
                <ExperienceContainer isSingular={isSingular} key={index}>
                  {!isSingular && (
                    <Close
                      type="button"
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      &times;
                    </Close>
                  )}
                  <FieldWrapper style={{ flexDirection: "column" }}>
                    <Label>Job Title</Label>
                    <Field name={`experience.${index}.title`} />
                    <Error name={`experience.${index}.title`} />
                  </FieldWrapper>
                  <FieldWrapper style={{ flexDirection: "column" }}>
                    <Label>Company</Label>
                    <Field name={`experience.${index}.company`} />
                    <Error name={`experience.${index}.company`} />
                  </FieldWrapper>
                  <FieldWrapper
                    width="50%"
                    style={{
                      display: "inline-flex",
                      paddingRight: spacing(2),
                      flexDirection: "column",
                      alignItems: "flex-start"
                    }}
                  >
                    <Label>Start Date</Label>
                    <DateInput
                      defaultValue={values[index].start_date}
                      onChange={v => {
                        setFieldValue(
                          `experience.${index}.start_date`,
                          v.toISOString()
                        );
                      }}
                    />
                    <Error name={`experience.${index}.start_date`} />
                  </FieldWrapper>
                  <FieldWrapper
                    width="50%"
                    style={{
                      display: "inline-flex",
                      paddingLeft: spacing(2),
                      flexDirection: "column",
                      alignItems: "flex-start"
                    }}
                  >
                    <Label>End Date</Label>
                    <DateInput
                      defaultValue={values[index].end_date}
                      minDate={
                        new Date(values[index].start_date)
                          ? new Date(values[index].start_date)
                          : null
                      }
                      onChange={v => {
                        setFieldValue(
                          `experience.${index}.end_date`,
                          v === null ? null : v.toISOString()
                        );
                      }}
                      hasCurrent
                    />
                    <Error name={`experience.${index}.end_date`} />
                  </FieldWrapper>
                  <FieldWrapper style={{ flexDirection: "column" }}>
                    <Label>Description</Label>
                    <TextEditor
                      defaultValue={values[index].description}
                      onChange={v =>
                        setFieldValue(`experience.${index}.description`, v)
                      }
                    />

                    <Error name={`experience.${index}.description`} />
                  </FieldWrapper>
                </ExperienceContainer>
              ))}
            </ExperiencesContainer>
          </Fragment>
        )}
      />
    </Wrapper>
  );
};
