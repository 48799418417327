import React, { Fragment, Component } from "react";
import { Modal } from "components/Modal";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import { sizing, spacing } from "styles/sizing";
import colors from "styles/colors";
import { semiBold } from "styles/typography";
import { Loader } from "components/Loader";
import { Formik, Form } from "formik";
import { Button } from "components/Button";
import * as Yup from "yup";
import {
  FieldWrapper,
  FieldLabel,
  Field,
  FieldError
} from "components/FormField";
import { format } from "date-fns";
import template from "lodash.template";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: ${sizing(18)};
  color: ${colors.blackRock};
  ${semiBold};
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
`;
export class Document extends Component {
  render() {
    const {
      match: {
        params: { id }
      },
      User
    } = this.props;
    return (
      <Modal isOpen onRequestClose={() => this.props.history.push(`/settings`)}>
        <Query query={query} variables={{ id, user: User.id }}>
          {({ data, loading }) => (
            <Fragment>
              {loading && <Loader />}
              {data.document && (
                <Wrapper>
                  <Title>{data.document.name}</Title>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: template(data.document.text)({
                        legal_name: data.document.signatures[0]
                          ? data.document.signatures[0].legal_name
                          : "YOUR NAME",
                        date: data.document.signatures[0]
                          ? format(
                              data.document.signatures[0].createdAt,
                              "DD/MM/YYYY"
                            )
                          : format(new Date().toISOString(), "DD/MM/YYYY")
                      })
                    }}
                  />
                  {data.document.signatures[0] && (
                    <p>
                      Document signed on{" "}
                      {format(
                        data.document.signatures[0].createdAt,
                        "DD/MM/YYYY"
                      )}{" "}
                      by {data.document.signatures[0].legal_name}
                      {data.document.signatures[0].company && (
                        <span>
                          {" "}
                          on behalf of {data.document.signatures[0].company}
                        </span>
                      )}
                      .
                    </p>
                  )}
                  {data.document.signatures[0] && (
                    <Button
                      secondary
                      onClick={() => this.props.history.push(`/settings`)}
                      style={{ minHeight: "40px" }}
                    >
                      Close
                    </Button>
                  )}
                  {!data.document.signatures[0] && (
                    <Mutation mutation={updateDocument}>
                      {mutation => (
                        <Formik
                          initialValues={{ name: "", company: "" }}
                          validationSchema={Yup.object().shape({
                            legal_name: Yup.string().required(
                              "Please print your name"
                            )
                          })}
                          onSubmit={values => {
                            mutation({
                              variables: {
                                id,
                                legal_name: values.legal_name,
                                company: values.company,
                                user: User.id
                              }
                            });
                          }}
                        >
                          {({ isValid, isSubmitting }) => (
                            <Form>
                              <p>
                                To agree to the above please type you name and
                                company name and click sign.
                              </p>
                              <FieldWrapper>
                                <FieldLabel name="legal_name">
                                  Legal Name
                                </FieldLabel>
                                <Field name="legal_name" />
                                <FieldError name="legal_name" />
                              </FieldWrapper>
                              <FieldWrapper>
                                <FieldLabel name="company">
                                  Company (If Applicable)
                                </FieldLabel>
                                <Field name="company" />
                                <FieldError name="company" />
                              </FieldWrapper>
                              <ButtonContainer>
                                <Button
                                  style={{ marginRight: spacing(1) }}
                                  secondary
                                  onClick={() =>
                                    this.props.history.push(`/settings`)
                                  }
                                >
                                  Cancel
                                </Button>
                                <Button
                                  style={{ marginLeft: spacing(1) }}
                                  disabled={!isValid || isSubmitting}
                                  type="submit"
                                  primary
                                >
                                  Sign
                                </Button>
                              </ButtonContainer>
                            </Form>
                          )}
                        </Formik>
                      )}
                    </Mutation>
                  )}
                </Wrapper>
              )}
            </Fragment>
          )}
        </Query>
      </Modal>
    );
  }
}

const query = gql`
  query($id: ID!, $user: ID!) {
    document(where: { id: $id }) {
      id
      name
      text
      signatures(where: { user: { id: $user } }) {
        id
        legal_name
        company
        createdAt
      }
    }
  }
`;

const updateDocument = gql`
  mutation($id: ID!, $company: String!, $legal_name: String!, $user: ID!) {
    updateDocument(
      where: { id: $id }
      data: {
        signatures: {
          create: {
            legal_name: $legal_name
            company: $company
            user: { connect: { id: $user } }
          }
        }
      }
    ) {
      id
      signatures(where: { user: { id: $user } }) {
        id
        legal_name
        company
        createdAt
      }
    }
  }
`;
