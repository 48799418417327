import React, { Component, Fragment, lazy, Suspense } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import styled, { css } from "styled-components";
import { Header, NavigationItem } from "components/Header";
import { Redirect, Route } from "react-router-dom";
import { FlagsProvider, Flag } from "flag";
import { Profile } from "containers/Profile";
// import { ProfileModal } from "containers/Profile/ProfileModal";
import { Roles } from "containers/Roles";
import { Role } from "containers/Role";
import { Messages } from "containers/Messages";
import { Settings } from "containers/Settings";
import { Message } from "containers/Message";
import { tablet, isMobile } from "styles/media";
import { spacing } from "styles/sizing";
import { throttle } from "lodash";
import { Loader } from "components/Loader";
import { RoleApply } from "containers/RoleApply";
import { CreateOrEditRole } from "containers/CreateOrEditRole";
import { Consultants } from "containers/Consultants";
import { Consultant } from "containers/Consultant";
import { AdminDashboard } from "containers/AdminDashboard";
import { TagManager } from "containers/TagManager";
import { AddAdmin } from "containers/AddAdmin";
import { Clients } from "containers/Clients";
import { CreateClient } from "containers/CreateClient";
import { Client } from "containers/Client";
import { Organisations } from "containers/Organisations";
import { CreateOrganisation } from "containers/CreateOrganisation";
import { EmptyArea } from "containers/EmptyArea";
import { Organisation } from "containers/Organisation";
import { OrganisationJoin } from "containers/OrginisationJoin";
import { OrganisationDelete } from "containers/OrganisationDelete";
import { OrganisationEdit } from "containers/OrganisationEdit";
import { OrganisationInvite } from "containers/OrganisationInvite";
import { RolePropose } from "containers/RolePropose";
// import { Support } from "containers/Support";
import { DeleteAccount } from "containers/DeleteAccount";
import { CreateConsultant } from "containers/CreateConsultant";
import { ClientDashboard } from "containers/ClientDashboard";
import { EditProfile } from "containers/EditProfile";
import { EditSettings } from "containers/EditSettings";
import { Document } from "containers/Document";
import auth0Client from "auth/authClient";
import { TeamMember } from "containers/TeamMember";
import TeamContact from "containers/TeamContact";
import ConsultantContact from "containers/ConsultantContact";

const Support = lazy(() => import("containers/Support"));

const Main = styled.main`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 56px);

  ${tablet(css`
    flex-direction: row;
    height: calc(100% - ${spacing(12)});
  `)}
`;

export class App extends Component {
  state = {
    windowSize: window.innerWidth
  };

  componentDidMount() {
    window.addEventListener("resize", this.getWindowSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getWindowSize);
  }

  setWindowSize = () => {
    this.setState({ windowSize: window.innerWidth });
  };

  getWindowSize = throttle(this.setWindowSize, 1000);

  render() {
    return (
      <Suspense fallback={<Loader />}>
        <Query query={query}>
          {({ data, loading, error }) => {
            if (error) {
              auth0Client.signOut();
              return null;
            }

            if (!data || !data.User) {
              return <Loader />;
            }

            return (
              <Query
                query={documentQuery}
                variables={{
                  client: data.User && data.User.role === "CLIENT",
                  consultant: data.User && data.User.role === "CONSULTANT",
                  id: data.User.id
                }}
              >
                {({ data: documentData }) => {
                  if (!documentData || !documentData.documents) {
                    return <Loader />;
                  }

                  const flags = {
                    admin: data.User && data.User.role === "ADMIN",
                    client: data.User && data.User.role === "CLIENT",
                    consultant: data.User && data.User.role === "CONSULTANT",
                    clientOrAdmin: flags => flags.admin || flags.client,
                    consultantOrAdmin: flags => flags.admin || flags.consultant,
                    unsigned: documentData.documents.some(
                      i => !i.signatures.length
                    ),
                    signed: documentData.documents.some(
                      i => i.signatures.length
                    )
                  };

                  return (
                    <FlagsProvider flags={flags}>
                      <Fragment>
                        <Header>
                          <Flag name="consultant">
                            <NavigationItem
                              to="/dashboard"
                              icon="DashboardIcon"
                              text="Dashboard"
                            />
                            <NavigationItem
                              to="/roles"
                              icon="RolesIcon"
                              text="Roles"
                            />
                          </Flag>
                          <Flag name="admin">
                            <NavigationItem
                              to="/dashboard"
                              icon="DashboardIcon"
                              text="Dashboard"
                            />
                            <NavigationItem
                              to="/clients"
                              icon="ConsultantsIcon"
                              text="Clients"
                            />

                            <NavigationItem
                              to="/roles"
                              icon="RolesIcon"
                              text="Roles"
                            />
                            <NavigationItem
                              to="/consultants"
                              icon="ConsultantsIcon"
                              text="Consultants"
                            />
                          </Flag>
                          <Flag name="client">
                            <NavigationItem
                              to="/dashboard"
                              icon="DashboardIcon"
                              text="Dashboard"
                            />
                            <NavigationItem
                              to="/consultants"
                              icon="ConsultantsIcon"
                              text="Consultants"
                            />
                          </Flag>
                          <NavigationItem
                            to="/teams"
                            icon="OrganisationIcon"
                            text="Teams"
                          />
                          <Query
                            query={messagesCount}
                            variables={{
                              User: data.User.id
                            }}
                            pollInterval={5000}
                            fetchPolicy="cache-and-network"
                          >
                            {({ data: { messagesConnection } }) => (
                              <NavigationItem
                                to="/messages"
                                icon="ChatIcon"
                                text="Messages"
                                badge={
                                  messagesConnection &&
                                  messagesConnection.aggregate.count !== "0"
                                    ? messagesConnection.aggregate.count < 99
                                      ? messagesConnection.aggregate.count === 0
                                        ? null
                                        : messagesConnection.aggregate.count.toString()
                                      : "99+"
                                    : null
                                }
                              />
                            )}
                          </Query>
                          <NavigationItem
                            to="/settings"
                            icon="SettingsIcon"
                            text="Settings"
                          />
                        </Header>
                        <Main>
                          <Flag name="admin">
                            <Route
                              path="/dashboard"
                              component={AdminDashboard}
                            />
                            <Route
                              exact
                              path="/dashboard/tag-manager"
                              component={TagManager}
                            />
                            <Route
                              exact
                              path="/dashboard/add-admin"
                              component={AddAdmin}
                            />
                            <Route
                              path="/clients"
                              exact={isMobile()}
                              render={props => (
                                <Clients User={data.User} {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/clients/create-client"
                              component={CreateClient}
                            />
                            <Route
                              exact
                              path="/clients/:id"
                              component={Client}
                            />
                            <Route
                              path="/consultants"
                              exact={isMobile()}
                              render={props => (
                                <Consultants User={data.User} {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/consultants/create-consultant"
                              component={CreateConsultant}
                            />
                            <Route
                              path="/consultants/:id"
                              render={props => (
                                <Consultant User={data.User} {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/"
                              render={() => <Redirect to="/dashboard" />}
                            />
                            <Route
                              exact
                              path="/roles/create"
                              render={props => (
                                <CreateOrEditRole
                                  {...props}
                                  type="create"
                                  User={data.User}
                                />
                              )}
                            />
                            <Route
                              path="/consultants/:id/contact"
                              render={props => (
                                <ConsultantContact
                                  User={data.User}
                                  {...props}
                                />
                              )}
                            />
                          </Flag>
                          <Flag name="consultant">
                            <Route
                              exact
                              path="/dashboard/edit-profile"
                              render={props => (
                                <EditProfile User={data.User} {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/"
                              render={() => <Redirect to="/dashboard" />}
                            />
                            <Route
                              path="/dashboard"
                              render={props => (
                                <Profile User={data.User} {...props} />
                              )}
                            />
                            <Route
                              path="/roles/:id/apply"
                              render={props => (
                                <RoleApply User={data.User} {...props} />
                              )}
                            />
                          </Flag>
                          <Flag name="client">
                            <Route
                              exact
                              path="/"
                              render={() => <Redirect to="/dashboard" />}
                            />
                            <Route
                              path="/dashboard"
                              exact={isMobile()}
                              render={props => (
                                <ClientDashboard User={data.User} {...props} />
                              )}
                            />

                            <Route
                              exact
                              path="/roles/create"
                              render={props => (
                                <CreateOrEditRole
                                  {...props}
                                  type="create"
                                  User={data.User}
                                />
                              )}
                            />
                            <Route
                              path="/dashboard/:id"
                              render={props => (
                                <Role User={data.User} {...props} />
                              )}
                            />
                            <Route
                              path="/consultants"
                              exact={isMobile()}
                              render={props => (
                                <Consultants User={data.User} {...props} />
                              )}
                            />
                            <Route
                              path="/consultants/:id"
                              render={props => (
                                <Consultant User={data.User} {...props} />
                              )}
                            />
                            <Route
                              path="/consultants/:id/propose"
                              exact
                              render={props => (
                                <RolePropose User={data.User} {...props} />
                              )}
                            />

                            <Route
                              exact
                              path="/dashboard/:id/edit"
                              render={props => (
                                <CreateOrEditRole
                                  {...props}
                                  type="edit"
                                  User={data.User}
                                />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/:id/duplicate"
                              render={props => (
                                <CreateOrEditRole
                                  {...props}
                                  type="duplicate"
                                  User={data.User}
                                />
                              )}
                            />
                          </Flag>
                          <Route
                            path="/roles"
                            exact={isMobile()}
                            render={props => (
                              <Roles User={data.User} {...props} />
                            )}
                          />
                          <Route
                            path={"/roles/:id"}
                            render={props => (
                              <Role User={data.User} {...props} />
                            )}
                          />
                          <Route
                            path="/teams"
                            exact={isMobile()}
                            component={Organisations}
                          />
                          <Route
                            exact
                            path="/teams/create-team"
                            component={CreateOrganisation}
                          />
                          <Route
                            path="/teams/:id"
                            render={props => (
                              <Organisation User={data.User} {...props} />
                            )}
                          />
                          <Route
                            exact
                            path="/teams/:id/member/:member"
                            render={props => (
                              <TeamMember User={data.User} {...props} />
                            )}
                          />
                          <Route
                            exact
                            path="/teams/:id/contact/:member"
                            render={props => (
                              <TeamContact User={data.User} {...props} />
                            )}
                          />
                          <Route
                            exact
                            path="/teams/:id/join"
                            render={props => (
                              <OrganisationJoin User={data.User} {...props} />
                            )}
                          />
                          <Route
                            exact
                            path="/teams/:id/delete"
                            render={props => (
                              <OrganisationDelete User={data.User} {...props} />
                            )}
                          />
                          <Route
                            exact
                            path="/teams/:id/edit"
                            render={props => (
                              <OrganisationEdit User={data.User} {...props} />
                            )}
                          />
                          <Route
                            exact
                            path="/teams/:id/invite"
                            render={props => (
                              <OrganisationInvite User={data.User} {...props} />
                            )}
                          />
                          <Route
                            path="/messages"
                            exact={isMobile()}
                            render={props => (
                              <Messages User={data.User} {...props} />
                            )}
                          />
                          <Route
                            path="/messages/:id"
                            exact
                            render={props => (
                              <Message
                                User={data.User}
                                unsigned={documentData.documents.some(
                                  i => !i.signatures.length
                                )}
                                {...props}
                              />
                            )}
                          />
                          <Route path="/settings" component={Settings} />
                          <Route
                            path="/settings/documents/:id"
                            render={props => (
                              <Document User={data.User} {...props} />
                            )}
                          />
                          <Route
                            path="/settings/edit"
                            render={props => (
                              <EditSettings User={data.User} {...props} />
                            )}
                          />
                          <Route
                            path="/settings/support"
                            render={props => (
                              <Support User={data.User} {...props} />
                            )}
                          />
                          <Route
                            path="/settings/delete"
                            render={props => (
                              <DeleteAccount User={data.User} {...props} />
                            )}
                          />
                          <Route
                            exact
                            path={"/clients"}
                            component={EmptyArea}
                          />
                          <Route exact path={"/teams"} component={EmptyArea} />
                          <Route
                            exact
                            path={"/teams/create-team"}
                            component={EmptyArea}
                          />
                          <Route exact path={"/roles"} component={EmptyArea} />
                          <Route
                            exact
                            path={"/consultants"}
                            component={EmptyArea}
                          />
                          <Route
                            exact
                            path={"/consultants/create-consultant"}
                            component={EmptyArea}
                          />
                          <Route
                            exact
                            path={"/messages"}
                            component={EmptyArea}
                          />
                          <Flag name="client">
                            <Route
                              exact
                              path={"/dashboard"}
                              component={EmptyArea}
                            />
                          </Flag>
                        </Main>
                      </Fragment>
                    </FlagsProvider>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </Suspense>
    );
  }
}

export default App;

const query = gql`
  query {
    User {
      id
      email
      role
    }
  }
`;

const documentQuery = gql`
  query($id: ID!, $client: Boolean, $consultant: Boolean) {
    documents(where: { client: $client, consultant: $consultant }) {
      id
      name
      signatures(where: { user: { id: $id } }) {
        id
      }
    }
  }
`;

const messagesCount = gql`
  query($User: ID!) {
    messagesConnection(
      where: {
        AND: {
          read_reciept: false
          conversation: {
            OR: [
              { recipient: { id: $User } }
              { sender: { id: $User } }
              {
                organisation: {
                  OR: [
                    { owner: { id: $User } }
                    { managers_some: { id: $User } }
                  ]
                }
              }
            ]
          }
          user: { id_not: $User }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
