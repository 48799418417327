import styled from "styled-components";
import { semiBold, bold, regular } from "styles/typography";
import colors from "styles/colors";
import { spacing, sizing } from "styles/sizing";

export const Wrapper = styled.div`
  padding: 0 ${spacing(3)};
  height: 100vh;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 2;
`;

export const Contact = styled.span`
  height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${bold};
  font-size: ${sizing(12)};
  margin: 0;
  color: ${colors.blackRock};
  padding-top: ${spacing()};
  padding-bottom: ${spacing()};
  display: block;
  white-space: nowrap;
  text-transform: capitalize;
`;

export const EditorArea = styled.textarea`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  max-height: 150px;
  border: none;
  border-top: 1px solid ${colors.iris};
  border-bottom: 1px solid ${colors.snuff};
  padding: ${spacing(2)};
  ${regular};
  font-size: ${sizing(16)};
  color: ${colors.blackRock};
  line-height: ${sizing(24)};

  &:focus {
    outline: none;
  }
`;

export const Footer = styled.div`
  padding: ${spacing(2)} ${spacing(2)};
  width: 100%;
`;

export const DateDisplay = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  ${bold};
  color: ${colors.palatinateBlue};
  font-size: ${sizing(12)};
`;

export const Avatar = styled.div`
  padding-right: ${spacing()};
`;

export const Image = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: url(${props => props.url});
  background-position: center;
  background-size: cover;
`;

export const Content = styled.div`
  flex: 1;
`;

export const TopStatusBar = styled.div`
  width: 100%;
  background: ${colors.palatinateBlue};
  padding: ${spacing(1)} ${spacing(2)};
  ${semiBold};
  color: ${colors.white};
  font-size: ${sizing(14)};
  text-align: center;
`;
