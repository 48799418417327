import React from "react";
import {
  shape,
  arrayOf,
  oneOf,
  string,
  object,
  func,
  oneOfType,
  element
} from "prop-types";
import { Formik } from "formik";
import {
  FieldWrapper,
  FieldLabel,
  FieldError,
  Field
} from "components/FormField";
import { TextEditor } from "components/TextEditor";
import { TagInput } from "components/TagInput";
import gql from "graphql-tag";
import { Autocomplete } from "components/Autocomplete";
import { experienceLevels } from "constants/experienceLevels";
import { locations } from "constants/locations";
import { ImageUploader } from "components/ImageUploader";
import { ExperienceInput } from "components/ExperienceInput";
import { ToggleSwitch } from "components/ToggleSwitch";
import { Button } from "components/Button";
import * as Yup from "yup";

export const Form = ({ config, onSubmit }) => {
  const validationSchema = config.fields.reduce((acc, curr) => {
    if (curr.type === "string") {
      const string = curr.required
        ? Yup.string().required(curr.errorMessage)
        : Yup.string();
      acc[curr.name] = string;
    }

    if (curr.type === "email") {
      const string = curr.required
        ? Yup.string()
            .email()
            .required("A valid email address is required.")
        : Yup.string().email();
      acc[curr.name] = string;
    }

    if (curr.type === "phone") {
      const string = curr.required
        ? Yup.string()
            .matches(/^[+#*()[\]]*([0-9][ ext+-pw#*()[\]]*){6,45}$/, {
              excludeEmptyString: true,
              message: "Please enter a valid phone number"
            })
            .required("Please enter a phone number")
        : Yup.string().matches(/^[+#*()[\]]*([0-9][ ext+-pw#*()[\]]*){6,45}$/, {
            excludeEmptyString: true,
            message: "Please enter a valid phone number"
          });
      acc[curr.name] = string;
    }

    if (curr.type === "checkbox") {
      const string = curr.required
        ? Yup.mixed().oneOf([true], curr.errorMessage)
        : Yup.mixed().oneOf([true, false], curr.errorMessage);
      acc[curr.name] = string;
    }

    return acc;
  }, {});

  const initialValues = config.fields.reduce((acc, curr) => {
    if (curr.type === "string") {
      acc[curr.name] = "";
    }

    if (curr.type === "phone") {
      acc[curr.name] = "";
    }

    if (curr.type === "email") {
      acc[curr.name] = "";
    }

    if (curr.type === "number") {
      acc[curr.name] = 0;
    }

    if (curr.type === "text-area") {
      acc[curr.name] = "";
    }

    if (curr.type === "experience") {
      acc[curr.name] = [
        {
          start_date: new Date(),
          end_date: null,
          title: "",
          company: "",
          description: ""
        }
      ];
    }

    if (curr.type === "experience-level") {
      acc[curr.name] = {};
    }

    if (curr.type === "location") {
      acc[curr.name] = {};
    }

    if (curr.type === "checkbox") {
      acc[curr.name] = false;
    }

    if (curr.type === "password") {
      acc[curr.name] = "";
    }
    return acc;
  }, {});

  return (
    <Formik
      validateOnBlur
      validateOnChange
      isInitialValid
      validationSchema={Yup.object().shape(validationSchema)}
      initialValues={{ ...initialValues, ...config.initialValues }}
      onSubmit={onSubmit}
    >
      {({
        values,
        setFieldValue,
        isSubmitting,
        isValid,
        handleSubmit,
        handleChange
      }) => (
        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
          {config.fields.map((field, index) => {
            if (field.type === "text-area") {
              return (
                <FieldWrapper key={`field---${index}`}>
                  <FieldLabel name={field.name}>{field.label}</FieldLabel>
                  <TextEditor
                    defaultValue={values[field.name]}
                    name={field.name}
                    onChange={value => setFieldValue(field.name, value)}
                  />
                  <FieldError name={field.name} />
                </FieldWrapper>
              );
            }

            if (field.type === "string") {
              return (
                <FieldWrapper key={`field---${index}`}>
                  <FieldLabel name={field.name}>{field.label}</FieldLabel>
                  <Field
                    onChange={handleChange}
                    type="text"
                    {...field.options}
                    name={field.name}
                  />
                  <FieldError name={field.name} />
                </FieldWrapper>
              );
            }

            if (field.type === "number") {
              return (
                <FieldWrapper key={`field---${index}`}>
                  <FieldLabel name={field.name}>{field.label}</FieldLabel>
                  <Field type="number" {...field.options} name={field.name} />
                  <FieldError name={field.name} />
                </FieldWrapper>
              );
            }

            if (field.type === "email") {
              return (
                <FieldWrapper key={`field---${index}`}>
                  <FieldLabel name={field.name}>{field.label}</FieldLabel>
                  <Field type="email" {...field.options} name={field.name} />
                  <FieldError name={field.name} />
                </FieldWrapper>
              );
            }

            if (field.type === "phone") {
              return (
                <FieldWrapper key={`field---${index}`}>
                  <FieldLabel name={field.name}>{field.label}</FieldLabel>
                  <Field type="text" {...field.options} name={field.name} />
                  <FieldError name={field.name} />
                </FieldWrapper>
              );
            }

            if (field.type === "password") {
              return (
                <FieldWrapper key={`field---${index}`}>
                  <FieldLabel name={field.name}>{field.label}</FieldLabel>
                  <Field type="password" {...field.options} name={field.name} />
                  <FieldError name={field.name} />
                </FieldWrapper>
              );
            }

            if (field.type === "tag") {
              return (
                <FieldWrapper key={`field---${index}`}>
                  <FieldLabel name={field.name}>{field.label}</FieldLabel>
                  <TagInput query={queryTags} values={values[field.name]} />
                  <FieldError name={field.name} />
                </FieldWrapper>
              );
            }

            if (field.type === "experience-level") {
              return (
                <FieldWrapper key={`field---${index}`}>
                  <FieldLabel name={field.name}>{field.label}</FieldLabel>
                  <Autocomplete
                    isSingle
                    items={Object.keys(experienceLevels).map(key => ({
                      id: key,
                      isActive: key === values[field.name].id,
                      value: experienceLevels[key]
                    }))}
                    onChange={value => setFieldValue(field.name, value)}
                  />
                  <FieldError name={field.name} />
                </FieldWrapper>
              );
            }

            if (field.type === "location") {
              return (
                <FieldWrapper key={`field---${index}`}>
                  <FieldLabel name={field.name}>{field.label}</FieldLabel>
                  <Autocomplete
                    isSingle
                    items={Object.keys(locations).map(key => ({
                      id: key,
                      isActive: key === values[field.name].id,
                      value: locations[key]
                    }))}
                    onChange={value => setFieldValue(field.name, value)}
                  />
                  <FieldError name={field.name} />
                </FieldWrapper>
              );
            }

            if (field.type === "image") {
              return (
                <FieldWrapper key={`field---${index}`}>
                  <FieldLabel name={field.name}>{field.label}</FieldLabel>
                  <ImageUploader
                    onChange={file => setFieldValue(field.name, file)}
                    image={values[field.name]}
                  />
                  <FieldError name={field.name} />
                </FieldWrapper>
              );
            }

            if (field.type === "experience") {
              return (
                <FieldWrapper key={`field---${index}`}>
                  <FieldLabel name={field.name}>{field.label}</FieldLabel>
                  <ExperienceInput
                    values={values[field.name]}
                    {...field.options}
                    setFieldValue={setFieldValue}
                  />
                  <FieldError name={field.name} />
                </FieldWrapper>
              );
            }

            if (field.type === "toggle") {
              return (
                <FieldWrapper key={`field---${index}`}>
                  <FieldLabel name={field.name}>{field.label}</FieldLabel>
                  <ToggleSwitch
                    value={values.part_time}
                    {...field.options}
                    onChange={value => setFieldValue(field.name, value)}
                  />
                  <FieldError name={field.name} />
                </FieldWrapper>
              );
            }

            if (field.type === "checkbox") {
              return (
                <FieldWrapper key={`field---${index}`}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Field
                      style={{ width: "auto", marginRight: "20px" }}
                      type="checkbox"
                      name={field.name}
                      {...field.options}
                    />
                    <FieldLabel name={field.name}>{field.label}</FieldLabel>
                  </div>
                  <FieldError name={field.name} />
                </FieldWrapper>
              );
            }

            return null;
          })}
          <Button type="submit" primary disabled={isSubmitting || !isValid}>
            {config.submitText}
          </Button>
        </form>
      )}
    </Formik>
  );
};

const queryTags = gql`
  query tags($search: String!) {
    tags(where: { value_contains: $search }) {
      id
      value
    }
  }
`;

Form.propTypes = {
  config: shape({
    fields: arrayOf(
      shape({
        name: string,
        label: oneOfType([string, element]),
        type: oneOf([
          "text-area",
          "string",
          "number",
          "email",
          "phone",
          "tag",
          "experience-level",
          "location",
          "image",
          "experience",
          "toggle",
          "checkbox",
          "password"
        ]),
        options: object
      })
    ),
    submitText: oneOfType([string, element])
  }),
  onSubmit: func
};

Form.defaultProps = {
  config: {
    submitText: "submit"
  }
};
