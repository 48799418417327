import React, { Fragment } from "react";
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import { Loader } from "components/Loader";
import { PageLayout, PageHeader } from "components/PageLayout";
import { Title, Members, WebsiteLink } from "./style";
import { TextEditor } from "components/TextEditor";
import { StyledProgressBar } from "containers/Profile/styles";
import OrganisationsDefault from "../../assets/images/Organisations.jpg";
import { Button } from "components/Button";
import { FlagsProvider, Flag } from "flag";
import { PageSubTitle } from "components/PageHeader";
import { PageTitle } from "components/PageTitle";
import {
  UserCard,
  UserName,
  UserAction,
  UserImage,
  UserRole
} from "components/UserCard";
import { ConfirmationDialog } from "components/ConfirmationDialog";

export const Organisation = ({
  User: { id: UserID },
  match: {
    params: { id }
  },
  history: { push }
}) => {
  if (id === "create-team") {
    return null;
  }

  return (
    <Query variables={{ id }} query={query} fetchPolicy="cache-and-network">
      {({ data, loading }) => {
        if (loading && (!data || !data.organisation)) {
          return <Loader />;
        }

        if (data && data.organisation) {
          const flags = {
            owner: data.organisation.owner.id === UserID,
            manager: data.organisation.managers.some(i => i.id === UserID),
            member: data.organisation.members.some(i => i.id === UserID),
            notJoined: flag => !flag.owner && !flag.manager && !flag.member,
            canEdit: flag => flag.owner || flag.manager,
            managerOrMember: flag => flag.manager || flag.member
          };
          return (
            <PageLayout
              isDual
              leftAligned
              backLink="/teams"
              header={
                <PageHeader>
                  <StyledProgressBar
                    profile_completeness={100}
                    image={data.organisation.logo || OrganisationsDefault}
                  />
                  <PageTitle>{data.organisation.name}</PageTitle>
                  {data.organisation.website && (
                    <PageSubTitle>
                      <WebsiteLink href={data.organisation.website}>
                        Visit Website
                      </WebsiteLink>
                    </PageSubTitle>
                  )}
                </PageHeader>
              }
            >
              <PageSubTitle>Description</PageSubTitle>
              <TextEditor
                readOnly
                key={data.organisation.id}
                defaultValue={data.organisation.description}
              />
              <FlagsProvider flags={flags}>
                <Fragment>
                  <PageSubTitle>Members</PageSubTitle>
                  <Members>
                    {[
                      {
                        ...data.organisation.owner,
                        owner: true
                      },
                      ...data.organisation.managers.map(i => ({
                        ...i,
                        manager: true
                      })),
                      ...data.organisation.members.map(i => ({
                        ...i,
                        member: true
                      }))
                    ].map((member, index) => (
                      <UserCard key={member.id + index}>
                        <UserImage src={member.avatar} />

                        <UserName>{`${member.first_names} ${
                          member.last_name
                        }`}</UserName>
                        {member.owner && <UserRole>Owner</UserRole>}
                        {member.manager && <UserRole>Manager</UserRole>}
                        {member.member && <UserRole>Member</UserRole>}
                        <Flag name="canEdit">
                          <Mutation mutation={UpdateUsers}>
                            {mutation => (
                              <Fragment>
                                {member.manager && (
                                  <Flag name="owner">
                                    <UserAction
                                      primary
                                      onClick={() =>
                                        ConfirmationDialog({
                                          message:
                                            "Are you sure you wish to transfer ownership?",
                                          primaryText: "Transfer",
                                          primaryAction: () =>
                                            mutation({
                                              variables: {
                                                owner: {
                                                  connect: { id: member.id }
                                                },
                                                managers: {
                                                  disconnect: { id: member.id }
                                                },
                                                id
                                              }
                                            }),
                                          secondaryText: "Cancel",
                                          secondaryAction: () => null
                                        })
                                      }
                                    >
                                      Transfer Ownership
                                    </UserAction>
                                  </Flag>
                                )}
                                {member.member && (
                                  <UserAction
                                    primary
                                    onClick={() =>
                                      mutation({
                                        variables: {
                                          members: {
                                            disconnect: { id: member.id }
                                          },
                                          managers: {
                                            connect: { id: member.id }
                                          },
                                          id
                                        }
                                      })
                                    }
                                  >
                                    Make Manager
                                  </UserAction>
                                )}
                                {member.manager && UserID !== member.id && (
                                  <UserAction
                                    secondary
                                    onClick={() =>
                                      mutation({
                                        variables: {
                                          members: {
                                            connect: { id: member.id }
                                          },
                                          managers: {
                                            disconnect: { id: member.id }
                                          },
                                          id
                                        }
                                      })
                                    }
                                  >
                                    Make Member
                                  </UserAction>
                                )}
                                {UserID !== member.id && (
                                  <UserAction
                                    secondary
                                    onClick={() =>
                                      push(`/teams/${id}/member/${member.id}`)
                                    }
                                  >
                                    View Profile
                                  </UserAction>
                                )}
                                {UserID !== member.id && (
                                  <UserAction
                                    secondary
                                    onClick={() =>
                                      push(`/teams/${id}/contact/${member.id}`)
                                    }
                                  >
                                    Contact
                                  </UserAction>
                                )}
                                {!member.owner && UserID !== member.id && (
                                  <UserAction
                                    secondary
                                    onClick={() =>
                                      ConfirmationDialog({
                                        message:
                                          "Are you sure you wish to delete this member?",
                                        primaryText: "Delete",
                                        primaryAction: () =>
                                          mutation({
                                            variables: {
                                              members: member.member
                                                ? {
                                                    disconnect: {
                                                      id: member.id
                                                    }
                                                  }
                                                : undefined,
                                              managers: member.manager
                                                ? {
                                                    disconnect: {
                                                      id: member.id
                                                    }
                                                  }
                                                : undefined,
                                              id
                                            }
                                          }),
                                        secondaryText: "Cancel",
                                        secondaryAction: () => null
                                      })
                                    }
                                  >
                                    Delete
                                  </UserAction>
                                )}
                              </Fragment>
                            )}
                          </Mutation>
                        </Flag>
                      </UserCard>
                    ))}
                  </Members>
                  <Title>Actions</Title>

                  <Fragment>
                    <Flag name="notJoined">
                      <Button primary onClick={() => push(`/teams/${id}/join`)}>
                        Join
                      </Button>
                    </Flag>
                    <Flag name="canEdit">
                      <Button
                        primary
                        onClick={() => push(`/teams/${id}/invite`)}
                      >
                        Invite
                      </Button>
                      <Button
                        secondary
                        onClick={() => push(`/teams/${id}/edit`)}
                      >
                        Edit
                      </Button>
                    </Flag>
                    <Flag name="owner">
                      <Button
                        secondary
                        onClick={() => push(`/teams/${id}/delete`)}
                      >
                        Delete
                      </Button>
                    </Flag>
                    <Flag name="managerOrMember">
                      <Mutation mutation={UpdateUsers}>
                        {mutation => (
                          <Button
                            secondary
                            onClick={() =>
                              ConfirmationDialog({
                                message:
                                  "Are you sure you wish to leave this group?",
                                primaryText: "Leave",
                                primaryAction: () =>
                                  mutation({
                                    variables: {
                                      members: data.organisation.members.find(
                                        i => i.id === UserID
                                      )
                                        ? {
                                            disconnect: {
                                              id: UserID
                                            }
                                          }
                                        : undefined,
                                      managers: data.organisation.managers.find(
                                        i => i.id === UserID
                                      )
                                        ? {
                                            disconnect: {
                                              id: UserID
                                            }
                                          }
                                        : undefined,
                                      id
                                    }
                                  }),
                                secondaryText: "Cancel",
                                secondaryAction: () => null
                              })
                            }
                          >
                            Leave Team
                          </Button>
                        )}
                      </Mutation>
                    </Flag>
                  </Fragment>
                </Fragment>
              </FlagsProvider>
            </PageLayout>
          );
        }
      }}
    </Query>
  );
};

const query = gql`
  query($id: ID!) {
    organisation(where: { id: $id }) {
      id
      name
      description
      website
      logo
      owner {
        id
        avatar
        first_names
        last_name
      }
      managers {
        id
        avatar
        first_names
        last_name
      }
      members {
        id
        avatar
        first_names
        last_name
      }
    }
  }
`;

const UpdateUsers = gql`
  mutation(
    $members: UserUpdateManyWithoutOrganisationMemberInput
    $managers: UserUpdateManyWithoutOrganisationManagerInput
    $owner: UserUpdateOneWithoutOrganisationOwnershipInput
    $id: ID!
  ) {
    updateOrganisation(
      members: $members
      managers: $managers
      owner: $owner
      where: { id: $id }
    ) {
      id
      owner {
        id
        avatar
        first_names
        last_name
      }
      managers {
        id
        avatar
        first_names
        last_name
      }
      members {
        id
        avatar
        first_names
        last_name
      }
    }
  }
`;
