import React, { Component, Fragment } from "react";
import Dropzone from "react-dropzone";
import { spacing, sizing } from "styles/sizing";
import styled, { css } from "styled-components";
import colors from "styles/colors";
import { regular } from "styles/typography";
import { tablet } from "styles/media";

const dropzoneStyle = {
  width: "100%",
  height: "auto",
  borderWidth: 2,
  borderColor: "rgb(102, 102, 102)",
  borderStyle: "dashed",
  borderRadius: 5
};

const Wrapper = styled.div`
  position: relative;
  padding: ${spacing(1)};
  border-radius: 50%;
  border: 1px dashed ${colors.blackRock};
  height: 120px;
  width: 120px;
  margin: 0 auto;
  ${tablet(css`
    margin: 0;
  `)}
  margin-bottom: ${spacing(1)};
  margin-top: ${spacing(1)};
  transition: border 0.4s ease;
  cursor: pointer;

  &:after {
    content: "+";
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    pointer-events: none;
    color: ${colors.white};
    font-size: ${sizing(64)};
    ${regular};

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        border: 1px dashed ${colors.carribeanGreen};
        &:after {
          opacity: 1;
        }
      }
    `}

  &:focus {
    outline: none;
  }
`;

const Image = styled.div`
  border-radius: 50%;
  width: 100%;
  height: 100%;

  background-image: url(${props => props.src});
  background-position: 50% 50%;
  background-size: cover;
`;

export const ImageUploader = ({ image, onChange, disabled }) => (
  <Dropzone
    style={dropzoneStyle}
    accept="image/*"
    disabled={disabled}
    onDrop={acceptedFiles => {
      if (acceptedFiles.length === 0) {
        return;
      }
      onChange(acceptedFiles[0]);
    }}
  >
    {({
      isDragActive,
      isDragReject,
      getInputProps,
      getRootProps,
      acceptedFiles,
      rejectedFiles
    }) => {
      if (isDragActive) {
        return "This file is authorized";
      }

      if (isDragReject) {
        return "This file is not authorized";
      }
      return (
        <Wrapper {...getRootProps()} disabled={disabled}>
          <input {...getInputProps()} />
          {typeof image === "string" ? (
            <Fragment>
              <Image src={image} />
            </Fragment>
          ) : (
            <Thumb file={image} />
          )}
        </Wrapper>
      );
    }}
  </Dropzone>
);

class Thumb extends Component {
  state = {
    loading: false,
    thumb: undefined
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) {
      return;
    }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;

    if (!file) {
      return null;
    }

    if (loading) {
      return <p>loading...</p>;
    }

    return <Image src={thumb} alt={file.name} />;
  }
}

ImageUploader.defaultProps = {
  image: "https://s3-eu-west-1.amazonaws.com/cc-app-uploads/user.jpg"
};
