import React, { Component, Fragment } from "react";
import { Modal } from "components/Modal";
import { Query, Mutation } from "react-apollo";
import { Loader } from "components/Loader";
import gql from "graphql-tag";
import { CancellableTag } from "components/Tag";
import { Title, Header, Body } from "./style";
import { Footer } from "glamorous";
import { Button } from "components/Button";
import { Search } from "components/Search";

export class TagManager extends Component {
  state = {
    search: ""
  };

  updateSearch = e => {
    this.setState({
      search: e.target.value.toLowerCase()
    });
  };

  render() {
    return (
      <Modal
        isOpen
        onRequestClose={() => this.props.history.push("/dashboard")}
      >
        <Header>
          <Title>Tag Manager</Title>
          <Search
            type="text"
            placeholder="Search"
            name="search"
            onChange={this.updateSearch}
          />
        </Header>
        <Body>
          <Mutation
            mutation={deleteTag}
            update={(
              cache,
              {
                data: {
                  deleteTag: { id }
                }
              }
            ) => {
              const { tags } = cache.readQuery({
                query: queryTags,
                variables: { search: this.state.search }
              });
              cache.writeQuery({
                query: queryTags,
                variables: { search: this.state.search },
                data: { tags: tags.filter(e => e.id !== id) }
              });
            }}
          >
            {mutation => (
              <Query
                query={queryTags}
                variables={{
                  search: this.state.search
                }}
                fetchPolicy={"cache-and-network"}
              >
                {({ data, loading, refetch }) => (
                  <Fragment>
                    {loading && !data && <Loader />}
                    {data && data.tags && (
                      <div>
                        {data.tags.map(tag => (
                          <CancellableTag
                            key={tag.id}
                            onClick={() => {
                              mutation({
                                variables: {
                                  id: tag.id
                                }
                              });
                            }}
                          >
                            {tag.value}
                          </CancellableTag>
                        ))}
                      </div>
                    )}
                  </Fragment>
                )}
              </Query>
            )}
          </Mutation>
        </Body>
        <Footer>
          <Button onClick={() => this.props.history.push("/dashboard")} primary>
            Close
          </Button>
        </Footer>
      </Modal>
    );
  }
}

const queryTags = gql`
  query tags($search: String!) {
    tags(where: { value_contains: $search }) {
      id
      value
    }
  }
`;

const deleteTag = gql`
  mutation deleteTag($id: ID!) {
    deleteTag(where: { id: $id }) {
      id
    }
  }
`;
