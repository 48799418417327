import React from "react";
import colors from "styles/colors";

export const ApplicationSent = ({ style }) => {
  const color = colors.liquorice;
  return (
    <svg style={style} width="100%" viewBox="0 0 228 242">
      <defs>
        <ellipse id="a" cx="92.8" cy="92.6468647" rx="92.8" ry="92.6468647" />
        <ellipse id="c" cx="122.4" cy="130.184818" rx="92.8" ry="92.6468647" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero" transform="translate(28.8 3.993399)">
          <ellipse
            cx="94.4"
            cy="126.191419"
            fill={colors.liquorice}
            rx="92.8"
            ry="92.6468647"
          />
          <ellipse
            cx="28.4"
            cy="232.016502"
            fill={color}
            opacity=".2"
            rx="6"
            ry="5.99009901"
            transform="matrix(-1 0 0 1 56.8 0)"
          />
          <ellipse
            cx="193.2"
            cy="217.640264"
            fill={color}
            opacity=".2"
            rx="6"
            ry="5.99009901"
            transform="matrix(-1 0 0 1 386.4 0)"
          />
          <ellipse
            cx="182.8"
            cy="197.673267"
            fill={color}
            opacity=".2"
            rx="3.6"
            ry="3.59405941"
            transform="matrix(-1 0 0 1 365.6 0)"
          />
          <ellipse
            cx="141.2"
            cy="19.5676568"
            fill={color}
            opacity=".2"
            rx="3.6"
            ry="3.59405941"
            transform="matrix(-1 0 0 1 282.4 0)"
          />
          <ellipse
            cx="5.2"
            cy="19.5676568"
            fill={color}
            opacity=".2"
            rx="3.6"
            ry="3.59405941"
            transform="matrix(-1 0 0 1 10.4 0)"
          />
          <ellipse
            cx="23.6"
            cy="5.99009901"
            fill={color}
            opacity=".2"
            rx="3.6"
            ry="3.59405941"
            transform="matrix(-1 0 0 1 47.2 0)"
          />
          <ellipse
            cx="139.2"
            cy="16.7722772"
            stroke={color}
            strokeWidth="1.6"
            rx="3.2"
            ry="3.19471947"
          />
          <ellipse
            cx="3.2"
            cy="16.7722772"
            stroke={color}
            strokeWidth="1.6"
            rx="3.2"
            ry="3.19471947"
          />
          <ellipse
            cx="180"
            cy="194.877888"
            stroke={color}
            strokeWidth="1.6"
            rx="3.2"
            ry="3.19471947"
          />
          <ellipse
            cx="21.6"
            cy="3.19471947"
            stroke={color}
            strokeWidth="1.6"
            rx="3.2"
            ry="3.19471947"
          />
          <ellipse
            cx="189.6"
            cy="214.844884"
            stroke={color}
            strokeWidth="1.6"
            rx="4.8"
            ry="4.79207921"
          />
          <ellipse
            cx="25.6"
            cy="228.422442"
            stroke={color}
            strokeWidth="1.6"
            rx="4.8"
            ry="4.79207921"
          />
        </g>
        <g fill="#FFF" fillRule="nonzero">
          <path d="M93.6 187.689769H152v9.58415842H93.6z" />
          <path
            stroke="#313347"
            strokeWidth="1.6"
            d="M152 187.689769v9.584159m-58.4 0v-9.584159M106.4 75.0759078V55.907591h32.8v19.1683168"
          />
          <path
            stroke="#313347"
            strokeWidth="1.6"
            d="M93.53050143 91.8468649h57.73899717L139.055809 74.2785481h-33.311618L93.53050143 91.8468649zM92.8 111.8151818V92.6468649H152v19.1683169M88 111.8165019h68.8v75.8719472H88z"
          />
        </g>
        <g transform="translate(30.4 36.739274)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <g fillRule="nonzero" mask="url(#b)">
            <path
              fill="#FFF"
              stroke="#313347"
              strokeWidth="1.6"
              d="M57.6 160.535974h69.6v43.9247525H57.6z"
            />
            <g fill="#313347">
              <path d="M64.8 170.118812h1.6v24.7590759h-1.6zM73.6 170.118812h1.6v24.7590759h-1.6zM82.4 170.118812H84v24.7590759h-1.6zM91.2 170.118812h1.6v24.7590759h-1.6zM100 170.118812h1.6v24.7590759H100zM108.8 170.118812h1.6v24.7590759h-1.6zM117.6 170.118812h1.6v24.7590759h-1.6z" />
            </g>
          </g>
        </g>
        <ellipse
          cx="122.8"
          cy="146.557756"
          fill="#DCD9FA"
          fillRule="nonzero"
          stroke="#313347"
          strokeWidth="1.6"
          rx="28.4"
          ry="28.3531353"
        />
        <ellipse
          cx="122.8"
          cy="146.557756"
          fill="#FFF"
          fillRule="nonzero"
          stroke="#313347"
          strokeWidth="1.6"
          rx="22"
          ry="21.9636964"
        />
        <g fill="#313347" fillRule="nonzero">
          <path d="M95.2 179.70297h1.6v7.18811881h-1.6zM104 179.70297h1.6v7.18811881H104zM112.8 179.70297h1.6v7.18811881h-1.6zM121.6 179.70297h1.6v7.18811881h-1.6zM130.4 179.70297h1.6v7.18811881h-1.6zM139.2 179.70297h1.6v7.18811881h-1.6zM148 179.70297h1.6v7.18811881H148z" />
        </g>
        <g fill="#313347" fillRule="nonzero">
          <path d="M102.63461538 167.05517147l-1.13137085-1.13137085 5.08276755-5.08276755 1.13137085 1.13137085zM138.21398828 131.47579857l-1.13137085-1.13137085 5.08276755-5.08276755 1.13137085 1.13137085zM121.6 117.405941h1.6v7.18811881h-1.6zM121.6 167.7227727h1.6v7.18811881h-1.6zM151.152475 145.358416v1.6h-7.18811881v-1.6zM100.8356433 145.358416v1.6h-7.18811881v-1.6z" />
          <g>
            <path d="M143.29675547 165.92380062l-1.13137085 1.13137085-5.08276755-5.08276755 1.13137085-1.13137085zM107.71738257 130.34442772l-1.13137085 1.13137085-5.08276755-5.08276755 1.13137085-1.13137085z" />
          </g>
          <g>
            <path d="M121.6 131.782179h1.6v16.7722772h-1.6z" />
            <path d="M121.6 146.9570965h11.2v1.59735974h-11.2z" />
          </g>
        </g>
        <g fill="#313347" fillRule="nonzero">
          <path d="M95.2 103.828383h1.6v7.18811881h-1.6zM104 103.828383h1.6v7.18811881H104zM112.8 103.828383h1.6v7.18811881h-1.6zM121.6 103.828383h1.6v7.18811881h-1.6zM130.4 103.828383h1.6v7.18811881h-1.6zM139.2 103.828383h1.6v7.18811881h-1.6zM148 103.828383h1.6v7.18811881H148z" />
        </g>
        <g fill="#313347" fillRule="nonzero">
          <path d="M104 84.660066h1.6v7.18811881H104zM112.8 84.660066h1.6v7.18811881h-1.6zM121.6 84.660066h1.6v7.18811881h-1.6zM130.4 84.660066h1.6v7.18811881h-1.6zM139.2 84.660066h1.6v7.18811881h-1.6z" />
        </g>
        <g fill="#313347" fillRule="nonzero">
          <path d="M112.8 67.0891089h1.6v7.18811881h-1.6zM121.6 67.0891089h1.6v7.18811881h-1.6zM121.6 47.9207921h1.6v7.18811881h-1.6zM121.6 0h1.6v19.1683168h-1.6zM130.4 67.0891089h1.6v7.18811881h-1.6z" />
        </g>
        <use
          fillRule="nonzero"
          stroke="#FFFFFF"
          strokeWidth="1.6"
          xlinkHref="#c"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#313347"
          strokeWidth="1.6"
          d="M122.39951 16.3831378l-8.606554 39.5231328h17.994755l-9.388201-39.5231328z"
        />
        <path
          fill="#FFF"
          stroke="#313347"
          strokeWidth="1.6"
          d="M178.996196 86.9625058c-2.4995 1e-7-4.809133-1.3105442-6.058883-3.4379635-1.249751-2.1274194-1.249751-4.7485082 0-6.8759275 1.24975-2.1274194 3.559383-3.4379637 6.058883-3.4379636.890233-.0005565 1.772207.167759 2.597535.4957118l1.041928.413534.257506-1.0736015c1.012989-4.233027 4.5045-7.4597099 8.865686-8.1932303 1.435369-.2310382 2.900931-.2117212 4.329479.057065.035665.0089765-.001984-.0060378.035665.0089765 3.419667.7746114 6.278062 3.0686501 7.733836 6.2068834l.365014.803558.841562-.2986827c3.015498-1.0713449 6.390409-.2791143 8.587796 2.0159097 2.197387 2.2950241 2.796995 5.6539134 1.525764 8.5470288-1.271231 2.8931153-4.170177 4.7671423-7.376657 4.7686485l-28.805114.0000534zM37.4980152 90.1572253c-1.6784392 1e-7-3.2293809-.9345292-4.0686005-2.4515597-.8392196-1.5170305-.8392196-3.3860892 0-4.9031197.8392196-1.5170305 2.3901613-2.4515597 4.0686005-2.4515597.5978001-.0003968 1.1900549.1196264 1.7442708.3534846l.6996651.2948848.1729175-.7655689c.6802322-3.0185074 3.0248169-5.3194061 5.9533964-5.8424683.9638645-.1647498 1.948004-.1509752 2.9072885.0406921.0239488.0064011-.0013326-.0043054.0239488.0064011 2.296341.5523636 4.2157811 2.1882078 5.193348 4.4260344l.245111.5730051.5651169-.2129862c2.0249371-.7639598 4.291223-.1990321 5.7667901 1.4375147 1.4755672 1.6365469 1.8782105 4.0317199 1.024566 6.0947566-.8536445 2.0630367-2.8003155 3.399377-4.9534984 3.400451l-19.3429207.0000381z"
        />
        <g fillRule="nonzero" stroke="#313347" strokeWidth="1.6">
          <path
            fill="#F6F5FC"
            strokeLinejoin="bevel"
            d="M22.9101562 143.2530618l2.8010687 21.5969564 4.679986-18.5372676 45.2833985-31.3028496z"
          />
          <path
            fill="#F6F5FC"
            strokeLinejoin="bevel"
            d="M25.8 164.7277228l15.8-10.1831684v-.1827058l-11.1633789-7.8040928z"
          />
          <path
            fill="#FFF"
            strokeLinejoin="round"
            d="M57.62890622 165.52640238l18.18057789-50.59414475L30.284359 146.4400934z"
          />
          <path
            fill="#FFF"
            strokeLinejoin="round"
            d="M-1.6e-7 130.25399797l75.95625005-15.35695424-52.96562506 28.4217719z"
          />
        </g>
      </g>
    </svg>
  );
};
