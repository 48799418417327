import React from "react";
import { Icon } from "./Icon";

export const FilterIcon = props => (
  <Icon {...props} viewBox="0 0 19 16">
    <g
      fill="none"
      fillRule="nonzero"
      stroke="currentColor"
      transform="matrix(-1 0 0 1 18 0)"
    >
      <path
        fill="currentColor"
        d="M15.5.5h1v15h-1zM8.5.5h1v15h-1zM1.5.5h1v15h-1z"
      />
      <circle cx="15.5" cy="4.5" r="1.5" fill="currentColor" />
      <circle cx="8.5" cy="10.5" r="1.5" fill="currentColor" />
      <circle cx="1.5" cy="4.5" r="1.5" fill="currentColor" />
    </g>
  </Icon>
);
