import React from "react";
import { Editor } from "slate-react";
import styled from "styled-components";
import colors from "styles/colors";
import { isKeyHotkey } from "is-hotkey";
import { Icon } from "react-icons-kit";
import {
  ic_format_bold,
  ic_format_italic,
  ic_format_quote,
  ic_format_underlined,
  ic_format_strikethrough,
  ic_format_list_bulleted,
  ic_format_list_numbered,
  ic_link,
  ic_title,
  ic_undo,
  ic_redo
} from "react-icons-kit/md/";
import { spacing, sizing } from "styles/sizing";
import { serializer } from "./serializer";
import { semiBold, regular, bold } from "styles/typography";
import { tablet } from "styles/media";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  color: ${colors.blackRock};
  flex: 1;
  ${props =>
    !props.readOnly &&
    !props.isMessageBox &&
    `border: 1px solid ${colors.snuff}`};

  ${props => props.isMessageBox && `border-top: 1px solid ${colors.snuff}`};
  ${props => props.isMessageBox && `border-bottom: 1px solid ${colors.snuff}`};
`;

const Toolbar = styled.div`
  width: 100%;
  display: flex;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.snuff};
  padding: ${spacing(1)};
  flex-wrap: wrap;

  ${tablet(`
    min-height: 60px;
  `)}
`;

const EditorWrapper = styled.div`
  ${props => !props.readOnly && `min-height: 250px`};
  ${props => props.isMessageBox && `min-height: 116px`};
  ${props => !props.readOnly && `padding: ${spacing(1)}`};
  ${props => props.readOnly && `padding-top: ${spacing(4)}`};

  color: ${colors.blackRock};
  width: 100%;
  ${regular};
  font-size: ${sizing(16)};
  text-decoration: none;
  text-decoration-line: none;
  overflow: scroll;
  flex: 1;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: ${sizing(18)};
    ${semiBold};
    color: ${colors.brightGray};
  }

  strong {
    ${bold};
  }

  a {
    color: ${colors.palatinateBlue};
  }

  strike {
    text-decoration: line-through;
  }

  blockquote {
    margin: ${spacing(2)};
    border-left: 2px solid ${colors.snuff};
    padding: ${spacing(1)};
  }

  ul,
  ol {
    padding-left: ${spacing(2)};
    ${bold};

    li {
      ${regular};
    }
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: unset;
    list-style-position: inside;
  }
`;

const Button = styled.button.attrs({
  type: "button"
})`
  border: none;
  width: 35px;
  height: 44px;
  background-color: ${colors.white};
  color: ${props => (props.active ? colors.carribeanGreen : colors.blackRock)};
  transition: color 0.4s ease;

  &:focus,
  &:hover {
    color: ${colors.carribeanGreen};
    outline: none;
  }
`;

const DEFAULT_NODE = "paragraph";
const isBoldHotkey = isKeyHotkey("mod+b");
const isItalicHotkey = isKeyHotkey("mod+i");
const isUnderlinedHotkey = isKeyHotkey("mod+u");

export class TextEditor extends React.Component {
  constructor(props) {
    super();
    this.state = {
      value: serializer.deserialize(props.defaultValue)
    };
  }

  getIcon = icon => {
    switch (icon) {
      case "format_bold":
        return ic_format_bold;
      case "format_italic":
        return ic_format_italic;
      case "format_underlined":
        return ic_format_underlined;
      case "format_quote":
        return ic_format_quote;
      case "format_list_numbered":
        return ic_format_list_numbered;
      case "format_list_bulleted":
        return ic_format_list_bulleted;
      case "format_strike":
        return ic_format_strikethrough;
      case "format_title":
        return ic_title;
      case "format_undo":
        return ic_undo;
      case "format_redo":
        return ic_redo;
      case "format_link":
        return ic_link;
      default:
        return null;
    }
  };

  onChange = ({ value }) => {
    this.setState({ value }, () => {
      this.props.onChange(serializer.serialize(value));
    });
  };

  hasMark = type => {
    const { value } = this.state;
    return value.activeMarks.some(mark => mark.type === type);
  };

  hasBlock = type => {
    const { value } = this.state;
    return value.blocks.some(node => node.type === type);
  };

  ref = editor => {
    this.editor = editor;
  };

  renderMarkButton = (type, icon) => {
    const isActive = this.hasMark(type);

    return (
      <Button
        active={isActive}
        onMouseDown={event => this.onClickMark(event, type)}
      >
        <Icon size={18} icon={this.getIcon(icon)} />
      </Button>
    );
  };

  renderBlockButton = (type, icon) => {
    let isActive = this.hasBlock(type);

    if (["numbered-list", "bulleted-list"].includes(type)) {
      const {
        value: { document, blocks }
      } = this.state;

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key);
        isActive = this.hasBlock("list-item") && parent && parent.type === type;
      }
    }

    return (
      <Button
        active={isActive}
        onMouseDown={event => this.onClickBlock(event, type)}
      >
        <Icon size={18} icon={this.getIcon(icon)} />
      </Button>
    );
  };

  renderNode = (props, editor, next) => {
    const { attributes, children, node } = props;

    switch (node.type) {
      case "blockquote":
        return <blockquote {...attributes}>{children}</blockquote>;
      case "bulleted-list":
        return <ul {...attributes}>{children}</ul>;
      case "list-item":
        return <li {...attributes}>{children}</li>;
      case "numbered-list":
        return <ol {...attributes}>{children}</ol>;
      case "title":
        return <h3 {...attributes}>{children}</h3>;
      case "span":
        return <span {...attributes}>{children}</span>;
      default:
        return next();
    }
  };

  renderMark = (props, editor, next) => {
    const { children, mark, attributes } = props;

    switch (mark.type) {
      case "bold":
        return <strong {...attributes}>{children}</strong>;
      case "italic":
        return <em {...attributes}>{children}</em>;
      case "underlined":
        return <u {...attributes}>{children}</u>;
      case "strike-through":
        return <strike {...attributes}>{children}</strike>;
      default:
        return next();
    }
  };

  onKeyDown = (event, editor, next) => {
    let mark;

    if (isBoldHotkey(event)) {
      mark = "bold";
    } else if (isItalicHotkey(event)) {
      mark = "italic";
    } else if (isUnderlinedHotkey(event)) {
      mark = "underlined";
    } else {
      return next();
    }

    event.preventDefault();
    editor.toggleMark(mark);
  };

  onClickMark = (event, type) => {
    event.preventDefault();
    this.editor.toggleMark(type);
  };

  onClickBlock = (event, type) => {
    event.preventDefault();

    const { editor } = this;
    const { value } = editor;
    const { document } = value;

    if (
      type !== "bulleted-list" &&
      type !== "numbered-list" &&
      type !== "undo" &&
      type !== "redo"
    ) {
      const isActive = this.hasBlock(type);
      const isList = this.hasBlock("list-item");

      if (isList) {
        editor
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock("bulleted-list")
          .unwrapBlock("numbered-list");
      } else {
        editor.setBlocks(isActive ? DEFAULT_NODE : type);
      }
    } else if (type === "undo" || type === "redo") {
      if (type === "undo") {
        this.editor.undo();
      }
      if (type === "redo") {
        this.editor.redo();
      }
      return;
    } else {
      const isList = this.hasBlock("list-item");
      const isType = value.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type === type);
      });

      if (isList && isType) {
        editor
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock("bulleted-list")
          .unwrapBlock("numbered-list");
      } else if (isList) {
        editor
          .unwrapBlock(
            type === "bulleted-list" ? "numbered-list" : "bulleted-list"
          )
          .wrapBlock(type);
      } else {
        editor.setBlocks("list-item").wrapBlock(type);
      }
    }
  };

  render() {
    return (
      <Wrapper
        readOnly={this.props.readOnly}
        isMessageBox={this.props.messageBox}
      >
        {!this.props.readOnly && (
          <Toolbar>
            {this.renderMarkButton("bold", "format_bold")}
            {this.renderMarkButton("italic", "format_italic")}
            {this.renderMarkButton("underlined", "format_underlined")}
            {this.renderMarkButton("strike-through", "format_strike")}
            {this.renderBlockButton("blockquote", "format_quote")}
            {this.renderBlockButton("numbered-list", "format_list_numbered")}
            {this.renderBlockButton("bulleted-list", "format_list_bulleted")}
            {this.renderBlockButton("title", "format_title")}
            {this.renderBlockButton("undo", "format_undo")}
            {this.renderBlockButton("redo", "format_redo")}
          </Toolbar>
        )}
        <EditorWrapper
          readOnly={this.props.readOnly}
          isMessageBox={this.props.messageBox}
        >
          <Editor
            spellCheck
            value={this.state.value}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            renderNode={this.renderNode}
            renderMark={this.renderMark}
            ref={this.ref}
            readOnly={this.props.readOnly}
            autoFocus={this.props.autoFocus}
            style={{
              height: this.props.readOnly
                ? "auto"
                : this.props.messageBox
                ? "100px"
                : "236px"
            }}
          />
        </EditorWrapper>
      </Wrapper>
    );
  }
}

TextEditor.defaultProps = {
  onChange: () => null
};
