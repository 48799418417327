import React from "react";
import styled, { css } from "styled-components";
import { regular } from "styles/typography";
import { sizing, spacing } from "styles/sizing";
import { tablet } from "styles/media";
import colors from "styles/colors";

const Title = styled.h1`
  ${regular};
  font-size: ${sizing(30)};
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding: ${spacing(2)} ${spacing(2)} ${spacing(1)} ${spacing(2)};
  background: ${colors.white};
  position: sticky;
  top: 0;
  z-index: 10;
  transform: translate3d(0, 0, 0);

  ${props => props.leftAligned && `text-align: left`};

  ${tablet(css`
    padding: ${spacing(3)} 0 ${spacing(2)} 0;
    text-align: center;
  `)}
`;

export function PageTitle({ children, ...props }) {
  return <Title {...props}>{children}</Title>;
}
