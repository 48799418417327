import React, { Component, Fragment } from "react";
import { Modal } from "components/Modal";
import styled from "styled-components";
import { ArrowIcon } from "components/icons";
import "rc-slider/assets/index.css";
import posed from "react-pose";
import colors from "styles/colors";
import { Form, Formik, FieldArray } from "formik";
import { spacing, sizing } from "styles/sizing";
import { semiBold } from "styles/typography";
import { Button } from "components/Button";
import gql from "graphql-tag";
import { UserInput } from "components/UserInput";
import { Query } from "react-apollo";
import { Loader } from "components/Loader";

const AnimatingDropdown = posed.div({
  open: {
    height: "auto",
    opacity: 1,
    flip: true
  },
  closed: { height: 0, opacity: 0, flip: true }
});

const AnimatingArrow = posed.div({
  open: { rotate: 90 },
  closed: { rotate: 0 }
});

const DropdownWrapper = styled.div`
  color: ${colors.blackRock};
  border-bottom: 1px solid ${colors.snuff};
`;

const DropdownHeader = styled.div`
  padding: ${spacing(2)} 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-size: ${sizing(12)};
    color: ${colors.palatinateBlue};
    padding: 0 ${spacing(1)};
  }
`;
const DropdownTitle = styled.h3`
  margin: 0;
  flex: 1;
`;

const DropdownBody = styled(AnimatingDropdown)`
  overflow: ${props => (props.pose === "open" ? "visible" : "hidden")};
`;

const DropdownContainer = styled.div`
  padding: ${spacing(2)} 0;
`;

const Dropdown = ({ children, title, state, onClick, active }) => (
  <DropdownWrapper>
    <DropdownHeader onClick={onClick}>
      <DropdownTitle>
        {title}
        {!!active && <span>{`${active} selected`}</span>}
      </DropdownTitle>
      <AnimatingArrow pose={state}>
        <ArrowIcon fill={colors.blackRock} />
      </AnimatingArrow>
    </DropdownHeader>
    <DropdownBody pose={state}>
      <DropdownContainer>{children}</DropdownContainer>
    </DropdownBody>
  </DropdownWrapper>
);

const Container = styled.div`
  border: 1px solid ${colors.snuff};
  padding: ${spacing(2)};
  flex: 1;
`;

const Title = styled.h3`
  padding: ${spacing()} 0;
  display: block;
  font-size: ${sizing(18)};
  ${semiBold};
  margin: 0;
  color: ${colors.blackRock};
  text-align: center;
  pointer-events: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: ${spacing(2)} 0;
`;

export class Filters extends Component {
  state = {
    activeFilter: null
  };

  getDefaultValues = () => {
    const { currentFilters } = this.props;

    const members =
      typeof currentFilters.members === "string"
        ? [currentFilters.members]
        : currentFilters.members || [];

    return {
      members: members.map(key => ({
        id: key,
        value: members[key]
      }))
    };
  };

  handleActiveFilter = filter => {
    const { activeFilter } = this.state;
    this.setState({
      activeFilter: activeFilter === filter ? null : filter
    });
  };

  render() {
    const { isActive, onRequestClose, onSubmit } = this.props;
    const { activeFilter } = this.state;
    let defaultValues = this.getDefaultValues();
    return (
      <Modal isOpen={isActive} onRequestClose={onRequestClose}>
        <Title>Filter Orginisations</Title>
        <Query query={queryCurrentMembers} variables={defaultValues.members}>
          {({ data, loading }) => {
            if (loading && !data) {
              return <Loader />;
            }
            if (data) {
              defaultValues = {
                ...defaultValues,
                members: data.users || []
              };

              return (
                <Formik initialValues={defaultValues} onSubmit={onSubmit}>
                  {({ values, setFieldValue }) => (
                    <Form>
                      <Container>
                        <Dropdown
                          title="Members"
                          key="members"
                          state={activeFilter === "members" ? "open" : "closed"}
                          onClick={() => this.handleActiveFilter("members")}
                          active={values.members.length}
                        >
                          <FieldArray
                            name="members"
                            render={arrayHelpers => (
                              <Fragment>
                                <UserInput
                                  name="members"
                                  query={queryMembers}
                                  values={values.members}
                                />
                              </Fragment>
                            )}
                          />
                        </Dropdown>
                      </Container>
                      <ButtonContainer>
                        <Button
                          type="button"
                          secondary
                          style={{
                            marginRight: spacing()
                          }}
                          onClick={() => {
                            onRequestClose();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          primary
                          style={{
                            marginLeft: spacing()
                          }}
                        >
                          Apply
                        </Button>
                      </ButtonContainer>
                    </Form>
                  )}
                </Formik>
              );
            }
          }}
        </Query>
      </Modal>
    );
  }
}

const queryMembers = gql`
  query users($search: String!) {
    users(
      where: {
        OR: [{ first_names_contains: $search }, { last_name_contains: $search }]
      }
    ) {
      id
      first_names
      last_name
      avatar
    }
  }
`;

const queryCurrentMembers = gql`
  query($ids: [ID!]) {
    users(where: { id_in: $ids }) {
      id
      first_names
      last_name
      avatar
    }
  }
`;
