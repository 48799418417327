export const tabletStart = 800;
export const deskStart = 1024;
export const deskWideStart = 1440;

export const mobileEnd = tabletStart - 0.1;
export const tabletEnd = deskStart - 0.1;
export const deskEnd = deskWideStart - 0.1;

export const baseFontSize = 16;
export const baseUnit = 8;
