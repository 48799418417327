import styled from "styled-components";
import { sizing, spacing } from "styles/sizing";
import colors from "styles/colors";
import { semiBold, regular } from "styles/typography";
import posed from "react-pose";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: ${sizing(18)};
  color: ${colors.blackRock};
  ${semiBold};
  text-align: center;
`;

export const AnimatedWrapper = posed.div({
  enter: {
    opacity: 1,
    scale: 1,
    duration: 400
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    duration: 400
  }
});

export const SubmittedTitle = styled.h1`
  font-size: ${sizing(30)};
  color: ${colors.blackRock};
  ${regular};
  text-align: center;
  padding: ${spacing(4)} 0 ${spacing(1)} 0;
  margin: 0;
`;

export const ImageContainer = styled.div`
  width: 250px;
  margin: 0 auto;
  padding-bottom: ${spacing(2)};
`;

export const Header = styled.header`
  width: 100%;
  padding: 0 ${spacing(2)};
  border-bottom: 1px solid ${colors.snuff};
`;

export const Body = styled.section`
  padding: ${spacing(2)} 0;
  width: 100%;
  overflow: scroll;
  border-bottom: 1px solid ${colors.snuff};
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;
  }
`;
