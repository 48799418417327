import React from "react";
import PropTypes from "prop-types";

import { Logo } from "components/Logo";
import {
  LogoContainer,
  Navigation,
  NavigationContainer,
  Wrapper
} from "./style";

export function Header({ children }) {
  return (
    <Wrapper>
      <LogoContainer>
        <Logo width="40px" />
      </LogoContainer>
      <Navigation>
        <NavigationContainer>{children}</NavigationContainer>
      </Navigation>
    </Wrapper>
  );
}

Header.propTypes = {
  children: PropTypes.node.isRequired
};
