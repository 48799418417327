import React from "react";
import styled from "styled-components";
import { spacing } from "styles/sizing";
import colors from "styles/colors";

const PageWrapper = styled.div`
  padding: ${spacing(3)};
  height: 100%;
  width: 100%;
  background-color: ${colors.blackRock};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
`;

export const PageContainer = ({ children, ...props }) => {
  return <PageWrapper {...props}>{children}</PageWrapper>;
};
