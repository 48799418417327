import React, { Component } from "react";
import { Logo } from "components/Logo";
import { PageContainer } from "components/PageContainer";
import colors from "styles/colors";
import { Button } from "components/Button";
import styled from "styled-components";
import { semiBold } from "styles/typography";
import { spacing, sizing } from "styles/sizing";

const Info = styled.h3`
  ${semiBold};
  color: ${colors.white};
  padding-top: ${spacing(2)};
  padding-bottom: ${spacing(4)};
  text-align: center;
  margin: 0;
  font-size: ${sizing(18)};
  line-height: ${sizing(26)};
  max-width: ${sizing(500)};
`;

const Container = styled.div`
  flex: 1;
  max-height: 600px;
`;

export class Login extends Component {
  render() {
    return (
      <PageContainer>
        <Logo isVertical width="110px" hasTitle />
        <Info>
          Whether you are a consultant or a business looking for consultants -
          we work with you to deliver great projects
        </Info>
        <Container>
          <Button secondary onClick={() => this.props.history.push("/login")}>
            Login
          </Button>
          <Button
            tertiary
            onClick={() => this.props.history.push("/get-started")}
          >
            Join as a consultant
          </Button>
          <Button tertiary onClick={() => this.props.history.push("/signup")}>
            Join as a hiring firm
          </Button>
          <Button
            tertiary
            onClick={() => (window.location = "https://coreconsultants.io")}
          >
            Back to the website
          </Button>
        </Container>
      </PageContainer>
    );
  }
}
