import React from "react";
import { Icon } from "./Icon";
import colors from "styles/colors";

export const OrganisationIcon = props => (
  <Icon {...props} viewBox="0 0 116 106">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeWidth="4"
      transform="translate(1 1)"
    >
      <circle cx="57" cy="44" r="44" />
      <g fill={colors.blackRock}>
        <circle cx="21" cy="21" r="21" />
        <circle cx="57" cy="83" r="21" />
        <circle cx="93" cy="21" r="21" />
      </g>
    </g>
  </Icon>
);
