import styled, { css } from "styled-components";
import { Field, ErrorMessage } from "formik";
import { spacing, sizing } from "styles/sizing";
import { regular } from "styles/typography";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  flex-direction: column;
  ${props => (props.width ? `width: ${props.width}` : "width: 100%")};
`;

export const Label = styled.label`
  ${props => props.margin && `margin-right: ${spacing(2)}`};
  padding: ${spacing(2)} 0;
`;

export const Input = styled(Field)`
  color: ${colors.blackRock};
  font-size: ${sizing(18)};
  border: none;
  border-bottom: 1px solid ${colors.liquorice};
  padding: ${spacing()};
  background: transparent;
  text-align: center;
  font-family: "Gilroy-Bold";
  transition: border-color 0.4s ease;
  max-width: 480px;
  border-radius: 0px;

  ${props =>
    props.component === "textarea" &&
    css`
      border: 1px solid ${colors.ghost};
      height: 280px;
      color: ${colors.scarpaFlow};
      font-size: ${sizing(16)};
      text-align: left;
      max-width: 100%;
      max-height: 100%;
      ${regular};
    `};

  &.block {
    color: ${colors.liquorice};
    ${regular};
    font-size: ${sizing(18)};
    border: 1px solid ${colors.solitude};
    margin: ${spacing(1)} 0;
    text-align: left;
    box-shadow: none;
    border-radius: 0px;

    &::placeholder {
      color: ${colors.ghost};
    }

    &:focus {
      border-color: ${colors.palatinateBlue};
    }
  }

  ${props => (props.width ? `width: ${props.width}` : "width: 100%")};
  ${props => props.error && `border-color: ${colors.red}`};

  &:focus {
    outline: none;
  }
`;

export const Error = styled(ErrorMessage)`
  color: ${colors.red};
  font-size: ${sizing(12)};
  ${regular};
  padding-top: ${spacing(1)};
`;
