import React from "react";
import { Icon } from "./Icon";

export const ProfileIcon = props => (
  <Icon {...props} viewBox="0 0 18 22">
    <path
      fill="currentcolor"
      fillRule="nonzero"
      d="M14.19 20.756h2.516l-1.202-6.608a1.796 1.796 0 0 0-1.767-1.474h-9.87c-.869 0-1.612.62-1.768 1.474L.898 20.756h2.516v-3.592h.898v3.592h8.98v-3.592h.898v3.592zm-10.324-8.98h9.871c1.302 0 2.418.93 2.65 2.212l1.202 6.607a.898.898 0 0 1-.883 1.059H.898a.898.898 0 0 1-.884-1.06l1.202-6.606a2.694 2.694 0 0 1 2.65-2.212zM9 9a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 1A5 5 0 1 1 9 0a5 5 0 0 1 0 10z"
    />
  </Icon>
);
