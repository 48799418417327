import React, { Fragment, Component } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import {
  StyledProgressBar,
  StyledLoader,
  SectionContent,
  Experience,
  ExperienceCompany,
  ExperienceDates,
  ExperienceTitle,
  Section,
  SectionHeader
} from "./styles";
import { format } from "date-fns";
import { CancellableTag, Tag } from "components/Tag";
import { TextEditor } from "components/TextEditor";
import { PageLayout, PageHeader } from "components/PageLayout";
import { PageSubTitle, PageTitle } from "components/PageHeader";
import {
  ContentBlock,
  ContentTitle,
  ContentArea,
  ContentColumn
} from "components/ContentBlock";
import {
  NaturalBlock,
  NaturalIntro,
  NaturalData
} from "components/NaturalBlocks";
import { locations } from "constants/locations";
import { experienceLevels } from "constants/experienceLevels";
import { Loader } from "components/Loader";
import {
  SummaryTile,
  SummaryImage,
  SummaryDetail,
  SummaryTitle,
  SummaryDate
} from "components/Summary";
import { Button } from "components/Button";
import { stringify } from "querystring";
import { TeamCard, TeamName, TeamImage, TeamRole } from "components/TeamCard";
import { FloatingButton } from "components/PageLayout/style";
import Helmet from "react-helmet";
import { NotFound } from "components/illustrations";

export class Profile extends Component {
  state = {
    allEntitiesRecieved: false
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      this.setState({
        allEntitiesRecieved: false
      });
    }
    return true;
  }

  render() {
    return (
      <Query query={query} fetchPolicy={"network-only"}>
        {({ loading, data }) => {
          if (!data.User) {
            return <Loader />;
          }

          const {
            avatar,
            first_names,
            last_name,
            data: {
              job_title,
              day_rate,
              summary,
              experience,
              tags,
              id,
              experience_level,
              location,
              part_time,
              willing_to_travel
            },
            organisationOwnership,
            organisationManager,
            organisationMember
          } = data.User;

          const percentage = () => {
            let count = 100;

            if (!experience.length) {
              count = count - 100 / 12;
            }

            if (!tags.length) {
              count = count - 100 / 12;
            }

            if (!summary) {
              count = count - 100 / 12;
            }

            return count;
          };

          return data && data.User ? (
            <PageLayout
              autoHeight
              header={
                <PageHeader>
                  <Helmet>
                    <title>Dashboard - Core Consultants</title>
                  </Helmet>
                  <PageTitle>Dashboard</PageTitle>
                </PageHeader>
              }
            >
              <ContentArea>
                <ContentColumn>
                  <ContentTitle>
                    Welcome{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {first_names} {last_name}
                    </span>{" "}
                    to Core Consultants
                  </ContentTitle>

                  {(!experience.length || !summary || !tags.length) && (
                    <ContentBlock>
                      <ContentTitle>Your Profile Status</ContentTitle>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start"
                        }}
                      >
                        <StyledProgressBar
                          style={{ margin: "1rem" }}
                          percentage={percentage()}
                          image={avatar}
                        />
                        <PageSubTitle>
                          {Math.floor(percentage())}% Complete
                        </PageSubTitle>
                      </div>
                      {!summary && (
                        <Fragment>
                          <strong>Summary</strong>
                          <p>
                            Update your summary and tell everyone a bit about
                            yourself.
                          </p>
                        </Fragment>
                      )}
                      {!experience.length && (
                        <Fragment>
                          <strong>Experience</strong>
                          <p>Tell us a little bit about your previous roles.</p>
                        </Fragment>
                      )}
                      {!tags.length && (
                        <Fragment>
                          <strong>Tags</strong>
                          <p>
                            Add some tags about your skills and expertise so we
                            can find you some relevant roles.
                          </p>
                        </Fragment>
                      )}
                    </ContentBlock>
                  )}

                  <ContentBlock>
                    <ContentTitle>Relevant Roles</ContentTitle>
                    <Query
                      query={relevantRoles}
                      variables={{
                        tags: tags.map(tag => ({
                          tags_some: { id: tag.id }
                        }))
                      }}
                    >
                      {({ data, loading }) => {
                        if (!data || !data.roles) {
                          return <Loader />;
                        }

                        return (
                          <Fragment>
                            {data.roles.length ? (
                              <Fragment>
                                {data.roles.map(role => (
                                  <SummaryTile
                                    key={`role-${role.id}`}
                                    to={`/roles/${role.id}`}
                                  >
                                    {role.organisation ? (
                                      <SummaryImage
                                        url={role.organisation.logo}
                                      />
                                    ) : (
                                      <SummaryImage url={role.client.avatar} />
                                    )}
                                    <SummaryDetail>
                                      <SummaryTitle>{role.title}</SummaryTitle>
                                      {role.location && (
                                        <Tag dark>
                                          {locations[role.location]}
                                        </Tag>
                                      )}
                                      {role.day_rate && (
                                        <Tag dark>{`£${
                                          role.day_rate
                                        } p/d`}</Tag>
                                      )}
                                      {role.experience && (
                                        <Tag dark>
                                          {experienceLevels[role.experience]}
                                        </Tag>
                                      )}
                                      {role.full_time && (
                                        <Tag dark>
                                          {role.full_time
                                            ? "Full Time"
                                            : "Part Time"}
                                        </Tag>
                                      )}
                                      <SummaryDate>
                                        {`Posted on: ${format(
                                          role.createdAt,
                                          "DD/MM/YYYY"
                                        )}`}
                                      </SummaryDate>
                                    </SummaryDetail>
                                  </SummaryTile>
                                ))}
                                <Button
                                  primary
                                  onClick={() =>
                                    this.props.history.push({
                                      pathname: "/roles",
                                      search: stringify({
                                        tags: tags.map(i => i.id)
                                      })
                                    })
                                  }
                                >
                                  See all
                                </Button>
                              </Fragment>
                            ) : null}
                            {!data.roles.length ? (
                              <p>
                                You currently have no relevant roles try adding
                                more tags to your profile to help us find you
                                some.
                                <NotFound
                                  style={{
                                    width: "200px",
                                    display: "block",
                                    margin: "16px auto"
                                  }}
                                />
                              </p>
                            ) : null}
                          </Fragment>
                        );
                      }}
                    </Query>
                  </ContentBlock>
                  <ContentBlock>
                    <ContentTitle>Your Teams</ContentTitle>
                    {![
                      ...organisationOwnership,
                      ...organisationManager,
                      ...organisationMember
                    ].length ? (
                      <p>
                        You are currently in no teams go create you dream team
                        or join an existing one.
                        <NotFound
                          style={{
                            width: "200px",
                            display: "block",
                            margin: "16px auto"
                          }}
                        />
                      </p>
                    ) : null}

                    {organisationOwnership.map(team => (
                      <TeamCard to={`/teams/${team.id}`} key={team.id}>
                        <TeamImage src={team.logo} />
                        <TeamName>{team.name}</TeamName>
                        <TeamRole>Owner</TeamRole>
                      </TeamCard>
                    ))}

                    {organisationManager.map(team => (
                      <TeamCard to={`/teams/${team.id}`} key={team.id}>
                        <TeamImage src={team.logo} />
                        <TeamName>{team.name}</TeamName>
                        <TeamRole>Manager</TeamRole>
                      </TeamCard>
                    ))}
                    {organisationMember.map(team => (
                      <TeamCard to={`/teams/${team.id}`} key={team.id}>
                        <TeamImage src={team.logo} />
                        <TeamName>{team.name}</TeamName>
                        <TeamRole>Member</TeamRole>
                      </TeamCard>
                    ))}
                  </ContentBlock>
                </ContentColumn>
                <ContentColumn>
                  <ContentBlock>
                    <ContentTitle>Your Profile</ContentTitle>
                    <FloatingButton
                      secondary
                      onClick={() =>
                        this.props.history.push("/dashboard/edit-profile")
                      }
                    >
                      Edit
                    </FloatingButton>
                    <StyledProgressBar
                      profile_completness={100}
                      image={avatar}
                    />
                    <Section>
                      <SectionHeader>
                        <PageSubTitle>Details</PageSubTitle>
                      </SectionHeader>

                      <NaturalBlock>
                        <NaturalIntro>Your name is:</NaturalIntro>
                        <NaturalData style={{ textTransform: "capitalize" }}>
                          {first_names} {last_name}
                        </NaturalData>
                      </NaturalBlock>

                      <NaturalBlock>
                        <NaturalIntro>Your Job Title is:</NaturalIntro>
                        <NaturalData style={{ textTransform: "capitalize" }}>
                          {job_title}
                        </NaturalData>
                      </NaturalBlock>

                      <NaturalBlock>
                        <NaturalIntro>Your daily rate is:</NaturalIntro>
                        <NaturalData>£{day_rate}</NaturalData>
                      </NaturalBlock>

                      <NaturalBlock>
                        <NaturalIntro>Your Experience level is:</NaturalIntro>
                        <NaturalData>
                          {experienceLevels[experience_level]}
                        </NaturalData>
                      </NaturalBlock>

                      <NaturalBlock>
                        <NaturalIntro>Your location is:</NaturalIntro>
                        <NaturalData>{locations[location]}</NaturalData>
                      </NaturalBlock>

                      <NaturalBlock>
                        <NaturalIntro>You are:</NaturalIntro>
                        <NaturalData>
                          {willing_to_travel
                            ? "Willing to travel"
                            : "not willing to travel"}
                        </NaturalData>
                      </NaturalBlock>
                      <NaturalBlock>
                        <NaturalIntro>You prefer to be:</NaturalIntro>
                        <NaturalData>
                          {part_time ? "Part time" : "Full time"}
                        </NaturalData>
                      </NaturalBlock>
                    </Section>

                    <Section>
                      <SectionHeader>
                        <PageSubTitle>Tags</PageSubTitle>
                      </SectionHeader>
                      {tags.length
                        ? tags.map(tag => (
                            <CancellableTag key={tag.id}>
                              {tag.value}
                            </CancellableTag>
                          ))
                        : null}
                      {!tags.length && (
                        <p>
                          Add some tags about your skills and expertise so we
                          can show you relevant roles.
                        </p>
                      )}
                    </Section>
                    <Section>
                      <SectionHeader>
                        <PageSubTitle>About Me</PageSubTitle>
                      </SectionHeader>
                      {summary ? (
                        <SectionContent>
                          <TextEditor
                            defaultValue={summary}
                            readOnly
                            key={id}
                          />
                        </SectionContent>
                      ) : (
                        <p>Add a short summary about yourself.</p>
                      )}
                    </Section>
                    <Section>
                      <SectionHeader>
                        <PageSubTitle>My Experience</PageSubTitle>
                      </SectionHeader>
                      {experience.length ? (
                        <SectionContent>
                          {experience.map(exp => (
                            <Experience key={exp.id}>
                              <ExperienceTitle>{exp.title}</ExperienceTitle>
                              <ExperienceCompany>
                                {exp.company}
                              </ExperienceCompany>
                              <ExperienceDates>{`${format(
                                exp.start_date,
                                "MMMM YYYY"
                              )} - ${
                                exp.end_date !== null
                                  ? format(exp.end_date, "MMMM YYYY")
                                  : "Current"
                              }`}</ExperienceDates>

                              <TextEditor
                                defaultValue={exp.description}
                                readOnly
                                key={exp.id}
                              />
                            </Experience>
                          ))}
                        </SectionContent>
                      ) : (
                        <p>Tell us about your recent roles.</p>
                      )}
                    </Section>
                  </ContentBlock>
                </ContentColumn>
              </ContentArea>
            </PageLayout>
          ) : (
            <StyledLoader />
          );
        }}
      </Query>
    );
  }
}

const relevantRoles = gql`
  query($tags: [RoleWhereInput!]) {
    roles(where: { OR: $tags, closed: false }, first: 5) {
      id
      title
      day_rate
      location
      experience
      full_time
      createdAt
      organisation {
        id
        logo
      }
      client {
        id
        avatar
      }
    }
  }
`;

const query = gql`
  query {
    User {
      id
      first_names
      last_name
      role
      avatar
      data {
        id
        job_title
        summary
        day_rate
        experience_level
        location
        part_time
        willing_to_travel
        experience(orderBy: start_date_DESC) {
          id
          start_date
          end_date
          title
          description
          company
        }
        tags {
          id
          value
        }
      }
      organisationOwnership {
        id
        logo
        name
      }
      organisationManager {
        id
        logo
        name
      }
      organisationMember {
        id
        logo
        name
      }
    }
  }
`;
