import React from "react";

export const NotFound = props => (
  <svg {...props} width="100%" viewBox="0 0 239 252">
    <defs>
      <ellipse
        id="a"
        cx="92.6517572"
        cy="92.6685552"
        rx="92.6517572"
        ry="92.6685552"
      />
      <ellipse
        id="c"
        cx="111.022364"
        cy="145.393768"
        rx="92.6517572"
        ry="92.6685552"
      />
      <circle id="d" cx="52.715655" cy="52.5787822" r="36.8608254" />
      <circle id="f" cx="65.4952077" cy="65.3606519" r="36.8608254" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M-28-4h285v275H-28z" />
      <g transform="translate(1 1)">
        <g fillRule="nonzero">
          <ellipse
            cx="111.022364"
            cy="145.393768"
            fill="#313347"
            rx="92.6517572"
            ry="92.6685552"
          />
          <ellipse
            cx="25.1597444"
            cy="244.053824"
            fill="#4734FF"
            opacity=".2"
            rx="5.99041534"
            ry="5.99150142"
            transform="matrix(-1 0 0 1 50.319488 0)"
          />
          <ellipse
            cx="231.230032"
            cy="230.473088"
            fill="#4734FF"
            opacity=".2"
            rx="5.99041534"
            ry="5.99150142"
            transform="matrix(-1 0 0 1 462.460064 0)"
          />
          <ellipse
            cx="214.456869"
            cy="210.501416"
            fill="#4734FF"
            opacity=".2"
            rx="3.5942492"
            ry="3.59490085"
            transform="matrix(-1 0 0 1 428.913738 0)"
          />
          <ellipse
            cx="192.092652"
            cy="51.5269122"
            fill="#4734FF"
            opacity=".2"
            rx="3.5942492"
            ry="3.59490085"
            transform="matrix(-1 0 0 1 384.185304 0)"
          />
          <ellipse
            cx="5.19169329"
            cy="70.6997167"
            fill="#4734FF"
            opacity=".2"
            rx="3.5942492"
            ry="3.59490085"
            transform="matrix(-1 0 0 1 10.383386 0)"
          />
          <ellipse
            cx="23.9616613"
            cy="44.7365439"
            fill="#4734FF"
            opacity=".2"
            rx="4.79233227"
            ry="4.79320113"
            transform="matrix(-1 0 0 1 47.923322 0)"
          />
          <ellipse
            cx="190.095847"
            cy="48.7308782"
            stroke="#313347"
            strokeWidth="1.6"
            rx="3.19488818"
            ry="3.19546742"
          />
          <ellipse
            cx="162.140575"
            cy="27.1614731"
            fill="#4734FF"
            opacity=".2"
            rx="2.39616613"
            ry="2.39660057"
            transform="matrix(-1 0 0 1 324.28115 0)"
          />
          <ellipse
            cx="181.309904"
            cy="7.98866856"
            fill="#4734FF"
            opacity=".2"
            rx="4.79233227"
            ry="4.79320113"
            transform="matrix(-1 0 0 1 362.619808 0)"
          />
          <ellipse
            cx="160.543131"
            cy="24.7648725"
            stroke="#313347"
            strokeWidth="1.6"
            rx="2.39616613"
            ry="2.39660057"
          />
          <ellipse
            cx="178.913738"
            cy="4.79320113"
            stroke="#313347"
            strokeWidth="1.6"
            rx="4.79233227"
            ry="4.79320113"
          />
          <ellipse
            cx="3.19488818"
            cy="67.9036827"
            stroke="#313347"
            strokeWidth="1.6"
            rx="3.19488818"
            ry="3.19546742"
          />
          <ellipse
            cx="211.661342"
            cy="207.705382"
            stroke="#313347"
            strokeWidth="1.6"
            rx="3.19488818"
            ry="3.19546742"
          />
          <ellipse
            cx="20.7667732"
            cy="40.7422096"
            stroke="#313347"
            strokeWidth="1.6"
            rx="3.99361022"
            ry="3.99433428"
          />
          <ellipse
            cx="227.635783"
            cy="227.677054"
            stroke="#313347"
            strokeWidth="1.6"
            rx="4.79233227"
            ry="4.79320113"
          />
          <ellipse
            cx="22.3642173"
            cy="240.458924"
            stroke="#313347"
            strokeWidth="1.6"
            rx="4.79233227"
            ry="4.79320113"
          />
        </g>
        <g transform="translate(18.370607 52.725212)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <g mask="url(#b)">
            <path
              fill="#DCD9FA"
              stroke="#313347"
              strokeWidth="1.6"
              d="M32.68578835 164.66866416l2.67382393-131.94600498c-.01948702-1.0504599.78519156-1.9153822 1.79729916-1.93185843l114.53641368-1.86456602c1.0121075-.01647623 1.84837975.82173348 1.86786647 1.87219338l-2.26806001 153.81930473c.01948672 1.05046-.78519135 1.9153828-1.79729875 1.93185912l-93.461714 1.52148534-23.34833048-23.40241314z"
            />
            <path
              fill="#FFF"
              stroke="#313347"
              strokeLinejoin="round"
              strokeWidth="1.6"
              d="M132.649776 31.1178869L120.7308614 19.172805v20.4772833c0 1.8848819 1.6419326 3.4128806 3.6673586 3.4128806h20.17047l-11.918914-11.945082 11.918914 11.945082V174.638362c0 1.055534-.853805 1.911214-1.907026 1.911214H23.47252128c-1.05322153 0-1.90702628-.85568-1.90702628-1.911214V21.08401811c0-1.05553385.85380475-1.91121311 1.90702628-1.91121311h97.25834012l11.9189146 11.9450819z"
            />
            <path
              fill="#FFF"
              stroke="#313347"
              strokeLinejoin="bevel"
              strokeWidth="1.6"
              d="M144.56869 43.1388107h-20.275252c-2.035948 0-3.6864095-1.5328495-3.6864095-3.4237151V19.172805L144.56869 43.1388107z"
            />
            <path
              fill="#313347"
              d="M40.7348241 46.3342781h84.6645367v1.59773371H40.7348241zM40.7348241 57.5184141h81.4696486v1.59773371H40.7348241zM40.7348241 68.70255h84.6645367v1.59773371H40.7348241zM40.7348241 137.4051h84.6645367v1.59773371H40.7348241zM40.7348241 148.589236h84.6645367v1.59773371H40.7348241z"
            />
            <path
              fill="#ECEAFE"
              stroke="#313347"
              strokeWidth="1.6"
              d="M41.5348241 83.8821534h83.0645367v39.9410765H41.5348241z"
            />
          </g>
        </g>
        <use
          fillRule="nonzero"
          stroke="#313347"
          strokeWidth="1.6"
          xlinkHref="#c"
        />
        <g transform="rotate(-45 198.11304197 42.54296514)">
          <circle
            cx="65.4952077"
            cy="65.3606519"
            r="46.0760318"
            fill="#FFF"
            fillRule="nonzero"
            stroke="#313347"
            strokeWidth="1.6"
            transform="rotate(45 65.495208 65.360652)"
          />
          <path
            fill="#ECEAFE"
            fillRule="nonzero"
            d="M75.07776073 29.8673367c.613808.6139193 9.03138603 9.03302354 25.25273421 25.25731272 3.07339098 10.20965616 2.76130855 17.67736237-.93624714 22.40311863-3.69755568 4.72575626-11.1529692 12.18252148-22.36624067 22.37029579-11.53749486 2.51053829-18.39393094 2.73413825-20.56930811.67080003-2.17537717-2.06333837-11.20595087-10.39082391-27.0917211-24.98245678-1.73526274-12.97754327-.10944796-23.49910458 4.87744425-31.56468428 3.72648413-6.02705124 13.82837834-11.9351268 22.9589568-14.08057597 9.19763985-2.16120682 17.41234137-.53593426 17.87438176-.07381014z"
          />
          <path
            stroke="#313347"
            strokeWidth="1.6"
            d="M59.52581353 39.5846164c-14.02499898 3.7502703-22.348057 18.13661307-18.5900731 32.13280028"
          />
          <path
            fill="#FFF"
            fillRule="nonzero"
            stroke="#313347"
            strokeWidth="1.6"
            d="M59.9054313 111.043626h13.1594834v6.68515306H59.9054313zM59.8379337 117.887494v57.514909c0 3.633891 2.9458507 6.579741 6.5797417 6.579741 3.633891 0 6.5797417-2.94585 6.5797417-6.579741v-57.514909H59.8379337z"
          />
          <g transform="translate(12.779553 12.78187)">
            <mask id="e" fill="#fff">
              <use xlinkHref="#d" />
            </mask>
            <g stroke="#313347" strokeWidth="1.6" mask="url(#e)">
              <path d="M91.60343085 60.11490432L60.30007662 91.41825855" />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M63.25738216 17.29844949l25.2296341 25.398284-25.2296341-25.398284zM17.50176432 63.05406734l24.66485227 24.83350219-24.66485227-24.8335022z"
              />
            </g>
          </g>
          <use
            fillRule="nonzero"
            stroke="#313347"
            strokeWidth="1.6"
            transform="rotate(45 65.495208 65.360652)"
            xlinkHref="#f"
          />
        </g>
        <path
          fill="#FFF"
          stroke="#313347"
          strokeLinejoin="round"
          strokeWidth="1.6"
          d="M39.9361022 102.715864V73.8092301c0-1.0555338.8538048-1.9112131 1.9070263-1.9112131h97.2583405"
        />
      </g>
    </g>
  </svg>
);
