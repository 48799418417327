import styled, { css } from "styled-components";
import { spacing, sizing } from "styles/sizing";
import colors from "styles/colors";
import { semiBold, regular } from "styles/typography";
import { tablet } from "styles/media";

export const Admin = styled.div`
  padding-bottom: ${spacing(1)};
  display: flex;
  flex-direction: column;
`;

export const Section = styled.section`
  padding: ${spacing(2)} 0;
  border-bottom: 1px solid ${colors.snuff};
`;

export const SectionTitle = styled.h2`
  padding: ${spacing(1)} 0;
  font-size: ${sizing(18)};
  ${semiBold};
  margin: 0;
`;

export const Stats = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Stat = styled.div`
  padding: ${spacing(1)};
  width: 50%;
  ${tablet(css`
    width: 25%;
  `)};
`;

export const StatInner = styled.dl`
  padding: ${spacing(1)};
  border: 1px solid ${colors.snuff};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  height: 100%;
`;

export const StatTitle = styled.dt`
  ${regular};
  font-size: ${sizing(16)};
  padding: ${spacing(1)} 0;
  margin: 0;
`;

export const StatValue = styled.dd`
  ${regular};
  font-size: ${sizing(24)};
  padding: ${spacing(1)} 0;
  margin: 0;
  color: ${colors.carribeanGreen};
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing(1)};
  flex: 1;
`;

export const Close = styled.button`
  border: none;
  background: transparent;
  font-size: ${sizing(32)};
  ${regular};
  color: ${colors.blackRock};

  &:focus {
    outline: none;
  }
`;
