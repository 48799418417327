import React from "react";
import styled, { css } from "styled-components";
import { regular, semiBold } from "styles/typography";
import { sizing, spacing } from "styles/sizing";
import { desk, tablet } from "styles/media";
import colors from "styles/colors";
import { Button } from "components/Button";

const Title = styled.h1`
  ${regular};
  font-size: ${sizing(30)};
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding: ${spacing(2)} ${spacing(2)} ${spacing(1)} ${spacing(2)};
  background: ${colors.white};
  position: sticky;
  top: 0;
  z-index: 10;
  transform: translate3d(0, 0, 0);

  ${props => props.leftAligned && `text-align: left`};

  ${tablet(css`
    padding: ${spacing(3)} 0 ${spacing(2)} 0;
    text-align: center;
  `)}
`;

export function PageTitle({ children, ...props }) {
  return <Title {...props}>{children}</Title>;
}

export const PageSubTitle = styled.h3`
  margin-top: 0;
  font-size: ${sizing(18)};
  line-height: ${sizing(26)};
  ${semiBold};
  margin: 0;
  padding-bottom: ${spacing(1)};

  ${props => props.center && "text-align: center"};

  ${desk(`
    font-size: ${sizing(20)};
  `)};
  text-transform: capitalize;
`;

export const PageButton = styled(Button)`
  cursor: pointer;
  display: block;
  margin: ${spacing(0.5)} 0;
  padding: ${spacing()};
  font-size: ${sizing(14)};
  width: auto;
  margin: 8px auto;
`;
