import styled from "styled-components";
import { Button } from "components/Button";
import { Form } from "formik";
import { FieldError } from "components/FormField";
import colors from "styles/colors";
import { spacing } from "styles/sizing";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: auto;
`;

export const StyledForm = styled(Form)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Errors = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${spacing(2)};
`;

export const StyledFieldError = styled(FieldError)`
  margin: ${spacing(1)} 0;
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${spacing(2)};

  * + * {
    margin-left: ${spacing(2)};
  }
`;

export const FormButton = styled(Button)`
  flex: 1;

  &:disabled {
    background-color: ${colors.frenchGray};
    border-color: transparent;
  }
`;
